import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: 'app-employee-attendance-report',
  templateUrl: './employee-attendance-report.component.html',
  styleUrls: ['./employee-attendance-report.component.css']
})
export class EmployeeAttendanceReportComponent implements OnInit {
  formTitle = "Employee Attendance Report";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList: any;
  excelDataList = [];
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  isSpinning = false
  spinning = false;
  filterClass: string = "filter-visible";
  month: any = new Date();
  year: any = new Date();
  m = new Date().getMonth()
  MONTH: any = this.datePipe.transform(this.month, "MM");
  YEAR: any = this.datePipe.transform(this.year, "yyyy");
  FROM_DATE: any;
  TO_DATE: any;
  // FROM_DATE = this.YEAR + '-' + this.MONTH + '-' + 1;
  // TO_DATE = this.YEAR + '-' + this.MONTH + '-' + 31;
  D_Attendance: any;
  STATUS: any;
  AttendanceVali: any;

  check = [];
  detailed = [];
  status = [];
  Attendance = [];
  isOk = true;
  twentynine: boolean;
  thirty: boolean;
  thirtyone: boolean;
  nodatafound = false;

  constructor(private api: ApiService, private datePipe: DatePipe, private msg: NzNotificationService) { }

  ngOnInit() {
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.search(true);
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }

  applyFilter() {
    var sort: string;
    this.MONTH = this.datePipe.transform(this.month, "MM");
    this.YEAR = this.datePipe.transform(this.year, "yyyy");
    // this.FROM_DATE = this.YEAR + '-' + this.MONTH + '-' + 1;
    // this.TO_DATE = this.YEAR + '-' + this.MONTH + '-' + 31;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }

    this.filterQuery = '';

    var filter = '';
    filter = this.filterQuery;
    var likeQuery = "";

    if (this.FROM_DATE != null) {
      this.FROM_DATE = this.FROM_DATE.toString();
    }
    if (this.TO_DATE != null) {
      this.TO_DATE = this.TO_DATE.toString();
    }
    if (this.month != null) {
      this.MONTH = this.MONTH
    }
    if (this.year != null) {
      this.YEAR = this.YEAR
    }

    if (this.MONTH == null || this.YEAR == null) {
      this.msg.error("Please apply Both Month and Year Filter", '')
      this.isOk = false
      this.isFilterApplied = "default";
    }
    else {
      this.isOk = true;
      this.isFilterApplied = "default";
    }
    if (this.isOk == true) {
      this.D_Attendance = [];
      this.dataList = [];
      this.STATUS = [];
      var likeQuery = '';
      this.isFilterApplied = "primary";
      this.filterClass = "filter-invisible";
      this.search();
    }
  }

  clearFilter() {
    this.D_Attendance = [];
    this.dataList = [];
    this.STATUS = [];
    this.month = new Date();
    this.year = new Date();
    this.MONTH = this.datePipe.transform(this.month, "MM");
    this.YEAR = this.datePipe.transform(this.year, "yyyy");
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
    this.filterQuery = '';
    this.search(true);
  }

  ifvalueisnotzero: boolean;
  search(reset: boolean = false, exportInExcel: boolean = false) {
    this.ifvalueisnotzero = true;
    this.nodatafound = false;
    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    this.MONTH = this.datePipe.transform(this.month, "MM");
    this.YEAR = this.datePipe.transform(this.year, "yyyy");
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.MONTH == '01' || this.MONTH == '03' || this.MONTH == '05' || this.MONTH == '07' ||
      this.MONTH == '08' || this.MONTH == '10' || this.MONTH == '12') {
      this.FROM_DATE = this.YEAR + '-' + this.MONTH + '-' + 1;
      this.TO_DATE = this.YEAR + '-' + this.MONTH + '-' + 31;
      this.twentynine = true;
      this.thirty = true;
      this.thirtyone = true;
    }
    else if (this.MONTH == '04' || this.MONTH == '06' || this.MONTH == '09' || this.MONTH == '11') {
      this.FROM_DATE = this.YEAR + '-' + this.MONTH + '-' + 1;
      this.TO_DATE = this.YEAR + '-' + this.MONTH + '-' + 30;
      this.twentynine = true;
      this.thirty = true;
      this.thirtyone = false;
    }
    else if (this.MONTH == '02') {
      if (this.YEAR % 4 == 0) {
        this.FROM_DATE = this.YEAR + '-' + this.MONTH + '-' + 1;
        this.TO_DATE = this.YEAR + '-' + this.MONTH + '-' + 29;
        this.twentynine = true;
        this.thirty = false;
        this.thirtyone = false;
      }
      else {
        this.FROM_DATE = this.YEAR + '-' + this.MONTH + '-' + 1;
        this.TO_DATE = this.YEAR + '-' + this.MONTH + '-' + 28;
        this.twentynine = false;
        this.thirty = false;
        this.thirtyone = false;
      }
    }

    if (this.FROM_DATE != null) {
      this.FROM_DATE = this.FROM_DATE.toString();
    }
    if (this.TO_DATE != null) {
      this.TO_DATE = this.TO_DATE.toString();
    }
    if (this.month != null) {
      this.MONTH = this.MONTH
    }
    if (this.year != null) {
      this.YEAR = this.YEAR
    }

    if (exportInExcel == false) {
      this.loadingRecords = true;
      this.isSpinning = true;
      this.api.getemployeeAttendanceReport(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, "", this.FROM_DATE, this.TO_DATE, this.MONTH, this.YEAR).subscribe((data) => {
        if (data['code'] == 200) {
          // console.log(data);
          this.isSpinning = false;
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          let apiData = JSON.parse(data['ATTENDANCE_DATA'][0]['ATTENDANCE_DATA']);
          // console.log(apiData, "APIData");
          let loop = [];
          let loop2 = [];
          let loop3 = [];
          let loop4 = [];
          for (let i = 0; i < apiData.length; i++) {
            if (apiData[i]['summary'] === '0') {
            } else {
              this.check = JSON.parse(apiData[i]['summary']);
              loop.push(this.check);
              this.status = JSON.parse(apiData[i]['calender']);
              loop3.push(this.status);
              this.detailed = JSON.parse(apiData[i]['detailed']);
              loop2.push(this.detailed);

              this.Attendance = JSON.parse(apiData[i]['Attendance_register']);
              loop4.push(this.Attendance);

            }
          }

          this.dataList = loop;
          console.log(this.dataList, "Employee details..");
          this.D_Attendance = loop2;
          console.log(this.D_Attendance, "Check this.. data")
          this.STATUS = loop3;
          // console.log(this.STATUS,"Statuss")
          this.AttendanceVali = loop4;
          console.log(this.AttendanceVali, "attendencevalidate")
          if (this.dataList.length <= 0) {
            this.ifvalueisnotzero = false;
            this.nodatafound = true;
          }
          // console.log(loop4, "loop4");
          // console.log(this.dataList, "EmployeeDetails");
          // console.log(this.D_Attendance, "In & Out Time Details");
          this.getdetailes();
          this.spinning = false;
        }
        else if (data['code'] == 300) {
          let warnmsg = data['message']
          this.nodatafound = true;
          this.msg.error('Please provide valid Month and Year.', "");
          this.isSpinning = false;
        }
        else if (data['code'] == 400) {
          let errormsg = data['message']
          this.nodatafound = true;
          this.msg.error(errormsg, "");
          this.isSpinning = false;
        }
      })
    }
    else {
    }
  }

  current_month: any;
  current_year: any;
  arraydetail: any;
  getdetailes() {
    for (let i = 0; i < this.D_Attendance.length; i++) {

      if (this.MONTH == '01' || this.MONTH == '03' || this.MONTH == '05' || this.MONTH == '07' || this.MONTH == '08' || this.MONTH == '10' || this.MONTH == '12') {
        // console.log(this.AttendanceVali[i], 'AttendanceVali');

        for (let b = 0; b < 31; b++) {

          let datesample;
          if (this.D_Attendance[i][b] != undefined) {
            datesample = this.D_Attendance[i][b].DATE;
            let dateinit = this.datePipe.transform(datesample, "d");
            let datecheck = b + 1;
            let stringdate = datecheck.toString();
            if (this.AttendanceVali[i] != 0) {

              // if (stringdate != dateinit) {
              if (this.AttendanceVali[i][0][b + 1] != 'P' && this.AttendanceVali[i][0][b + 1] != 'HF') {
                if (stringdate != dateinit) {
                  this.D_Attendance[i].splice(b, 0, {
                    "DATE": this.YEAR + "-" + this.MONTH + "-" + stringdate,
                    "IN_TIME": "--:--",
                    "OUT_TIME": "--:--",
                    "WORKING_HOURS": "--:--",
                    "TOTAL_OVERTIME_HOURS": "--:--",
                  });
                }
                else {
                  this.D_Attendance[i][b] = {
                    "DATE": this.YEAR + "-" + this.MONTH + "-" + stringdate,
                    "IN_TIME": "--:--",
                    "OUT_TIME": "--:--",
                    "WORKING_HOURS": "--:--",
                    "TOTAL_OVERTIME_HOURS": "--:--",
                  }
                }

              }

            }
            // if (stringdate != dateinit) {
            //   this.D_Attendance[i].splice(b, 0, {
            //     "DATE": this.YEAR + "-" + this.MONTH + "-" + stringdate,
            //     "IN_TIME": "--:--",
            //     "OUT_TIME": "--:--",
            //     "WORKING_HOURS": "--:--",
            //     "TOTAL_OVERTIME_HOURS": "--:--",
            //   });
            // }
          } else {
            this.D_Attendance[i].splice(b, 0, {
              "DATE": this.YEAR + "-" + this.MONTH + "-" + (b + 1),
              "IN_TIME": "--:--",
              "OUT_TIME": "--:--",
              "WORKING_HOURS": "--:--",
              "TOTAL_OVERTIME_HOURS": "--:--",
            });
          }

        }
      }
      else if (this.MONTH == '04' || this.MONTH == '06' || this.MONTH == '09' || this.MONTH == '11') {
        for (let b = 0; b < 30; b++) {

          let datesample;
          if (this.D_Attendance[i][b] != undefined) {
            datesample = this.D_Attendance[i][b].DATE;
            let dateinit = this.datePipe.transform(datesample, "d");
            let datecheck = b + 1;
            let stringdate = datecheck.toString();
            if (this.AttendanceVali[i] != 0) {

              // if (stringdate != dateinit) {
              if (this.AttendanceVali[i][0][b + 1] != 'P' && this.AttendanceVali[i][0][b + 1] != 'HF') {
                if (stringdate != dateinit) {
                  this.D_Attendance[i].splice(b, 0, {
                    "DATE": this.YEAR + "-" + this.MONTH + "-" + stringdate,
                    "IN_TIME": "--:--",
                    "OUT_TIME": "--:--",
                    "WORKING_HOURS": "--:--",
                    "TOTAL_OVERTIME_HOURS": "--:--",
                  });
                }
                else {
                  this.D_Attendance[i][b] = {
                    "DATE": this.YEAR + "-" + this.MONTH + "-" + stringdate,
                    "IN_TIME": "--:--",
                    "OUT_TIME": "--:--",
                    "WORKING_HOURS": "--:--",
                    "TOTAL_OVERTIME_HOURS": "--:--",
                  }
                }

              }

            }
            // if (stringdate != dateinit) {
            //   this.D_Attendance[i].splice(b, 0, {
            //     "DATE": this.YEAR + "-" + this.MONTH + "-" + stringdate,
            //     "IN_TIME": "--:--",
            //     "OUT_TIME": "--:--",
            //     "WORKING_HOURS": "--:--",
            //     "TOTAL_OVERTIME_HOURS": "--:--",
            //   });
            // }
          } else {
            this.D_Attendance[i].splice(b, 0, {
              "DATE": this.YEAR + "-" + this.MONTH + "-" + (b + 1),
              "IN_TIME": "--:--",
              "OUT_TIME": "--:--",
              "WORKING_HOURS": "--:--",
              "TOTAL_OVERTIME_HOURS": "--:--",
            });
            // console.log("this is With P and HF ");
          }
        }
      }
      else if (this.MONTH == '02') {
        if (this.YEAR % 4 == 0) {
          for (let b = 0; b < 29; b++) {

            let datesample;
            if (this.D_Attendance[i][b] != undefined) {
              datesample = this.D_Attendance[i][b].DATE;
              let dateinit = this.datePipe.transform(datesample, "d");
              let datecheck = b + 1;
              let stringdate = datecheck.toString();
              if (this.AttendanceVali[i] != 0) {
                // if (stringdate != dateinit) {
                if (this.AttendanceVali[i][0][b + 1] != 'P' && this.AttendanceVali[i][0][b + 1] != 'HF') {
                  if (stringdate != dateinit) {
                    this.D_Attendance[i].splice(b, 0, {
                      "DATE": this.YEAR + "-" + this.MONTH + "-" + stringdate,
                      "IN_TIME": "--:--",
                      "OUT_TIME": "--:--",
                      "WORKING_HOURS": "--:--",
                      "TOTAL_OVERTIME_HOURS": "--:--",
                    });
                  }
                  else {
                    this.D_Attendance[i][b] = {
                      "DATE": this.YEAR + "-" + this.MONTH + "-" + stringdate,
                      "IN_TIME": "--:--",
                      "OUT_TIME": "--:--",
                      "WORKING_HOURS": "--:--",
                      "TOTAL_OVERTIME_HOURS": "--:--",
                    }
                  }
                }

              }
              // if (stringdate != dateinit) {
              //   this.D_Attendance[i].splice(b, 0, {
              //     "DATE": this.YEAR + "-" + this.MONTH + "-" + stringdate,
              //     "IN_TIME": "--:--",
              //     "OUT_TIME": "--:--",
              //     "WORKING_HOURS": "--:--",
              //     "TOTAL_OVERTIME_HOURS": "--:--",
              //   });
              // }
            } else {
              this.D_Attendance[i].splice(b, 0, {
                "DATE": this.YEAR + "-" + this.MONTH + "-" + (b + 1),
                "IN_TIME": "--:--",
                "OUT_TIME": "--:--",
                "WORKING_HOURS": "--:--",
                "TOTAL_OVERTIME_HOURS": "--:--",
              });
            }
          }
        }
        else {
          for (let b = 0; b < 28; b++) {
            let datesample;
            if (this.D_Attendance[i][b] != undefined) {
              datesample = this.D_Attendance[i][b].DATE;
              let dateinit = this.datePipe.transform(datesample, "d");
              let datecheck = b + 1;
              let stringdate = datecheck.toString();

              if (this.AttendanceVali[i] != 0) {
                // if (stringdate != dateinit) {
                if (this.AttendanceVali[i][0][b + 1] != 'P' && this.AttendanceVali[i][0][b + 1] != 'HF') {
                  if (stringdate != dateinit) {
                    this.D_Attendance[i].splice(b, 0, {
                      "DATE": this.YEAR + "-" + this.MONTH + "-" + stringdate,
                      "IN_TIME": "--:--",
                      "OUT_TIME": "--:--",
                      "WORKING_HOURS": "--:--",
                      "TOTAL_OVERTIME_HOURS": "--:--",
                    });
                  }
                  else {
                    this.D_Attendance[i][b] = {
                      "DATE": this.YEAR + "-" + this.MONTH + "-" + stringdate,
                      "IN_TIME": "--:--",
                      "OUT_TIME": "--:--",
                      "WORKING_HOURS": "--:--",
                      "TOTAL_OVERTIME_HOURS": "--:--",
                    }
                  }
                }

              }

              // if (stringdate != dateinit) {
              //   this.D_Attendance[i].splice(b, 0, {
              //     "DATE": this.YEAR + "-" + this.MONTH + "-" + stringdate,
              //     "IN_TIME": "--:--",
              //     "OUT_TIME": "--:--",
              //     "WORKING_HOURS": "--:--",
              //     "TOTAL_OVERTIME_HOURS": "--:--",
              //   });
              // }
            } else {
              this.D_Attendance[i].splice(b, 0, {
                "DATE": this.YEAR + "-" + this.MONTH + "-" + (b + 1),
                "IN_TIME": "--:--",
                "OUT_TIME": "--:--",
                "WORKING_HOURS": "--:--",
                "TOTAL_OVERTIME_HOURS": "--:--",
              });
            }
          }
        }
      }

      /// IF the Month & Year Values Are Null then this operation will be performed.....//////////////////
      // else if (this.MONTH == null && this.YEAR == null) {
      //   this.current_month = new Date();
      //   this.current_year = new Date();
      //   this.current_month = this.datePipe.transform(this.current_month, 'MM');
      //   this.current_year = this.datePipe.transform(this.current_month, 'yyyy');

      //   if (this.current_month == '01' || this.current_month == '03' || this.current_month == '05' || this.current_month == '07' ||
      //     this.current_month == '08' || this.current_month == '10' || this.current_month == '12') {
      //     for (let b = 0; b < 31; b++) {

      //       let datesample;
      //       if (this.D_Attendance[i][b] != undefined) {
      //         datesample = this.D_Attendance[i][b].DATE;
      //         let dateinit = this.datePipe.transform(datesample, "d");
      //         let datecheck = b + 1;
      //         let stringdate = datecheck.toString();

      //         if (stringdate != dateinit) {
      //           this.D_Attendance[i].splice(b, 0, {
      //             "DATE": this.current_year + "-" + this.current_month + "-" + stringdate,
      //             "IN_TIME": "--:--:--",
      //             "OUT_TIME": "--:--:--",
      //             "WORKING_HOURS": "--:--:--",
      //             "TOTAL_OVERTIME_HOURS": "--:--:--",
      //           });
      //         }
      //       } else {
      //         this.D_Attendance[i].splice(b, 0, {
      //           "DATE": this.current_year + "-" + this.current_month + "-" + (b + 1),
      //           "IN_TIME": "--:--:--",
      //           "OUT_TIME": "--:--:--",
      //           "WORKING_HOURS": "--:--:--",
      //           "TOTAL_OVERTIME_HOURS": "--:--:--",
      //         });
      //       }
      //     }
      //   }
      //   else if (this.current_month == '04' || this.current_month == '06' || this.current_month == '09' || this.current_month == '11') {
      //     for (let b = 0; b < 30; b++) {

      //       let datesample;
      //       if (this.D_Attendance[i][b] != undefined) {
      //         datesample = this.D_Attendance[i][b].DATE;
      //         let dateinit = this.datePipe.transform(datesample, "d");
      //         let datecheck = b + 1;
      //         let stringdate = datecheck.toString();

      //         if (stringdate != dateinit) {
      //           this.D_Attendance[i].splice(b, 0, {
      //             "DATE": this.current_year + "-" + this.current_month + "-" + stringdate,
      //             "IN_TIME": "--:--:--",
      //             "OUT_TIME": "--:--:--",
      //             "WORKING_HOURS": "--:--:--",
      //             "TOTAL_OVERTIME_HOURS": "--:--:--",
      //           });
      //         }
      //       } else {
      //         this.D_Attendance[i].splice(b, 0, {
      //           "DATE": this.current_year + "-" + this.current_month + "-" + (b + 1),
      //           "IN_TIME": "--:--:--",
      //           "OUT_TIME": "--:--:--",
      //           "WORKING_HOURS": "--:--:--",
      //           "TOTAL_OVERTIME_HOURS": "--:--:--",
      //         });
      //       }
      //     }
      //   }
      //   else if (this.current_month == '02') {
      //     if (this.current_year % 4 == 0) {
      //       for (let b = 0; b < 29; b++) {

      //         let datesample;
      //         if (this.D_Attendance[i][b] != undefined) {
      //           datesample = this.D_Attendance[i][b].DATE;
      //           let dateinit = this.datePipe.transform(datesample, "d");
      //           let datecheck = b + 1;
      //           let stringdate = datecheck.toString();

      //           if (stringdate != dateinit) {
      //             this.D_Attendance[i].splice(b, 0, {
      //               "DATE": this.current_year + "-" + this.current_month + "-" + stringdate,
      //               "IN_TIME": "--:--:--",
      //               "OUT_TIME": "--:--:--",
      //               "WORKING_HOURS": "--:--:--",
      //               "TOTAL_OVERTIME_HOURS": "--:--:--",
      //             });
      //           }
      //         } else {
      //           this.D_Attendance[i].splice(b, 0, {
      //             "DATE": this.current_year + "-" + this.current_month + "-" + (b + 1),
      //             "IN_TIME": "--:--:--",
      //             "OUT_TIME": "--:--:--",
      //             "WORKING_HOURS": "--:--:--",
      //             "TOTAL_OVERTIME_HOURS": "--:--:--",
      //           });
      //         }
      //       }
      //     }
      //     else {
      //       for (let b = 0; b < 28; b++) {
      //         let datesample;
      //         if (this.D_Attendance[i][b] != undefined) {
      //           datesample = this.D_Attendance[i][b].DATE;
      //           let dateinit = this.datePipe.transform(datesample, "d");
      //           let datecheck = b + 1;
      //           let stringdate = datecheck.toString();

      //           if (stringdate != dateinit) {
      //             this.D_Attendance[i].splice(b, 0, {
      //               "DATE": this.current_year + "-" + this.current_month + "-" + stringdate,
      //               "IN_TIME": "--:--:--",
      //               "OUT_TIME": "--:--:--",
      //               "WORKING_HOURS": "--:--:--",
      //               "TOTAL_OVERTIME_HOURS": "--:--:--",
      //             });
      //           }
      //         } else {
      //           this.D_Attendance[i].splice(b, 0, {
      //             "DATE": this.current_year + "-" + this.current_month + "-" + (b + 1),
      //             "IN_TIME": "--:--:--",
      //             "OUT_TIME": "--:--:--",
      //             "WORKING_HOURS": "--:--:--",
      //             "TOTAL_OVERTIME_HOURS": "--:--:--",
      //           });
      //         }
      //       }
      //     }
      //   }
      // }

      // console.log(this.D_Attendance[i]);
      this.getnum(i);
    }
  }

  // Modal Open Code starts
  isVisible = false;

  showModal(): void {
    this.isVisible = true;
  }

  handleOk(): void {
    this.isVisible = false;
  }

  handleCancel(): void {
    this.isVisible = false;
  }
  // Modal Open Code Ends

  // PageBreak code starts
  arrayshow = []
  getnum(i) {
    let add = 2 + (i - 1) * 3;
    this.arrayshow.push(add);
  }

  hassomething(i): boolean {
    return this.arrayshow.find((res) => {
      if (res == i && this.dataList.length != i + 1) {
        return true;
      }
      else {
        return false;
      }
    });
  }
  // PageBreak code Ends

}

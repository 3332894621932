
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import { NzNotificationService } from 'ng-zorro-antd';
import { stringify } from 'querystring';
// import { leaveMaster } from '../Models/LeaveMaster';
// import { ApiService } from '../Service/api.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ApiService } from 'src/app/Service/api.service';
import { leaveMaster } from '../LeaveMaster';

@Component({
  selector: 'app-master-table',
  templateUrl: './master-table.component.html',
  styleUrls: ['./master-table.component.css']
})
export class MasterTableComponent implements OnInit {

  constructor(private api: ApiService, private message: NzNotificationService, private datepipe: DatePipe) { }

  columns: string[][] = [["NAME", "Name"],
  ["CODE", "Code"],
  ["DESCRIPTION", "Description"],
  ["GENDER_NAME", "Applicable For"],
  ["APPLICABLE_TYPES", "Applicable On"],
  ["ALLOTMENT_TYPE_CAPTION", "Allotment Type"],
  ["ALLOTMENT_FROM_CAPTION", "Allotment From"],
  // ["LOOSE_OFF_PAY","Loss Of Pay"],
  // ["STATUS", "Status"],
  ["EFFECTIVE_FROM", "Effective From"],
  ["LEAVE_AVAIL_FROM", "Leave Avail From"],
  ["ALLOTMENT_BALANCE", "Allotment Bal"],
  ["CARRY_FORWARD_LIMIT", "Carry Forward"],
  // ["PRO_RATED", "Pro rated"],
  ["PRORATED_MAX_JOINING_DATE", "Prorated Joining date"],
  // ["LEAVE_ENCASHMENT", "Leave Encashment"],
  // ["AUTO_CREDIT", "Auto Credit"],  
  ["MAX_ENCASH", "max Encash"],
  ["MIN_ENCASH", "Min Encash"],
  ["MAX_AVAIL_PERMONTH", "Mav Avail Permonth"],
  ["MAX_AVAIL_PER_INSTANCE", "Max Avail Per Instance"],
  ["MIN_AVAIL_PER_INSTANCE", "Min Avail Per Instance"],
  ["MIN_GAP_BETWEEN_TWO_INSTANCE", "Min Gap"],
  ["CLUBBED_WITH_NOT_POSSIBLE", "Clubbed"]]
  // ["IS_EARNED","Is Earned"]]

  @Input() data: leaveMaster = new leaveMaster();
  @Input() listOfData: any;
  formTitle = "Leave Master";
  filterClass: string = 'filter-invisible';
  searchText: string = "";
  isVisible = false;
  isSpinning = false;
  loadingRecords = false;
  user: leaveMaster = new leaveMaster();
  allotmentId: any;

  dataList: any[] = [];
  drawerTitle: string | undefined;
  switch5 = false;
  switch6 = false;
  ALLOTMENT_TYPE_ID = null;

  pageIndex = 1;
  pageSize = 10;
  sortKey: string = "id";
  sortValue: string = "desc";
  totalRecords = 1;
  listOfData2: any = []
  disabledEndDate2
  startValue: any;
  endValue: any;
  endOpen = false;
  startOpen = false;
  today2 = new Date();
  dates: any = [];
  isFilterApplied: string = 'default';
  filterQuery: string = '';

  drawerClose() {
    // this.search();
    this.isVisible = false;
    this.getData();
  }
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  // sort: { key: string; value: string }
  sort(sort: any): void {

    this.sortKey = sort.key;
    this.sortValue = sort.value;


    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  search(reset: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    console.log("search text : " + this.searchText);
    // console.log(sort);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND ";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
        // likeQuery += " " + `${column[0]==='GENDER'?'GENDER_NAME':''} ` + " like '%" +this.searchText +"%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    // if (likeQuery)
    //   filter += likeQuery;

    this.api.getLeaveData(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, this.filterQuery + likeQuery).subscribe(data => {
      console.log(data['data']);
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];

    }, err => {
      console.log(err);
    });
  }

  showFilter() {
    if (this.filterClass === 'filter-visible')
      this.filterClass = 'filter-invisible';
    else this.filterClass = 'filter-visible';

  }

  applyFilter() {

    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }

    this.startValue = this.datepipe.transform(this.startValue, 'yyyy-MM-dd');
    this.endValue = this.datepipe.transform(this.endValue, 'yyyy-MM-dd');


    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }

    this.filterQuery = '';
    this.startValue = this.datepipe.transform(this.startValue, 'yyyy-MM-dd');
    this.endValue = this.datepipe.transform(this.endValue, 'yyyy-MM-dd');

    if (this.startValue != null && this.endValue != null) {
      this.filterQuery = " AND EFFECTIVE_FROM BETWEEN '" + this.startValue + "' AND '" + this.endValue + "' "

      var filter = '';
      filter = this.filterQuery;
      var likeQuery = "";
    }

    if (this.data.ALLOTMENT_TYPE_ID != null) {
      this.filterQuery =
        this.filterQuery + ' AND ALLOTMENT_TYPE_ID=' + "" + this.data.ALLOTMENT_TYPE_ID + "";
    }

    this.search();
    var likeQuery = '';


    if (this.searchText != '') {
      likeQuery = ' AND';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);

      //console.log("likeQuery" + likeQuery);
    }

    this.api
      .getLeaveData(0, 0, this.sortKey, sort, this.filterQuery)
      .subscribe(
        (data) => {
          console.log(data);
          this.loadingRecords = false;
          this.isFilterApplied = 'primary';
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';
          // this.search();
        },
        (err) => {
          console.log(err);
        }
      );

  }



  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.startValue = null;
    this.endValue = null;

    this.isFilterApplied = 'default';
    this.filterQuery = '';

    this.dataList = [];
    this.search();
    this.data.ALLOTMENT_TYPE_ID = null;

  }

  startDateChange() {
    var startDate = this.datepipe.transform(this.startValue, 'yyyy-MM-dd');
    var endDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');

    console.log(this.getDaysArray(startDate, endDate));
    console.log(this.dates);
  }

  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datepipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datepipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }

  timeDefaultValue = setHours(new Date(), 0);

  disabledStartDate2 = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today2) > 0;

  moduleStartDateHandle(open: boolean) {
    // console.log(open);

    if (!open) {
      this.endOpen = true;

      // this.endValue = this.startValue;
    }
  }

  ngOnInit() {
    this.search();
    this.getData();
  }

  getData() {
    this.api.getLeaveData(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, '').subscribe((data: any) => {
      this.loadingRecords = false;
      this.dataList = data['data'];
      this.totalRecords = data['count'];
      console.log(this.dataList, 'getFunction');
      // console.log(this.data.CLUBBED_WITH_NOT_POSSIBLE, 'getClubbed');


    }, err => {
      console.log(err);
    });
  }


  add() {
    this.isVisible = true;
    this.drawerTitle = "Create Leave";
    this.user = new leaveMaster();
    this.switch5 = false;
    this.switch6 = false;

  }

  getNames(type: any) {
    this.data.APPLICABLE_TYPES = String(type).split(',');
    var name = ''
    for (var i = 0; i < this.data.APPLICABLE_TYPES.length; i++) {
      if (this.data.APPLICABLE_TYPES[i] == 1) {
        name = name + ('Full Day,');
      }
      if (this.data.APPLICABLE_TYPES[i] == 2) {
        name = name + ('Half Day,');
      }
      if (this.data.APPLICABLE_TYPES[i] == 3) {
        name = name + ('Quarter Day,');
      }

      if (i + 1 == this.data.APPLICABLE_TYPES.length) {
        return name;
      }
    }
  }

  getNames2(type: any) {
    var CLUBBED_WITH_NOT_POSSIBLE = String(type).split(',');
    let name = ''
    // console.log(this.data.CLUBBED_WITH_NOT_POSSIBLE,"Clubbed");

    for (var i = 0; i < this.dataList.length; i++) {
      // this.dataList[i].CLUBBED_WITH_NOT_POSSIBLE;
      // console.log(this.dataList[i].CLUBBED_WITH_NOT_POSSIBLE)
      console.log(this.dataList[i]['NAME'])

      // if (CLUBBED_WITH_NOT_POSSIBLE==this.dataList[i].STATUS == true) {

      if (CLUBBED_WITH_NOT_POSSIBLE == this.dataList[i].ID) {

        console.log(this.dataList[i]['NAME'])
        name = name + (this.dataList[i]['NAME'] + ',')

      }




      // if (CLUBBED_WITH_NOT_POSSIBLE[i] == 1) {
      //   name = name + ('Type 1,');
      // }
      // if (CLUBBED_WITH_NOT_POSSIBLE[i] == 2) {
      //   name = name + ('Type 2,');
      // }
      // if (CLUBBED_WITH_NOT_POSSIBLE[i] == 3) {
      //   name = name + ('Type 3,');
      // }

      if (i + 1 == CLUBBED_WITH_NOT_POSSIBLE.length) {
        return name;
      }
    }
    console.log(name);
  }

  edit(data: leaveMaster) {
    console.log(data);

    this.drawerTitle = 'Update Leave';
    this.user = Object.assign({}, data);

    this.api.getLeaveData(0, 0, '', '', " AND ID !=" + data.ID).subscribe(data => {
      this.listOfData2 = data['data'];

    }, err => {
      console.log(err);
    });
    // this.getData();


    this.user.GENDER = this.user.GENDER;

    this.user.APPLICABLE_TYPES = String(this.user.APPLICABLE_TYPES).split(',');
    for (var i = 0; i < this.user.APPLICABLE_TYPES.length; i++) {
      this.user.APPLICABLE_TYPES[i] = Number(this.user.APPLICABLE_TYPES[i]);
    }
    console.log(this.user.APPLICABLE_TYPES);

    this.switch5 = this.user.APPLICABLE_TYPES.length == 3 ? true : false;

    this.user.ALLOTMENT_FROM = String(this.user.ALLOTMENT_FROM);

    this.user.ALLOTMENT_TYPE_ID = String(this.user.ALLOTMENT_TYPE_ID);
    this.user.LEAVE_AVAIL_FROM = String(this.user.LEAVE_AVAIL_FROM);


    if (this.user.CLUBBED_WITH_NOT_POSSIBLE.trim() == '') {
      this.user.CLUBBED_WITH_NOT_POSSIBLE = []
    }
    else {
      this.user.CLUBBED_WITH_NOT_POSSIBLE = String(this.user.CLUBBED_WITH_NOT_POSSIBLE).trim().split(',');
      for (var i = 0; i < this.user.CLUBBED_WITH_NOT_POSSIBLE.length; i++) {
        this.user.CLUBBED_WITH_NOT_POSSIBLE[i] = Number(this.user.CLUBBED_WITH_NOT_POSSIBLE[i]);

      }
    }

    this.switch6 = this.user.CLUBBED_WITH_NOT_POSSIBLE.length == 3 ? true : false;

    this.user.LEAVE_AVAIL_FROM = String(this.user.LEAVE_AVAIL_FROM);

    this.user.PRORATED_MAX_JOINING_DATE = String(this.user.PRORATED_MAX_JOINING_DATE);
    this.isVisible = true;


  }


}

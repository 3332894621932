import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { leaveYearConfiguration } from '../leaveyearconfiguration';
// import { leaveYearConfiguration } from '../Models/leaveyearconfiguration';
// import { ApiService } from '../Service/api.service';

@Component({
  selector: 'app-leave-yearconfigurationform',
  templateUrl: './leave-yearconfigurationform.component.html',
  styleUrls: ['./leave-yearconfigurationform.component.css']
})
export class LeaveYearconfigurationformComponent implements OnInit {

  @Input() data: leaveYearConfiguration = new leaveYearConfiguration;
  @Input() drawerClose!: Function;
  constructor(private message: NzNotificationService, private api: ApiService, private datepipe: DatePipe) { }

  isSpinning = false;

  @Input() listOfData2: any = []
  @Input() dataList: any = []


  listOfData: any = []


  pageIndex = 1;
  pageSize = 10;
  totalRecords = 100;

  date = null;
  isOk = false;



  ngOnInit() {
    this.getData();

  }

  getData() {
    this.api.getLeaveConfiguration(0, 0, '', '', 'AND STATUS=1 AND ORG_ID=1 ').subscribe((data) => {
      this.listOfData = data['data'];
      console.log('this.listOfData', this.listOfData);
    })
  }




  onSubmit(addNew: boolean, httpForm: NgForm) {
    this.isOk = true;

    if (this.data.NAME == null || this.data.NAME.trim() == "") {
      this.isOk = false
      this.message.error('Please Enter Valid Name.', "")
    }
    else if (this.data.START_DATE == null || this.data.START_DATE == undefined) {
      this.isOk = false
      this.message.error('Please Select Start Date.', "")
    }

    else if (this.data.END_DATE == null || this.data.END_DATE == undefined) {
      this.isOk = false
      this.message.error('Please Select End Date.', "")
    }
    else if (this.data.LEAVE_CREDIT_DAY == null || this.data.LEAVE_CREDIT_DAY == 0) {
      this.isOk = false
      this.message.error('Please Select Leave Credit Day.', "")
    }
    else if (this.data.COMPOFF_LAPS_DAY == null || this.data.COMPOFF_LAPS_DAY == 0) {
      this.isOk = false
      this.message.error('Please Enter Compoff Lapse.', "")
    }

    if (this.isOk) {
      if (this.data.ID) {

        this.data.START_DATE = this.datepipe.transform(this.data.START_DATE, 'yyyy-MM-dd');
        this.data.END_DATE = this.datepipe.transform(this.data.END_DATE, 'yyyy-MM-dd');

        this.isSpinning = false;

        this.api.updateLeaveConfiguration(this.data).subscribe((successCode: any) => {
          if (successCode['code'] == 200) {
            this.getData();
            this.message.success("Leave Information Updated Successfully...", "");
            console.log(this.data, 'Update Success')
            if (!addNew) {
              this.drawerClose();
              this.reset(httpForm);
              this.isSpinning = false;
            }
            else {
              this.data = new leaveYearConfiguration();
              this.message.error("Failed To Update Leave information", "");
              console.log(this.data, "fail Update")

              this.isSpinning = false;
            }
            // console.log(this.data.APPLICABLE_TYPES);
          }

        });

      }
      else {

        this.isSpinning = true;
        this.data.START_DATE = this.datepipe.transform(this.data.START_DATE, 'yyyy-MM-dd');
        this.data.END_DATE = this.datepipe.transform(this.data.END_DATE, 'yyyy-MM-dd');


        console.log(this.listOfData, 'Code')
        this.data.ORG_ID = 1;
        this.api.createLeaveConfiguration(this.data)
          .subscribe(successCode => {

            if (successCode['code'] == 200) {
              this.message.success("Leave Information Added Successfully...", "");
              console.log(this.data, "create Success");
              this.getData();
              if (!addNew) {
                this.reset(httpForm);
                this.drawerClose();
              }
              else {
                // httpForm.resetForm();
                this.data = new leaveYearConfiguration();
                this.reset(httpForm);
              }
              this.isSpinning = false;
            }
            else {
              this.message.error("Failed To Add Leave Information...", "");
              console.log(this.data, "Fail data")
              this.isSpinning = false;
            }
          });
      }

    }
  }


  close(httpForm: NgForm) {
    this.reset(httpForm);
    this.drawerClose();
  }

  reset(httpForm: NgForm) {
    httpForm.reset();
    
  }

}

import { Component, OnInit, ViewChild } from "@angular/core";
import { DatePipe } from "@angular/common";
import { NzDatePickerComponent, NzNotificationService } from "ng-zorro-antd";
import { UpdateAttendanceRegister } from "src/app/Models/UpdateAttendanceRegister";
import { ApiService } from "src/app/Service/api.service";
import { CookieService } from "ngx-cookie-service";
import { ExportService } from "src/app/Service/export.service";

@Component({
  selector: 'app-leave-register',
  templateUrl: './leave-register.component.html',
  styleUrls: ['./leave-register.component.css']
})
export class LeaveRegisterComponent implements OnInit {
  monthFormat = "yyyy-MMM-dd";
  formTitle = "Leave Register";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [
    {
      EMPLOYEE_CODE: 'UV001', EMPLOYEE_NAME: 'Abhay Kadam', BRANCH_NAME: 'Sangli', DEPARTMENT_NAME: 'Training',
      "WD": 24,
      "HOD": 7,
      "INPR": 18,
      "HFD": 2,
      "LMD": 1,
      "LMHF": 0,
      "EMD": 0,
      "EMHF": 0,
      "LCD": 3,
      "LCHF": 0,
      THF: 2,
      "COM": 0,
      "L": 0,
      "AB": 0,
      "DETD": 1,

      "OP": 0,
      "AL": 3,
      "AP": 0,
      "FD": 1,
      "IC": 0,
      "CL": 2,

      "OP1": 0,
      "AL1": 3,
      "AP1": 0,
      "FD1": 0,
      "IC1": 0,
      "CL1": 3,

      "OP2": 0,
      "AL2": 6,
      "AP2": 0,
      "FD2": 0,
      "IC2": 0,
      "CL2": 6,

      "OP3": 0,
      "AL3": 0,
      "AP3": 0,
      "FD3": 0,
      "IC3": 0,
      "CL3": 0,

      "OP4": 5,
      "AL4": 0,
      "AP4": 0,
      "BL4": 5,
    },
    {
      EMPLOYEE_CODE: 'UV002', EMPLOYEE_NAME: 'Kedar Kulkarni', BRANCH_NAME: 'Kolhapur', DEPARTMENT_NAME: 'Developoment',
      "WD": 24,
      "HOD": 7,
      "INPR": 18,
      "HFD": 2,
      "LMD": 1,
      "LMHF": 0,
      "EMD": 0,
      "EMHF": 0,
      "LCD": 3,
      "LCHF": 0,
      THF: 2,
      "COM": 0,
      "L": 0,
      "AB": 0,
      "DETD": 1,

      "OP": 0,
      "AL": 3,
      "AP": 0,
      "FD": 1,
      "IC": 0,
      "CL": 2,

      "OP1": 0,
      "AL1": 3,
      "AP1": 0,
      "FD1": 0,
      "IC1": 0,
      "CL1": 3,

      "OP2": 0,
      "AL2": 6,
      "AP2": 0,
      "FD2": 0,
      "IC2": 0,
      "CL2": 6,

      "OP3": 0,
      "AL3": 0,
      "AP3": 0,
      "FD3": 0,
      "IC3": 0,
      "CL3": 0,

      "OP4": 5,
      "AL4": 0,
      "AP4": 0,
      "BL4": 5,
    },
    {
      EMPLOYEE_CODE: 'UV003', EMPLOYEE_NAME: 'Rajdoot Herlekar', BRANCH_NAME: 'Sangli', DEPARTMENT_NAME: 'Developoment',
      "WD": 24,
      "HOD": 7,
      "INPR": 18,
      "HFD": 2,
      "LMD": 1,
      "LMHF": 0,
      "EMD": 0,
      "EMHF": 0,
      "LCD": 3,
      "LCHF": 0,
      THF: 2,
      "COM": 0,
      "L": 0,
      "AB": 0,
      "DETD": 1,

      "OP": 0,
      "AL": 3,
      "AP": 0,
      "FD": 1,
      "IC": 0,
      "CL": 2,

      "OP1": 0,
      "AL1": 3,
      "AP1": 0,
      "FD1": 0,
      "IC1": 0,
      "CL1": 3,

      "OP2": 0,
      "AL2": 6,
      "AP2": 0,
      "FD2": 0,
      "IC2": 0,
      "CL2": 6,

      "OP3": 0,
      "AL3": 0,
      "AP3": 0,
      "FD3": 0,
      "IC3": 0,
      "CL3": 0,

      "OP4": 5,
      "AL4": 0,
      "AP4": 0,
      "BL4": 5,
    },
    {
      EMPLOYEE_CODE: 'UV004', EMPLOYEE_NAME: 'Priyanka Patil', BRANCH_NAME: 'Sangli', DEPARTMENT_NAME: 'HR',
      "WD": 24,
      "HOD": 7,
      "INPR": 18,
      "HFD": 2,
      "LMD": 1,
      "LMHF": 0,
      "EMD": 0,
      "EMHF": 0,
      "LCD": 3,
      "LCHF": 0,
      THF: 2,
      "COM": 0,
      "L": 0,
      "AB": 0,
      "DETD": 1,

      "OP": 0,
      "AL": 3,
      "AP": 0,
      "FD": 1,
      "IC": 0,
      "CL": 2,

      "OP1": 0,
      "AL1": 3,
      "AP1": 0,
      "FD1": 0,
      "IC1": 0,
      "CL1": 3,

      "OP2": 0,
      "AL2": 6,
      "AP2": 0,
      "FD2": 0,
      "IC2": 0,
      "CL2": 6,

      "OP3": 0,
      "AL3": 0,
      "AP3": 0,
      "FD3": 0,
      "IC3": 0,
      "CL3": 0,

      "OP4": 5,
      "AL4": 0,
      "AP4": 0,
      "BL4": 5,
    },
  ];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  startValue: Date | null = null;
  startValue1 = undefined;
  endValue: Date | null = null;
  endOpen = false;
  startOpen = false;
  isFilterApplied: string = "default";
  columns2: string[][] = [
    ["WD", "WD"],
    ["HOD", "HOD"],
    ["INPR", "INPR"],
    ["HFD", "HFD"],
    ["LMD", "LMD"],
    ["LMHF", "LMHF"],
    ["EMD", "EMD"],
    ["EMHF", "EMHF"],
    ["LCD", "LCD"],
    ["LCHF", "LCHF"],
    ["THF", "THF"],
    ["COM", "COM"],
    ["L", "L"],
    ["AB", "AB"],
    ["DETD", "DETD"],
  ]
  columns: string[][] = [ 

    ["OP", "OP"],
    ["AL", "AL"],
    ["AP", "AP"],
    ["FD", "FD"],
    ["IC", "IC"],
    ["CL", "CL"],

    ["OP1", "OP"],
    ["AL1", "AL"],
    ["AP1", "AP"],
    ["FD1", "FD"],
    ["IC1", "IC"],
    ["CL1", "CL"],

    ["OP2", "OP"],
    ["AL2", "AL"],
    ["AP2", "AP"],
    ["FD2", "FD"],
    ["IC2", "IC"],
    ["CL2", "CL"],

    ["OP3", "OP"],
    ["AL3", "AL"],
    ["AP3", "AP"],
    ["FD3", "FD"],
    ["IC3", "IC"],
    ["CL3", "CL"],
    ["OP3", "AL"],

    ["AL4", "AL"],
    ["AP4", "AP"],
    ["BL4", "BL"],

  ];

  today = new Date().getFullYear().toString() + "-" + (new Date().getMonth() + 1).toString() + "-" + new Date().getDate().toString();
  current = new Date();
  filterClass: string = "filter-invisible";
  initFilter = true;
  month = this.today;
  isSpinning = false;
  month1;
  empId: number;
  drawerVisible: boolean;
  drawerData: UpdateAttendanceRegister = new UpdateAttendanceRegister();
  drawerTitle: string;
  excelDataList =[
    {
      EMPLOYEE_CODE: 'UV001', EMPLOYEE_NAME: 'Abhay Kadam', BRANCH_NAME: 'Sangli', DEPARTMENT_NAME: 'Training',
      "WD": 24,
      "HOD": 7,
      "INPR": 18,
      "HFD": 2,
      "LMD": 1,
      "LMHF": 0,
      "EMD": 0,
      "EMHF": 0,
      "LCD": 3,
      "LCHF": 0,
      THF: 2,
      "COM": 0,
      "L": 0,
      "AB": 0,
      "DETD": 1,

      "OP": 0,
      "AL": 3,
      "AP": 0,
      "FD": 1,
      "IC": 0,
      "CL": 2,

      "OP1": 0,
      "AL1": 3,
      "AP1": 0,
      "FD1": 0,
      "IC1": 0,
      "CL1": 3,

      "OP2": 0,
      "AL2": 6,
      "AP2": 0,
      "FD2": 0,
      "IC2": 0,
      "CL2": 6,

      "OP3": 0,
      "AL3": 0,
      "AP3": 0,
      "FD3": 0,
      "IC3": 0,
      "CL3": 0,

      "OP4": 5,
      "AL4": 0,
      "AP4": 0,
      "BL4": 5,
    },
    {
      EMPLOYEE_CODE: 'UV002', EMPLOYEE_NAME: 'Kedar Kulkarni', BRANCH_NAME: 'Kolhapur', DEPARTMENT_NAME: 'Developoment',
      "WD": 24,
      "HOD": 7,
      "INPR": 18,
      "HFD": 2,
      "LMD": 1,
      "LMHF": 0,
      "EMD": 0,
      "EMHF": 0,
      "LCD": 3,
      "LCHF": 0,
      THF: 2,
      "COM": 0,
      "L": 0,
      "AB": 0,
      "DETD": 1,

      "OP": 0,
      "AL": 3,
      "AP": 0,
      "FD": 1,
      "IC": 0,
      "CL": 2,

      "OP1": 0,
      "AL1": 3,
      "AP1": 0,
      "FD1": 0,
      "IC1": 0,
      "CL1": 3,

      "OP2": 0,
      "AL2": 6,
      "AP2": 0,
      "FD2": 0,
      "IC2": 0,
      "CL2": 6,

      "OP3": 0,
      "AL3": 0,
      "AP3": 0,
      "FD3": 0,
      "IC3": 0,
      "CL3": 0,

      "OP4": 5,
      "AL4": 0,
      "AP4": 0,
      "BL4": 5,
    },
    {
      EMPLOYEE_CODE: 'UV003', EMPLOYEE_NAME: 'Rajdoot Herlekar', BRANCH_NAME: 'Sangli', DEPARTMENT_NAME: 'Developoment',
      "WD": 24,
      "HOD": 7,
      "INPR": 18,
      "HFD": 2,
      "LMD": 1,
      "LMHF": 0,
      "EMD": 0,
      "EMHF": 0,
      "LCD": 3,
      "LCHF": 0,
      THF: 2,
      "COM": 0,
      "L": 0,
      "AB": 0,
      "DETD": 1,

      "OP": 0,
      "AL": 3,
      "AP": 0,
      "FD": 1,
      "IC": 0,
      "CL": 2,

      "OP1": 0,
      "AL1": 3,
      "AP1": 0,
      "FD1": 0,
      "IC1": 0,
      "CL1": 3,

      "OP2": 0,
      "AL2": 6,
      "AP2": 0,
      "FD2": 0,
      "IC2": 0,
      "CL2": 6,

      "OP3": 0,
      "AL3": 0,
      "AP3": 0,
      "FD3": 0,
      "IC3": 0,
      "CL3": 0,

      "OP4": 5,
      "AL4": 0,
      "AP4": 0,
      "BL4": 5,
    },
    {
      EMPLOYEE_CODE: 'UV004', EMPLOYEE_NAME: 'Priyanka Patil', BRANCH_NAME: 'Sangli', DEPARTMENT_NAME: 'HR',
      "WD": 24,
      "HOD": 7,
      "INPR": 18,
      "HFD": 2,
      "LMD": 1,
      "LMHF": 0,
      "EMD": 0,
      "EMHF": 0,
      "LCD": 3,
      "LCHF": 0,
      THF: 2,
      "COM": 0,
      "L": 0,
      "AB": 0,
      "DETD": 1,

      "OP": 0,
      "AL": 3,
      "AP": 0,
      "FD": 1,
      "IC": 0,
      "CL": 2,

      "OP1": 0,
      "AL1": 3,
      "AP1": 0,
      "FD1": 0,
      "IC1": 0,
      "CL1": 3,

      "OP2": 0,
      "AL2": 6,
      "AP2": 0,
      "FD2": 0,
      "IC2": 0,
      "CL2": 6,

      "OP3": 0,
      "AL3": 0,
      "AP3": 0,
      "FD3": 0,
      "IC3": 0,
      "CL3": 0,

      "OP4": 5,
      "AL4": 0,
      "AP4": 0,
      "BL4": 5,
    },
  ];
  constructor(private api: ApiService, private datePipe: DatePipe, private message: NzNotificationService, private cookie: CookieService, private _exportService: ExportService) { }

  ngOnInit() {
    this.startValue1 = this.current.getMonth() + 1;
    this.endValue = this.current;
    // this.getBranch();
    // this.getDepartment();
    this.search(true);
  }

  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue) {
      return false;
    }

    return startValue.getTime() > this.current.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.startValue) {
      return false;
    }

    return (
      endValue.getTime() < this.startValue.getTime() ||
      endValue.getTime() >= this.current.getTime()
    );
  };

  onStartChange(date: Date): void {
    this.startValue = date;
  }

  onEndChange(date: Date): void {
    this.endValue = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }

    console.log("handleStartOpenChange", open, this.endOpen);
  }

  handleEndOpenChange(open: boolean): void {
    console.log(open);
    this.endOpen = open;
    this.startOpen = open;
  }

  @ViewChild("moduleEndDatePicker", { static: true }) moduleEndDatePickerVar: NzDatePickerComponent;

  moduleStartDateHandle(open: boolean) {
    if (!open) {
      this.endOpen = true;
    }
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  filterQuery: string = "";

  search(reset: boolean = false, exportInExcel: boolean = false) {
    this.filterQuery = "";

    if (reset) {
      this.pageIndex = 1;
    }

    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND (";

      likeQuery += " EMPLOYEE_NAME like ('%" + this.searchText + "%') OR";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      this.filterQuery += likeQuery.substring(0, likeQuery.length - 3) + ")";
    }

    var monthFilterStr = "";
    if (this.startValue1 != undefined) {
      monthFilterStr = " AND MONTH IN (" + this.startValue1 + ")";
    }

    var yearFilterStr = "";
    if (this.endValue != undefined) {
      yearFilterStr = " AND YEAR IN (" + this.datePipe.transform(this.endValue, "yyyy") + ")";
    }

    var branchFilterStr = "";
    if (this.BRANCH_ID.length > 0) {
      branchFilterStr = " AND BRANCH_ID IN (" + this.BRANCH_ID + ")";
    }

    var departmentFilterStr = "";
    if (this.DEPARTMENT_ID.length > 0) {
      departmentFilterStr = " AND DEPARTMENT_ID IN (" + this.DEPARTMENT_ID + ")";
    }

    if (exportInExcel) {
      this.exportLoading = true;

      // this.api.getEmployeeAttendanceRegister(0, 0, this.sortKey, sort, this.filterQuery + monthFilterStr + yearFilterStr + branchFilterStr + departmentFilterStr + " AND ACTIVE_STATUS=1 AND ORG_ID=" + this.orgId).subscribe(data => {
      //   if (data['code'] == 200) {
      this.exportLoading = false;
      //     this.excelDataList = data['data'];
      //     this.convertInExcel();
      //   }

      // }, err => {
      //   if (err['ok'] == false)
      //     this.message.error("Server Not Found", "");
      // });

    } else {
      this.loadingRecords = true;

      // this.api.getEmployeeAttendanceRegister(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + monthFilterStr + yearFilterStr + branchFilterStr + departmentFilterStr + " AND ACTIVE_STATUS=1 AND ORG_ID=" + this.orgId).subscribe((data) => {
      //   if (data["code"] == 200) {
      this.loadingRecords = false;
      //     this.totalRecords = data["count"];
      //     this.dataList = data["data"];
      //   }

      // }, (err) => {
      //   if (err['ok'] == false)
      //     this.message.error("Server Not Found", "");
      // });
    }
  }

  onKeypressEvent() {
    document.getElementById("searchBtn").focus();
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  clearFilter() {
    this.startValue1 = this.current.getMonth() + 1;
    this.endValue = this.current;
    this.BRANCH_ID = [];
    this.DEPARTMENT_ID = [];
    this.search(true);
    this.isFilterApplied = "default";
    this.filterClass = "filter-invisible";
  }

  applyFilter() {
    if ((this.startValue1 != undefined) || (this.endValue != undefined) || (this.BRANCH_ID.length > 0) || (this.DEPARTMENT_ID.length > 0))
      this.isFilterApplied = "primary";

    else
      this.isFilterApplied = "default";

    this.search(true);
    this.filterClass = "filter-invisible";
  }

  showFilter(): void {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else this.filterClass = "filter-visible";
  }

  edit(data: UpdateAttendanceRegister, empId) {
    data.EMPLOYEE_ID = empId;
    this.drawerData = Object.assign({}, data);
    this.drawerTitle = "Update Status";
    this.drawerVisible = true;
  }

  BRANCH_ID = [];
  DEPARTMENT_ID = [];
  branch = [];
  department = [];
  orgId = this.cookie.get('orgId');

  getBranch() {
    this.branch = [];

    this.api.getAllBranch(0, 0, 'NAME', 'asc', ' AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe(data => {
      if (data['code'] == 200) {
        this.branch = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  getDepartment() {
    this.department = [];

    this.api.getAllDepartments(0, 0, 'NAME', 'asc', ' AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe(data => {
      if (data['code'] == 200) {
        this.department = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  exportLoading: boolean = false;

  importInExcel() {
    this.search(true, true)
  }

  convertInExcel() {

    this._exportService.exportExcel(this.excelDataList, 'Salary Register ' + this.datePipe.transform(new Date(), 'dd-MMM-yy, hh mm ss a'));

  }

  getMonthInText(monthInNumber: string) {
    if (monthInNumber === "1")
      return "January";

    else if (monthInNumber === "2")
      return "February";

    else if (monthInNumber === "3")
      return "March";

    else if (monthInNumber === "4")
      return "April";

    else if (monthInNumber === "5")
      return "May";

    else if (monthInNumber === "6")
      return "June";

    else if (monthInNumber === "7")
      return "July";

    else if (monthInNumber === "8")
      return "August";

    else if (monthInNumber === "9")
      return "September";

    else if (monthInNumber === "10")
      return "October";

    else if (monthInNumber === "11")
      return "November";

    else if (monthInNumber === "12")
      return "December";
  }
}
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NzDatePickerComponent, NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { EmployeeMaster } from 'src/app/Models/employeemaster';
import { ApiService } from 'src/app/Service/api.service';
import { AddemployeemasterComponent } from '../../employeemaster/addemployeemaster/addemployeemaster.component';
// import { AddemployeemasterComponent } from '../../employeemaster/addemployeemaster/addemployeemaster.component';
// import { AddemployeemasterComponent } from '../addemployeemaster/addemployeemaster.component';

@Component({
  selector: 'app-emp-leave-policy-maptable',
  templateUrl: './emp-leave-policy-maptable.component.html',
  styleUrls: ['./emp-leave-policy-maptable.component.css']
})
export class EmpLeavePolicyMaptableComponent implements OnInit {
  formTitle = "Manage Employees";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  @Input() dataList2: any[] = [];
  loadingRecords = false;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  orgId = this.cookie.get('orgId');
  branchId = sessionStorage.getItem('branchId')
  START_DATE: any;
  END_DATE: any;

  columns: string[][] = [["NAME", "Employee Name"], ["DEPARTMENT_NAME", "Department"], ["BRANCH_NAME", "Branch"], ["DESIGNATION_NAME", "Designation"],
  ["EMPLOYEE_REPORTING_HEAD_NAME", "Reporting Head"],
  ["EMPLOYEE_TEMPRARY_HEAD_NAME", "Temporary Head"], ["EMPLOYEE_CODE", "Employee Code"]]

  columns1: string[][] = [["NAME", "Employee Name"], ["DEPARTMENT_NAME", "Department"], ["BRANCH_NAME", "Branch"], ["DESIGNATION_NAME", "Designation"],
  ["EMPLOYEE_REPORTING_HEAD_NAME", "Reporting Head"],
  ["EMPLOYEE_TEMPRARY_HEAD_NAME", "Temporary Head"], ["EMPLOYEE_CODE", "Employee Code"], ["SEQUENCE_NO", "Sequence no"]]

  drawerVisible: boolean;
  drawerVisible2: boolean;
  drawerVisibleReportPerson: boolean
  drawerTitle: string;
  drawerData: EmployeeMaster = new EmployeeMaster();
  applicationId = Number(this.cookie.get('applicationId'))
  drawerVisible1: boolean;
  listOfData: EmployeeMaster[] = [];
  OPEN_TIME2 = null;
  CLOSE_TIME2 = null;
  DAYS = false;
  table2 = 0;
  dataList3 = [];
  loginRoleStatus = this.cookie.get('loginType');
  selectedRoleStatus = this.cookie.get('selectedLoginType');
  ROLE_ID = Number(this.cookie.get('roleId'))
  dataList1 = []
  selecteddata = []

  constructor(private datePipe: DatePipe, private api: ApiService, private cookie: CookieService, private message: NzNotificationService) { }

  ngOnInit() {
    this.search(true);
    this.getpolicies();
    this.getselectedemp();
    this.getBranch();
    this.getEmployees();
    this.getDesignation();
    this.getDepartment();

  }



  getselectedemp() {
    this.api.getselectedemp(0, 0, 'ID', 'ASC', '').subscribe(data => {
      this.selecteddata = data['data'];
      console.log(this.selecteddata);

    }, err => {
      console.log(err);
    });
  }

  getpolicies() {
    this.api.getAllleavepolicy(0, 0, 'ID', 'ASC', 'AND STATUS = 1').subscribe(data => {
      this.dataList1 = data['data'];

    }, err => {
      console.log(err);
    });
  }

  filterClass: string = "filter-invisible";
  BRANCH_ID = "AL"
  DESIGNATION_ID = "AL"
  DEPARTMENT_ID = "AL"
  EMPLOYEE_ID = "AL"
  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";

    else
      this.filterClass = "filter-visible";
  }


  branches = [];

  getBranch() {
    this.api.getAllBranch(0, 0, '', 'asc', 'AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe((data1) => {
      console.log(data1);
      this.branches = data1['data'];
    },
      (err) => {
        console.log(err);
      }
    );
  }

  designations = [];
  getDesignation() {
    this.api.getAllDesignation(0, 0, '', 'asc', 'AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe((data2) => {
      console.log(data2);
      this.designations = data2['data'];
    },
      (err) => {
        console.log(err);
      }
    );
  }
  departments = [];
  getDepartment() {
    this.api.getAllDepartments(0, 0, '', 'asc', 'AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe((data3) => {
      console.log(data3);
      this.departments = data3['data'];
    },
      (err) => {
        console.log(err);
      }
    );
  }
  employees = [];
  isSpinning = false;
  getEmployees() {
    this.api.getAllEmployees(0, 0, '', 'asc', 'AND STATUS=1 AND ORG_ID=' + this.orgId).subscribe((data4) => {
      console.log(data4, 'data4');
      this.employees = data4['data'];
    },
      (err) => {
        console.log(err);
      }
    );
  }
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(false);
  }

  search(reset: boolean = false) {
    this.selectedRoleStatus = this.cookie.get('selectedLoginType');

    var filter = "";
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns1.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')'
    }

    var filter = ""

    if (likeQuery)
      filter = ' AND ORG_ID =' + this.orgId + ' AND ID !=' + Number(this.cookie.get('userId')) + this.filterQuery + likeQuery
    else
      filter = ' AND ORG_ID =' + this.orgId + ' AND ID !=' + Number(this.cookie.get('userId')) + this.filterQuery


    // if (this.ROLE_ID != 14) {
    this.api.getAllemployeeMaster(0, 0, '', sort, filter).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];

        if (this.totalRecords == 0) {
          data.SEQUENCE_NO = 1;

        } else {
          data.SEQUENCE_NO = this.dataList[this.dataList.length - 1]['SEQUENCE_NO'] + 1;
        }
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
    // }

    // else if (this.ROLE_ID == 14) {
    // var branches = ''
    // branches = " AND BRANCH_ID IN (" + this.branchId + ")"
    // this.loadingRecords = true;
    // this.api.getAllemployeeMaster(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + ' AND ORG_ID =' + this.orgId + ' AND ID !=' + Number(this.cookie.get('userId')) + branches).subscribe((data) => {
    //   if (data['code'] == 200) {
    //     this.loadingRecords = false;
    //     this.totalRecords = data['count'];
    //     this.dataList = data['data'];

    //     if (this.totalRecords == 0) {
    //       data.SEQUENCE_NO = 1;

    //     } else {
    //       data.SEQUENCE_NO = this.dataList[this.dataList.length - 1]['SEQUENCE_NO'] + 1;
    //     }
    //   }

    // }, err => {
    //   if (err['ok'] == false)
    //     this.message.error("Server Not Found", "");
    // });
    // }
    //  else {
    //   this.loadingRecords = true;
    //   this.api.getEmployeeAttendanceRegister(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + monthFilterStr + yearFilterStr + branchFilterStr + departmentFilterStr + " AND ACTIVE_STATUS=1 AND ORG_ID=" + this.orgId + " AND ROLE_STATUS IN (" + this.selectedRoleStatus + ")" + " AND EMP_ID != 1").subscribe((data) => {
    //     if (data["code"] == 200) {
    //       this.loadingRecords = false;
    //       this.totalRecords = data["count"];
    //       this.dataList = data["data"];

    //       if (this.initFilter) {
    //         this.initFilter = false;
    //       }
    //     }

    //   }, (err) => {
    //     if (err['ok'] == false)
    //       this.message.error("Server Not Found", "");
    //   });
    // }

  }

  // applyFilter() {
  //   this.isFilterApplied = 'primary'
  //   this.loadingRecords = false;
  //   var sort: string;

  //   try {
  //     sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
  //   }
  //   catch (error) {
  //     sort = '';
  //   }


  //   this.filterQuery = '';

  //   if (this.BRANCH_ID != undefined && this.BRANCH_ID.length > 0) {
  //     this.BRANCH_ID = this.BRANCH_ID
  //   }
  //   if (this.EMPLOYEE_ID != undefined && this.EMPLOYEE_ID.length > 0) {
  //     this.EMPLOYEE_ID = this.EMPLOYEE_ID
  //   }
  //   if (this.DESIGNATION_ID != undefined && this.DESIGNATION_ID.length > 0) {
  //     this.DESIGNATION_ID = this.DESIGNATION_ID
  //   }
  //   if (this.DEPARTMENT_ID != undefined && this.DEPARTMENT_ID.length > 0) {
  //     this.DEPARTMENT_ID = this.DEPARTMENT_ID
  //   }

  //   this.search(true);

  //   this.filterClass = "filter-invisible";
  // }

  applyFilter() {



    if (this.BRANCH_ID == "AL") {
      this.filterQuery = this.filterQuery
    }
    else {
      this.filterQuery += " AND BRANCH_ID=" + this.BRANCH_ID
    }

    if (this.EMPLOYEE_ID == "AL") {
      this.filterQuery = this.filterQuery
    }
    else {
      this.filterQuery += " AND ID=" + this.EMPLOYEE_ID
    }

    if (this.DESIGNATION_ID == "AL") {
      this.filterQuery = this.filterQuery
    }
    else {
      this.filterQuery += " AND DESIGNATION_ID=" + this.DESIGNATION_ID
    }

    if (this.DEPARTMENT_ID == "AL") {
      this.filterQuery = this.filterQuery
    }
    else {
      this.filterQuery += " AND DEPARTMENT_ID=" + this.DEPARTMENT_ID
    }
    // LOAN_AMOUNT



    this.search(true)
    this.filterClass = "filter-invisible";
  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.BRANCH_ID ="AL"
    this.EMPLOYEE_ID = "AL"
    this.DESIGNATION_ID = null;
    this.DEPARTMENT_ID = null;
    this.startValue = new Date();
    this.endValue = new Date();

    this.search();

  }



  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  // add(): void {
  //   this.drawerData = new EmployeeMaster();
  //   this.drawerTitle = "Create New Employee";

  //   this.api.getAllemployeeMaster(1, 1, 'SEQUENCE_NO', 'desc', '' + ' AND ORG_ID=' + this.orgId).subscribe(data => {
  //     if (data['count'] == 0) {
  //       this.drawerData.SEQUENCE_NO = 1;

  //     } else {
  //       this.drawerData.SEQUENCE_NO = data['data'][0]['SEQUENCE_NO'] + 1;
  //     }

  //   }, err => {
  //     console.log(err);
  //   })

  //   this.drawerVisible = true;
  //   this.AddemployeemasterComponentVar.fileURL1 = null;

  //   this.drawerData.BRANCH_ID = 0;
  //   this.drawerData.DEPARTMENT_ID = 0;
  //   this.drawerData.DESIGNATION_ID = 0;
  //   this.drawerData.REPORTING_HEAD_ID = 0;
  //   this.drawerData.TEMPORARY_HEAD_ID = 0;

  //   this.drawerData.REPORTING_PERSON_ID = 0;
  //   this.drawerData.H_PROMISE_ID = 0;
  //   this.drawerData.CORPORATE_MANAGER_ID = 0;
  //   this.drawerData.DIRECTOR_ID = 0;
  //   this.drawerData.ACCOUNT_ID = 0;

  //   this.AddemployeemasterComponentVar.filterEmployeeList(0);
  //   this.AddemployeemasterComponentVar.filterHPromiseEmployeeList(0);
  //   this.AddemployeemasterComponentVar.filterCorporateManagerEmployeeList(0);
  //   this.AddemployeemasterComponentVar.filterDirectorEmployeeList(0);
  //   this.AddemployeemasterComponentVar.filterAccountantEmployeeList(0);
  // }

  // @ViewChild(AddemployeemasterComponent, { static: false }) AddemployeemasterComponentVar: AddemployeemasterComponent;

  // edit(data: EmployeeMaster): void {
  //   this.drawerTitle = "Update Employee Information";
  //   this.drawerData = Object.assign({}, data);

  //   if (this.drawerData.DEPARTMENT_ID == 0)
  //     this.drawerData.DEPARTMENT_ID = 0;

  //   if (this.drawerData.BRANCH_ID == 0)
  //     this.drawerData.BRANCH_ID = 0;

  //   if (this.drawerData.DESIGNATION_ID == 0)
  //     this.drawerData.DESIGNATION_ID = 0;

  //   if (this.drawerData.REPORTING_HEAD_ID == 0)
  //     this.drawerData.REPORTING_HEAD_ID = 0;

  //   if (this.drawerData.TEMPORARY_HEAD_ID == 0)
  //     this.drawerData.TEMPORARY_HEAD_ID = 0;

  //   if ((this.drawerData.REPORTING_PERSON_ID == 0) || (this.drawerData.REPORTING_PERSON_ID == null))
  //     this.drawerData.REPORTING_PERSON_ID = 0;

  //   if ((this.drawerData.H_PROMISE_ID == 0) || (this.drawerData.H_PROMISE_ID == null))
  //     this.drawerData.H_PROMISE_ID = 0;

  //   if ((this.drawerData.CORPORATE_MANAGER_ID == 0) || (this.drawerData.CORPORATE_MANAGER_ID == null))
  //     this.drawerData.CORPORATE_MANAGER_ID = 0;

  //   if ((this.drawerData.DIRECTOR_ID == 0) || (this.drawerData.DIRECTOR_ID == null))
  //     this.drawerData.DIRECTOR_ID = 0;

  //   if ((this.drawerData.ACCOUNT_ID == 0) || (this.drawerData.ACCOUNT_ID == null))
  //     this.drawerData.ACCOUNT_ID = 0;

  //   this.DAYS = false;
  //   var d = '';

  //   if (data.ID != undefined && data.ID > 0) {
  //     d = ' AND ID!=' + data.ID;
  //   }

  //   this.AddemployeemasterComponentVar.getallorg2(data.ID);
  //   this.drawerVisible = true;

  //   this.AddemployeemasterComponentVar.fileURL1 = null;

  //   if ((this.drawerData.SIGNATURE != " ") && (this.drawerData.SIGNATURE != null))
  //     this.drawerData.SIGNATURE = this.api.retriveimgUrl + "signature/" + this.drawerData.SIGNATURE;

  //   else
  //     this.drawerData.SIGNATURE = null;

  //   this.AddemployeemasterComponentVar.filterEmployeeList(data.ID);
  //   this.AddemployeemasterComponentVar.filterHPromiseEmployeeList(data.ID);
  //   this.AddemployeemasterComponentVar.filterCorporateManagerEmployeeList(data.ID);
  //   this.AddemployeemasterComponentVar.filterDirectorEmployeeList(data.ID);
  //   this.AddemployeemasterComponentVar.filterAccountantEmployeeList(data.ID);
  // }
  startValue: Date | null = null;
  endValue: Date | null = null;
  endOpen = false;

  disabledStartDate = (startValue: Date): boolean => {
    if (!startValue || !this.endValue) {
      return false;
    }
    return startValue.getTime() < this.endValue.getTime();
  };

  disabledEndDate = (endValue: Date): boolean => {
    if (!endValue || !this.startValue) {
      return false;
    }
    return endValue.getTime() <= this.startValue.getTime();
  };

  onStartChange(date: Date): void {
    this.startValue = date;
  }

  onEndChange(date: Date): void {
    this.endValue = date;
  }

  handleStartOpenChange(open: boolean): void {
    if (!open) {
      this.endOpen = true;
    }
    console.log('handleStartOpenChange', open, this.endOpen);
  }

  handleEndOpenChange(open: boolean): void {
    console.log(open);
    this.endOpen = open;
  }

  map(data) {
    this.drawerTitle = "Map Roles";
    this.drawerData = Object.assign({}, data);

    this.api.getAllEmpRoleMap(0, 0, 'ID', 'ASC', ' AND EMPLOYEE_ID=' + data.ID).subscribe(data => {
      this.dataList3 = data['data'];
      this.drawerVisible2 = true;
    });
  }

  drawerClose(): void {
    this.search(false);
    this.drawerVisible = false;
  }

  drawerClose1(): void {
    this.search(false);
    this.drawerVisible1 = false;
  }

  drawerClose2(): void {
    // this.AddemployeemasterComponentVar.getEmployeeList();
    // this.AddemployeemasterComponentVar.getHPromiseEmployeeList();
    // this.AddemployeemasterComponentVar.getCorporateManagerEmployeeList();
    // this.AddemployeemasterComponentVar.getDirectorEmployeeList();
    // this.AddemployeemasterComponentVar.getAccountantEmployeeList();

    this.search(false);
    this.drawerVisible2 = false;
  }

  drawerCloseCollege(): void {
    this.search(false);
    this.drawerVisibleEmployeeCollege = false;
  }

  drawerTitle1: string;
  drawerData2: string[];
  drawerData1: EmployeeMaster = new EmployeeMaster();

  expensesMap(data: EmployeeMaster) {
    this.api.getAllEmployeeExpenseHead(data.ID).subscribe(data => {
      if (data['code'] == 200) {
        this.drawerData2 = data['data'];
      }

    }, err => {
      console.log(err);
    });

    this.drawerTitle1 = "Expense Heads for " + data.NAME + "";
    this.drawerData1 = Object.assign({}, data);
    this.drawerVisible1 = true;
  }

  drawerTitleEmployeeCollege: string;
  drawerVisibleEmployeeCollege: boolean;
  drawerDataEmployeeColleges: string[];

  mapCollege(data: EmployeeMaster) {
    this.api.getAllEmployeeColleges(data.ID).subscribe(data => {
      if (data['code'] == 200) {
        this.drawerDataEmployeeColleges = data['data'];
      }

    }, err => {
      console.log(err);
    });

    this.drawerTitleEmployeeCollege = "Location for " + data.NAME + "";
    this.drawerData1 = Object.assign({}, data);
    this.drawerVisibleEmployeeCollege = true;
  }

  get closeCallbackCollege() {
    return this.drawerCloseCollege.bind(this);
  }

  // clearDeviceIDForWeb(data: EmployeeMaster) {
  //   this.api.clearDeviceID(data.ID, "W").subscribe(data => {
  //     if (data['code'] == 200) {
  //       this.message.success("Web ID Cleared Successfully", "");
  //     }

  //   }, err => {
  //     if (err['ok'] == false)
  //       this.message.error("Server Not Found", "");
  //   });
  // }

  cancelForWeb(): void { }

  // clearDeviceIDForMobile(data: EmployeeMaster) {
  //   this.api.clearDeviceID(data.ID, "M").subscribe(data => {
  //     if (data['code'] == 200) {
  //       this.message.success("Mobile ID Cleared Successfully", "");
  //     }

  //   }, err => {
  //     if (err['ok'] == false)
  //       this.message.error("Server Not Found", "");
  //   });
  // }

  cancelForMobile(): void { }

  onEnterKeyPress() {
    document.getElementById("searchbtn").focus();
  }

  onEmailClick(email) {
    // this.api.getLogs(email).subscribe(data => {
    //   if (data['code'] == 200) {
    //     this.message.success("Logs Getting Successfully", "");
    //   }

    // }, err => {
    //   if (err['ok'] == false)
    //     this.message.error("Server Not Found", "");
    // });
  }

  // onRoleStatusChange(data: EmployeeMaster, status) {
  //   data.ROLE_STATUS = status;

  //   this.api.updateemployeeMaster(data).subscribe(successCode => {
  //     if (successCode['code'] == 200)
  //       this.message.success("Role Status Updated Successfully", "");

  //     else
  //       this.message.error("Failed to Update Role Status", "");

  //     this.search(false);
  //   });
  // }

  onActiveStatusChange(data: EmployeeMaster, status) {
    data.STATUS = status;

    this.api.updateemployeeMaster(data).subscribe(successCode => {
      if (successCode['code'] == 200)
        this.message.success("Active Status Updated Successfully", "");

      else
        this.message.error("Failed to Update Active Status", "");

      this.search(false);
    });
  }

  // changeReportingPerson(reportingPersonID: number, data: EmployeeMaster) {
  //   let obj1 = new Object();
  //   obj1["EMPLOYEE_ID"] = data.ID;
  //   obj1["REPORTING_PERSON_ID"] = reportingPersonID;
  //   obj1["STATUS"] = 1;

  //   this.api.updateReportingPerson(obj1).subscribe(successCode => {
  //     if (successCode['code'] == 200)
  //       this.message.success("Reporting Person Updated Successfully", "");

  //     else
  //       this.message.error("Failed to Update Reporting Person", "");

  //     this.search(false);
  //   });
  // }

  map2(data: any) {
    this.drawerVisibleReportPerson = true
  }




  checked = false;
  checked1 = false;
  checkedd = true;
  indeterminate = true;
  indeterminate1 = false;
  setOfCheckedId = new Set<number>();
  setOfCheckedId1 = new Set<number>();
  array = []

  isChecked: boolean
  onChecked1(id: number, checked: boolean, ind: any): void {
    if (checked) {
      console.log(checked);
      this.isChecked = checked
      this.array.push(id)
      console.log(this.array);
      this.indeterminate = false
      // this.dataList[ind].IS_SELECTED = 1
    } else {
      // this.dataList[ind].IS_SELECTED = 0
      this.array.pop()
      console.log(this.array);
      this.indeterminate = true

    }

  }

  onAllChecked1(value: boolean): void {
    console.log(value);
    this.dataList.forEach(item => {
      this.isChecked = value
      if (value) {
        if (value == true) {
          this.array.push(item.ID)
          this.checked1 = true
          this.indeterminate = false
        }
        console.log(this.array);
        // item.IS_SELECTED = 1
      } else {
        // item.IS_SELECTED = 0
        this.array = []
        this.checked1 = false
        this.indeterminate = true
      }

    });

  }

  isApkVersionModalVisible = false;
  isApkVersionModalConfirmLoading = false;
  apkVersionModalTitle: string = "";
  LEAVE_POLICY_ID: any

  handleApkVersionModalCancel(): void {
    this.isApkVersionModalVisible = false;
    this.isApkVersionModalConfirmLoading = false;
    this.startValue = null;
    this.endValue = null;

  }
  showApkVersionModal(): void {
    this.START_DATE = ""
    this.END_DATE = ""
    this.LEAVE_POLICY_ID = ""
    this.isApkVersionModalVisible = true;
    this.apkVersionModalTitle = "Map Policy";
  }

  SendData = new Object()
  Time1: any
  Time2: any
  handleApkVersionModalOk(): void {
    var isOk = true;
    this.Time1 = this.datePipe.transform(this.startValue, "yyyy-MM-dd")
    this.Time2 = this.datePipe.transform(this.endValue, "yyyy-MM-dd")

    if ((this.LEAVE_POLICY_ID == undefined) || (this.LEAVE_POLICY_ID == null)) {
      isOk = false;
      this.message.error("Please Select Policy", "");
    }

    if ((this.startValue == undefined) || (this.startValue == null)) {
      isOk = false;
      this.message.error("Please Enter Start Date", "");
    }

    if ((this.endValue == undefined) || (this.endValue == null)) {
      isOk = false;
      this.message.error("Please Enter End Date", "");
    }


    if (isOk) {
      this.isApkVersionModalConfirmLoading = true;
      this.SendData = ({
        LEAVE_POLICY_ID: this.LEAVE_POLICY_ID,
        START_DATE: this.Time1,
        END_DATE: this.Time2,
        EMPLOYEE_IDS: this.array,
        ORG_ID: this.cookie.get('orgId')
      });
      console.log(this.SendData);
      this.api.addbulkpolicymap(this.SendData)
        .subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Employee Ploicy Mapped Successfully ...", "");
            this.drawerClose();
            this.isApkVersionModalConfirmLoading = false;
            this.isApkVersionModalVisible = false;
            this.array = []
            this.startValue = null;
            this.endValue = null;
            this.indeterminate = true
          }
          else {
            this.message.error("Employee Ploicy Mapping Failed...", "");
            this.isApkVersionModalConfirmLoading = false;
            this.array = []
            this.indeterminate = true
          }
        });
    }
  }
  prevModalVisible: any
  prevModalTitle: any
  isprevModalLoading = false
  mappedData = []
  previewPolicy(data: any, i: any) {
    console.log(data['ID']);
    this.prevModalTitle = "View Policy"
    this.prevModalVisible = true
    var EMPLOYEE_ID = data['ID']
    this.api.getMappedPolicy(EMPLOYEE_ID).subscribe(data => {
      if (data['code'] == '200') {
        this.mappedData = data['data']
      }
    })
  }

  handleprevModalCancel() {
    this.prevModalVisible = false
  }
  prevCancel() {
    this.prevModalVisible = false
  }
  ID: number
  USER_ID: any

  deletePolicy(data: any, i: any) {
    this.ID = data.ID
    this.USER_ID = this.cookie.get('userId')
  }
  cancel(): void {
    this.prevModalVisible = false
  }
  confirm(): void {
    this.api.deletMappedPolicy(this.ID, this.USER_ID).subscribe(data => {
      if (data['code'] == '200') {
        this.prevModalVisible = false
        this.message.success('Policy deleted succesfull', '')
        this.search(true)
        this.array = []
        this.indeterminate = true
      }
      else {
        this.message.error('Something went wrong', '')
        this.array = []
      }
    })
  }






}
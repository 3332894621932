import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { differenceInCalendarDays, setHours } from 'date-fns'
import { NzNotificationService } from 'ng-zorro-antd';
import { ApiService } from 'src/app/Service/api.service';
import { leaveYearConfiguration } from '../leaveyearconfiguration';
// import { leaveYearConfiguration } from '../Models/leaveyearconfiguration';
// import { ApiService } from '../Service/api.service';
@Component({
  selector: 'app-leave-yearconfigurationtable',
  templateUrl: './leave-yearconfigurationtable.component.html',
  styleUrls: ['./leave-yearconfigurationtable.component.css']
})
export class LeaveYearconfigurationtableComponent implements OnInit {

  constructor(private api: ApiService, private message: NzNotificationService, private datepipe: DatePipe) { }

  columns: string[][] = [["NAME", "Name"],
  ["START_DATE", "Start Date"],
  ["END_DATE", "End Date"],
  ["LEAVE_CREDIT_DAY", "Leave Credit Day"],
  ["COMPOFF_LAPS_DAY", "Compoff Lapse"]]
  // ["IS_EARNED","Is Earned"]]

  // @Input() data: leaveMaster = new leaveMaster();
  @Input() listOfData: any;
  formTitle = "Leave Year Configuration";
  filterClass: string = 'filter-invisible';
  searchText: string = "";
  isVisible = false;
  isSpinning = false;
  loadingRecords = false;
  user: leaveYearConfiguration = new leaveYearConfiguration();
  allotmentId: any;

  dataList: any[] = [];
  drawerTitle: string | undefined;
  switch5 = false;
  switch6 = false;
  ALLOTMENT_TYPE_ID = null;

  pageIndex = 1;
  pageSize = 10;
  sortKey: string = "id";
  sortValue: string = "desc";
  totalRecords = 1;
  listOfData2: any = []
  disabledEndDate2
  startValue: any = new Date();
  endValue: any = new Date();
  endOpen = false;
  startOpen = false;
  today2 = new Date();
  dates: any = [];
  isFilterApplied: string = 'default';
  filterQuery: string = '';

  drawerClose() {
    this.isVisible = false;
    this.getData();
  }
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  // sort: { key: string; value: string }
  sort(sort: any): void {

    this.sortKey = sort.key;
    this.sortValue = sort.value;


    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  search(reset: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }

    // this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    console.log("search text : " + this.searchText);
    // console.log(sort);

    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND ";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
        // likeQuery += " " + `${column[0]==='GENDER'?'GENDER_NAME':''} ` + " like '%" +this.searchText +"%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    if (likeQuery)
      filter += likeQuery;

    this.api.getLeaveConfiguration(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, this.filterQuery + likeQuery).subscribe(data => {
      console.log(data['data']);
      this.loadingRecords = false;
      this.isFilterApplied = 'primary';
      this.totalRecords = data['count'];
      this.dataList = data['data'];
      this.isSpinning = false;
      this.filterClass = 'filter-invisible';

    }, err => {
      console.log(err);
    });
  }

  showFilter() {
    if (this.filterClass === 'filter-visible')
      this.filterClass = 'filter-invisible';
    else this.filterClass = 'filter-visible';

  }


  applyFilter() {

    var sort: string;
    try {
      sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
    } catch (error) {
      sort = '';
    }

    this.startValue = this.datepipe.transform(this.startValue, 'yyyy-MM-dd');
    this.endValue = this.datepipe.transform(this.endValue, 'yyyy-MM-dd');


    this.filterQuery = '';
    this.startValue = this.datepipe.transform(this.startValue, 'yyyy-MM-dd');
    this.endValue = this.datepipe.transform(this.endValue, 'yyyy-MM-dd');

    if (this.startValue != null && this.endValue != null) {
      this.filterQuery = " AND START_DATE BETWEEN '" + this.startValue + "' AND '" + this.endValue + "' "

      var filter = '';
      filter = this.filterQuery;
      var likeQuery = "";
    }


    this.search();
    var likeQuery = '';


    if (this.searchText != '') {
      likeQuery = ' AND';
      this.columns.forEach((column) => {
        likeQuery += ' ' + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2);

      //console.log("likeQuery" + likeQuery);
    }

    this.api
      .getLeaveConfiguration(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery)
      .subscribe(
        (data) => {
          console.log(data);
          this.loadingRecords = false;
          this.isFilterApplied = 'primary';
          this.totalRecords = data['count'];
          this.dataList = data['data'];
          this.isSpinning = false;
          this.filterClass = 'filter-invisible';
          // this.search();
        },
        (err) => {
          console.log(err);
        }
      );
  }

  clearFilter() {
    this.filterClass = 'filter-invisible';
    this.startValue = new Date();
    this.endValue = new Date();

    this.isFilterApplied = 'default';
    this.filterQuery = '';

    this.dataList = [];
    this.search();
    // this.data.ALLOTMENT_TYPE_ID = null;

  }

  startDateChange() {
    var startDate = this.datepipe.transform(this.startValue, 'yyyy-MM-dd');
    var endDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');

    console.log(this.getDaysArray(startDate, endDate));
    console.log(this.dates);
  }

  getDaysArray(start: any, end: any) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(this.datepipe.transform(dt, 'yyyy-MM-dd'));
      this.dates.push(this.datepipe.transform(dt, 'yyyy-MM-dd'));
    }
    return arr;
  }

  timeDefaultValue = setHours(new Date(), 0);

  ngOnInit() {
    this.search();
    // this.getData();
  }

  getData() {
    this.api.getLeaveConfiguration(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, '').subscribe((data: any) => {
      this.loadingRecords = false;
      this.dataList = data['data'];
      this.totalRecords = data['count'];
      console.log(this.dataList, 'getFunction');

    }, err => {
      console.log(err);
    });
  }

  disabledStartDate2 = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today2) > 0;

  moduleStartDateHandle(open: boolean) {
    if (!open) {
      this.endOpen = true;
    }
  }

  add() {
    this.drawerTitle = "Create Leave Year Configuration";
    this.user = new leaveYearConfiguration();
    this.isVisible = true;
  }

  edit(data: leaveYearConfiguration) {

    console.log(data);

    this.drawerTitle = 'Update Leave';
    this.user = Object.assign({}, data);

    this.api.getLeaveConfiguration(0, 0, '', '', " AND ID !=" + data.ID).subscribe(data => {
      this.listOfData2 = data['data'];

    }, err => {
      console.log(err);
    });

    this.user.LEAVE_CREDIT_DAY = String(this.user.LEAVE_CREDIT_DAY);
    this.isVisible = true;
  }
}

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NzDatePickerComponent, NzNotificationService } from 'ng-zorro-antd';
import { Leave } from 'src/app/Models/Leave';
import { ApiService } from 'src/app/Service/api.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { NgForm } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-add-employee-leave',
  templateUrl: './add-employee-leave.component.html',
  styleUrls: ['./add-employee-leave.component.css']
})

export class AddEmployeeLeaveComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: Leave;
  @Input() drawerVisible: boolean;
  isSpinning = false;

  leaveTypes = [];

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe) { }

  ngOnInit() {
    this.getLeaveTypes();
    this.getLeaveTypeMaster();
  }

  getLeaveTypes() {
    this.leaveTypes = [];
    var filter = "AND EMPLOYEE_ID=" + this.api.userId
    this.api.getAllEmployeeLeave1(0, 0, '', 'asc', '', this.api.userId).subscribe(data => {
      if (data['code'] == 200) {
        console.log(data);
        this.leaveTypes = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  close(myForm: NgForm): void {
    this.drawerClose();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  save(addNew: boolean, myForm: NgForm): void {
    var isOk = true;

    if (this.data.TYPE_ID == undefined || this.data.TYPE_ID == 0) {
      isOk = false;
      this.message.error("Please Select Valid Leave Type", "");
    }

    else if (this.data.FROM_DATE == undefined) {
      isOk = false;
      this.message.error("Please Select Valid From Date", "");
    }

    else if (this.data.TO_DATE == undefined) {
      isOk = false;
      this.message.error("Please Select Valid To Date", "");
    }

  
    else if (this.data.MODE == null || this.data.MODE == 0) {
      isOk = false;
      this.message.error("Please Select Mode", "");
    }
    else if (this.data.REASON == undefined || this.data.REASON.trim() == '') {
      isOk = false;
      this.message.error("Please Enter Valid Reason", "");
    }

    // else if (this.data.MODE == '2') {
    //   isOk = false;
    //   this.message.error("Please Select Session", "");
    // }

    else if (this.balance == 0) {
      isOk = false;
      this.message.error("You don't have enough balance to take this leave, please select Loss of pay TYPE LEAVE and apply again.", "");
    }
    if (isOk) {
      this.isSpinning = true;

      this.data.FROM_DATE = this.datePipe.transform(this.data.FROM_DATE, "yyyy-MM-dd");
      if (this.data.MODE == '2') {
        this.data.TO_DATE = this.datePipe.transform(this.data.TO_DATE, "yyyy-MM-dd");
        this.data.SESSION = this.data.SESSION
      }
      else {
        this.data.TO_DATE = this.datePipe.transform(this.data.TO_DATE, "yyyy-MM-dd");
        this.data.SESSION = '0'
      }

      this.data.EMPLOYEE_ID = this.api.userId;
     
        this.data.MODE = this.data.MODE.toString()
    


      if (this.data.ID) {
        this.api.updateLeave(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Leave Updated Successfully", "");

            if (!addNew)
              this.close(myForm);

            this.isSpinning = false;

          } else {
            this.message.error("Leave Updation Failed", "");
            this.isSpinning = false;
          }
        });

      } else {
        console.log(this.data)
        this.api.createLeave(this.data).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.message.success("Leave Created Successfully", "");

            if (!addNew)
              this.close(myForm);

            else {
              this.data = new Leave();
            }

            this.isSpinning = false;

          } else {
            this.message.error("Leave Creation Failed", "");
            this.isSpinning = false;
          }
        });
      }
    }
  }

  today = new Date().setDate(new Date().getDate() + 4);

  disabledStartDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) < 0;

  @ViewChild('moduleEndDatePicker', { static: false }) moduleEndDatePicker: NzDatePickerComponent;

  moduleStartDateHandle(open: boolean) {
    if (!open) {
      this.data.TO_DATE = null;
      this.data.TO_DATE = this.data.FROM_DATE;

      // this.moduleEndDatePicker.open();
    }
  }
  disabledmode=false
  disabledEndDate = (endValue: Date): boolean => {
    if (this.data.FROM_DATE != null) {
      if (!endValue) {
        return false;
      }

      // var modulePreviousDate = new Date(this.data.FROM_DATE);
      // modulePreviousDate.setDate(modulePreviousDate.getDate() + (-1));

      var modulePreviousDate = new Date(this.data.FROM_DATE)

      return endValue <= new Date(modulePreviousDate);
    };
  }

  leaveTypeDetails = [];
  // EMPLOYEE_ID
  getLeaveTypeMaster() {
    // this.api.getAllLeaveType(0, 0, 'ID', 'asc', '').subscribe(data => {
    //   if (data['code'] == 200) {
    //     this.leaveTypeDetails = data['data'];
    //   }

    // }, err => {
    //   console.log(err);
    // });

    // this.leaveTypeDetails = [];
    // var filter =" AND LEAVE_POLICY_STATUS=1"
    this.api.getAllEmployeeLeave1(0, 0, '', 'asc', '', this.api.userId).subscribe(data => {
      if (data['code'] == 200) {
        console.log(data);
        this.leaveTypeDetails = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }
  balance
  leavetype
  onLeaveTypeSelected(leaveType) {

    if (this.leaveTypeDetails.length > 0) {
      var leaveData = this.leaveTypeDetails.filter(obj => {
        return (obj.PAYROLL_LEAVE_ID == leaveType);


      });

      console.log(leaveData, leaveType, "leaveType");

      if (leaveData.length > 0) {
        this.today = new Date().setDate(new Date().getDate() + Number(leaveData[0]["LEAVE_APPROVAL_DAYS"]));
        this.balance = leaveData[0]["BALANCE"]
        this.data.MODE = leaveData[0]['APPLICABLE_TYPES']
        this.leavetype = leaveData[0]["PAYROLL_LEAVE_NAME"]

        console.log(this.data.MODE);
        if(this.balance==0 &&this.leavetype!="LWP" ){
          this.disabledmode=true
        }

      }
      else
        this.today = new Date().setDate(new Date().getDate() + Number(4));

        this.data.FROM_DATE = this.datePipe.transform(this.today, "yyyy-MM-dd");
      this.data.TO_DATE = this.datePipe.transform(this.today, "yyyy-MM-dd");
      console.log(this.data.FROM_DATE, "E");

        this.disabledStartDate = (current: Date): boolean =>
          differenceInCalendarDays(current, this.today) < 0;
      
      this.data.MODE=null
      
    }
  }
}

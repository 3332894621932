import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { NzNotificationService } from 'ng-zorro-antd';
import { Roledetails } from './Models/roledetails';
import { ViewNotificationDrawerComponent } from './pages/NotificationModule/view-notification-drawer/view-notification-drawer.component';
import { ChangePasswordDrawerComponent } from './pages/CommonModule/change-password-drawer/change-password-drawer.component';
import { Attendance } from './Models/Attedance';
import { DatePipe } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { environment } from 'src/environments/environment';

declare const L: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  menus = [];
  isCollapsed = false;
  isLogedIn = false;
  message1;
  formsParent: Roledetails[];
  forms: Roledetails[];
  detailsData: Roledetails = new Roledetails();
  EMAIL_ID = "";
  PASSWORD = "";
  supportKey = "";
  ORGANIZATION_ID: number;
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData = [];
  userId = this.cookie.get('userId');
  USERNAME = this.cookie.get('userName');
  roleId = Number(this.cookie.get('roleId'));
  pageName: string = "";
  pageName2: string = "";
  dataList = [];
  ROLE_ID = Number(this.cookie.get('roleId'));
  org = [];
  shownotify = false;
  currentApplicationVersionForDevelopment: string = environment.firebase.appVersion1;
  currentApplicationVersionForProduction: string = environment.firebase.appVersion2;

  constructor(private router: Router, private api: ApiService, private cookie: CookieService, private message: NzNotificationService, private datePipe: DatePipe) {
    this.loggerInit();
  }

  loggerInit() {
    // console.log("before init");
    if (this.cookie.get('supportKey') === '' || this.cookie.get('supportKey') === null) {
      // console.log("after device id");
      this.api.loggerInit().subscribe(data => {
        // console.log("data from api logger init");
        // console.log(data);
        if (data['code'] == "200") {
          this.cookie.set('supportKey', data["data"][0]["supportkey"], 365, "", "", false, "Strict");
          // console.log("after init");
        }
      }, err => {
        // console.log(err);
      });
    } else {
      // console.log("present device id");
      // console.log(this.cookie);
    }
  }

  setUserId(event) {
    this.ROLE_ID = event;
    this.roleId = event;

    sessionStorage.setItem('roleId', this.roleId.toString());

    this.cookie.set('roleId', this.roleId.toString(), 365, "", "", false, "Strict");
    this.router.navigateByUrl('/dashboard');

    this.router.navigate(['dashboard'])
      .then(() => {
        window.location.reload();
      });
  }

  ngOnInit() {
    this.getMyOfficeLatitudeLongitude();
    let url = window.location.href;
    var arr = url.split("/");
    // this.pageName2 = arr[3];
    this.pageName2 = arr[(arr.length) - 1];
    console.log(this.pageName2);

    if (this.cookie.get('token') === '' || this.cookie.get('token') === null) {
      this.isLogedIn = false;
      let url = window.location.href;
      var arr = url.split("/");

      // if (arr[3] == "adminlogin") {
      if (arr[(arr.length) - 1] == "adminlogin") {
        this.pageName2 = 'adminlogin';
        console.log("Page : " + this.pageName2);
        this.router.navigateByUrl('/adminlogin');

      } else {
        this.pageName2 = 'login';
        console.log("Page : " + this.pageName2);
        this.router.navigateByUrl('/login');
      }

    } else {
      if (this.userId || this.roleId != 0) {
        this.isLogedIn = true;
        this.accessPageForRedirect();
        this.loadForms();

        this.api.getAllEmpRoleMap(0, 0, 'ID', 'ASC', ' AND STATUS=1 AND EMPLOYEE_ID=' + this.userId).subscribe(data => {
          this.dataList = data['data'];

        }, err => {
          console.log(err);
        });

      } else {
        this.cookie.deleteAll();
      }
    }

    this.api.requestPermission(this.userId);
    this.api.receiveMessage();

    var fullName = this.USERNAME.split(' ');
    if (fullName.length > 1)
      this.USERNAME = fullName[0] + ' ' + fullName[fullName.length - 1];

    else
      this.USERNAME = fullName[0];

    // if (this.userId)
    //   this.getTodaysDayInDayOut();
  }

  accessPageForRedirect() {
    // if (this.roleId != 0) {
    //   let url = window.location.href;
    //   var arr = url.split("/");
    //   let validPage = "/" + arr[3];

    //   this.api.getCheckAccessOfForm(this.roleId, validPage).subscribe(data => {
    //     if (data['data'] == true) {
    //       console.log(data['data']);
    //       this.router.navigateByUrl(validPage);
    //       this.pageName = arr[3];

    //     } else {
    //       if ((validPage != "/adminlogin") && (validPage != "/login") && (validPage != "/dashboard")) {
    //         this.api.logoutForSessionValues();
    //       }
    //     }
    //   });
    // }

    // if (this.roleId != 0) {
    //   let url = window.location.href;
    //   var arr = url.split("/");
    //   // let validPage = "/" + arr[3];
    //   let validPage = "/" + arr[(arr.length) - 1];

    //   if ((validPage != "/adminlogin") && (validPage != "/login") && (validPage != "/dashboard")) {
    //     // this.router.navigateByUrl('/dashboard');

    //     // this.router.navigate(['dashboard']).then(() => {
    //     //   window.location.reload();
    //     // });
    //     this.api.logoutForSessionValues()
    //   }
    // }

   
      if (this.roleId != 0) {
        let url = window.location.href;
        var arr = url.split("/");
        let validPage = "/" + arr[3];
  
        //  {
        //   this.router.navigateByUrl('/dashboard');
        //   this.router.navigate(['dashboard']).then(() => {
        //     window.location.reload();
  
        //   });
  
        // }
        this.api.getCheckAccessOfForm(this.roleId, validPage).subscribe(data => {
          if (data['data'] == true) {
            if (validPage == "/selectpage")
              this.router.navigateByUrl('/dashboard')
            else
              this.router.navigateByUrl(validPage)
          }
          else {
            if ((validPage != "/traineraccessorlogin") && (validPage != "/adminlogin") && (validPage != "/login") && (validPage != "/orglogin")) {
              this.api.logoutForSessionValues()
            }
          }
        });
      }
    
  }

  loadForms() {
    this.api.getForms(this.userId, this.roleId,).subscribe(data => {
      if (data['code'] == 200) {
        data['data'].forEach(element => {
          element['children'].sort(this.sortFunction)

          if (element['children'].length == 0)
            delete element['children']
        });

        this.menus = data['data'].sort(this.sortFunction)
      }
    });
  }

  sortFunction(a, b) {
    var dateA = a.SEQ_NO;
    var dateB = b.SEQ_NO;
    return dateA > dateB ? 1 : -1;
  };

  getData(form) {
    this.cookie.set('roleId', form.ROLE_ID);
  }

  isSpinning = false;

  logout() {
    this.isSpinning = true;

    this.api.logout().subscribe(forms => {
      if (this.roleId != 1) {
        this.api.unsubscribeTokenToTopic(this.api.cloudID);
      }

      setTimeout(() => {
        window.location.reload();
      }, 1000);

      this.cookie.deleteAll();
      sessionStorage.clear();

    }, err => {
      setTimeout(() => {
        window.location.reload();
      }, 1000);

      this.cookie.deleteAll();
      sessionStorage.clear();

      this.isSpinning = false;
      this.message.error("Failed to Logout", "");
      console.log(err);
    });
  }

  @ViewChild(ViewNotificationDrawerComponent, { static: false }) ViewNotificationDrawerComponentVar: ViewNotificationDrawerComponent;

  add(): void {
    this.drawerTitle = "Notifications";
    this.drawerVisible = true;
    this.ViewNotificationDrawerComponentVar.pageSize = 8;
    this.ViewNotificationDrawerComponentVar.getNotifications();
  }

  drawerClose(): void {
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  changePasswordDrawerVisible: boolean = false;
  changePasswordDrawerTitle: string;

  @ViewChild(ChangePasswordDrawerComponent, { static: false }) ChangePasswordDrawerComponentVar: ChangePasswordDrawerComponent;

  showChangePasswordDrawer(): void {
    this.changePasswordDrawerTitle = "Reset Password";
    this.changePasswordDrawerVisible = true;
    this.ChangePasswordDrawerComponentVar.getInfo();
  }

  changePasswordDrawerClose(): void {
    this.changePasswordDrawerVisible = false;
  }

  get changePasswordDrawerCloseCallback() {
    return this.changePasswordDrawerClose.bind(this);
  }

  size = 'large';

  intime() {
    var data = new Attendance();
    data.IN_LOCATION = "16.846520_74.612343";
    data.IN_DISTANCE = "16m";

    this.api.createAttendance(data).subscribe(data => {
      if (data['code'] == '200') {
        this.message.success("Successfully Day In", "");

        if (this.userId)
          this.getTodaysDayInDayOut();

      } else {
        this.message.error("Failed to Day In", "");
      }
    });
  }

  outtime() {
    var data = new Attendance();
    data.ID = this.ID;
    data.OUT_LOCATION = "16.846520_74.612343";
    data.OUT_DISTANCE = "16m";

    this.api.updateAttendance(data).subscribe(data => {
      if (data['code'] == '200') {
        this.message.success("Successfully Day Out", "");

        if (this.userId)
          this.getTodaysDayInDayOut();

      } else {
        this.message.error("Failed to Day Out", "");
      }
    });
  }

  ID: any;
  inOutData: any;
  inTime: any;
  outTime: any;
  inOut = 0;
  showBtns: null;

  getTodaysDayInDayOut() {
    this.ID = null;
    this.inOut = 0;

    var filterQuery = " AND (DATE BETWEEN '" + this.datePipe.transform(new Date(), 'yyyy-MM-dd') + "' AND '" + this.datePipe.transform(new Date(), 'yyyy-MM-dd') + "' ) AND"
    filterQuery += " EMPLOYEE_ID = " + this.userId + " AND ";
    filterQuery += "STATUS IN ('P','L','A','HF','H','LM') ";

    this.api.getAttendanceRegister(0, 0, 'id', 'desc', filterQuery).subscribe(data => {
      if (data['code'] == 200) {
        console.log(data);
        this.showBtns = data['data'];

        if (data['count'] > 0) {
          this.ID = data['data'][0]['ID'];
          this.inOutData = data['data'][0];
          this.inTime = this.datePipe.transform(new Date(), "yyyy-MM-dd") + ' ' + this.inOutData['IN_TIME'];
          this.outTime = this.datePipe.transform(new Date(), "yyyy-MM-dd") + ' ' + this.inOutData['OUT_TIME'];
          console.log(this.inTime);
          console.log(this.outTime);

          if (data['data'][0]['OUT_LOCATION'] == '' && data['data'][0]['IN_LOCATION'] == '') {
            this.inOut = 0;

          } else if (data['data'][0]['OUT_LOCATION'] == '' && data['data'][0]['IN_LOCATION'] != '') {
            this.inOut = 1;

          } else if (data['data'][0]['OUT_LOCATION'] != '' && data['data'][0]['IN_LOCATION'] != '') {
            this.inOut = 2;
          }

        } else {
          this.inOut = 0;
        }

        this.gettingDetails(this.inOut);

      } else {
        this.message.error("Failed to get Details", "");
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  dayInBtnStatus: boolean = true;
  dayOutBtnStatus: boolean = true;

  gettingDetails(inOutStatus) {
    this.dayInBtnStatus = true;
    this.dayOutBtnStatus = true;

    if (inOutStatus == 0) {
      console.log("Not Day In");
      console.log("Not Day Out");

      this.dayOutBtnStatus = false;

    } else if (inOutStatus == 1) {
      console.log("Day In");
      console.log("Not Day Out");

      this.dayInBtnStatus = false;

    } else if (inOutStatus == 2) {
      console.log("Day In");
      console.log("Day Out");

      this.dayInBtnStatus = false;
      this.dayOutBtnStatus = false;
    }
  }

  isApkVersionModalVisible = false;
  isApkVersionModalConfirmLoading = false;
  apkVersionModalTitle: string = "";

  showApkVersionModal(): void {
    this.isApkVersionModalVisible = true;
    this.apkVersionModalTitle = "APK Details";
  }

  handleApkVersionModalCancel(): void {
    this.isApkVersionModalVisible = false;
    this.isApkVersionModalConfirmLoading = false;
    this.uploadProgress = 0;
    this.isProgressVisible = false;
    this.MIN_VERSION = undefined;
    this.CUR_VERSION = undefined;
    this.fileURL = null;

    if (this.timer != undefined)
      this.timer.unsubscribe();
  }

  handleApkVersionModalOk(): void {
    var isOk = true;

    if (this.MIN_VERSION != undefined) {
      if (this.MIN_VERSION.trim() == "") {
        isOk = false;
        this.message.error("Please Enter Minimum Version", "");
      }
    } else {
      isOk = false;
      this.message.error("Please Enter Minimum Version", "");
    }

    if (this.CUR_VERSION != undefined) {
      if (this.CUR_VERSION.trim() == "") {
        isOk = false;
        this.message.error("Please Enter Current Version", "");
      }
    } else {
      isOk = false;
      this.message.error("Please Enter Current Version", "");
    }

    if ((this.fileURL == undefined) || (this.fileURL == null)) {
      isOk = false;
      this.message.error("Please Choose Valid APK File", "");
    }

    if (isOk) {
      this.isApkVersionModalConfirmLoading = true;
      this.imageUpload();
    }
  }

  MIN_VERSION: any;
  CUR_VERSION: any;
  fileURL: File = null;

  onFileSelected(event) {
    this.fileURL = <File>event.target.files[0];
  }

  clear() {
    this.fileURL = null;
  }

  folderName = "apk";
  uploadedAttachmentStr: string;
  uploadProgress: number = 0;
  isProgressVisible: boolean = false;
  timer: any;

  imageUpload() {
    this.uploadedAttachmentStr = "";

    if (this.fileURL) {
      var number = Math.floor(100000 + Math.random() * 900000);
      var fileExt = this.fileURL.name.split('.').pop();
      var url = "APK" + number + "." + fileExt;

      this.timer = this.api.onFileUploadWithProgress(this.folderName, this.fileURL, url).subscribe(res => {
        if (res.type === HttpEventType.Response) {
          this.isProgressVisible = false;
          console.log('Upload complete');
          this.uploadedAttachmentStr = this.api.retriveimgUrl + "apk/" + url;
          var obj1 = new Object();
          obj1["MIN_VERSION"] = this.MIN_VERSION;
          obj1["CUR_VERSION"] = this.CUR_VERSION;
          obj1["APK_LINK"] = this.uploadedAttachmentStr;

          this.apkInformationUpdate(obj1);
        }

        if (res.type === HttpEventType.UploadProgress) {
          this.isProgressVisible = true;
          const percentDone = Math.round(100 * res.loaded / res.total);
          console.log('Progress ' + percentDone + '%');
          this.uploadProgress = percentDone;
        }

      }, err => {
        this.isApkVersionModalConfirmLoading = false;

        if (err['ok'] == false)
          this.message.error("Failed to Upload the File", "");
      });
    }
  }

  apkInformationUpdate(apkData) {
    this.api.updateGlobalSettingInfo(apkData).subscribe(successCode => {
      if (successCode['code'] == 200) {
        this.message.success("APK Information Updated Successfully", "");
        this.handleApkVersionModalCancel();

      } else {
        this.message.error("APK Information Updation Failed", "");
        this.isApkVersionModalConfirmLoading = false;
      }

    }, err => {
      this.isApkVersionModalConfirmLoading = false;

      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  numberWithDecimal(event: any) {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }

  isDayInDayOutModalVisible = false;
  isDayInDayOutModalConfirmLoading = false;
  dayInDayOutModalTitle: string = "";

  showDayInDayOutModal(dayInDayOut): void {
    this.getCurrentPosition();
    this.isDayInDayOutModalVisible = true;

    if (dayInDayOut == "Day In")
      this.dayInDayOutModalTitle = "Day In";

    else if (dayInDayOut == "Day Out")
      this.dayInDayOutModalTitle = "Day In";
  }

  handleDayInDayOutModalCancel(): void {
    this.isDayInDayOutModalVisible = false;
    this.isDayInDayOutModalConfirmLoading = false;
  }

  getCurrentPosition() {
    var container = L.DomUtil.get('map');
    if (container != null) {
      container._leaflet_id = null;
    }

    if (!navigator.geolocation) {
      console.log("Location Not Supported");

    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        const coordinates = position.coords;
        const LatLong = [coordinates.latitude, coordinates.longitude];
        console.log(`Latitude : ${coordinates.latitude}, Longitude : ${coordinates.longitude}`);
        let map = L.map('map').setView(LatLong, 20);

        // // Street Map
        // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        //   maxZoom: 19,
        //   attribution: '© OpenStreetMap'
        // }).addTo(map);

        // Satellite Map
        L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
          maxZoom: 20,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
        }).addTo(map);

        let marker = L.marker(LatLong).addTo(map);
        this.findDistance(LatLong[0], this.myOfficeLatitude, LatLong[1], this.myOfficeLongitude);
      });

      // this.watchPosition();
    }
  }

  watchPosition() {
    let destinationLat = 0;
    let destinationLong = 0;

    let id = navigator.geolocation.watchPosition((position) => {
      console.log(`Lat : ${position.coords.latitude}, Long : ${position.coords.longitude}`);

      if ((position.coords.latitude === destinationLat) && (position.coords.longitude === destinationLong)) {
        navigator.geolocation.clearWatch(id);
      }

    }, err => {
      console.log(err);

    }, {
      enableHighAccuracy: true,
      timeout: 1000,
      maximumAge: 0
    });
  }

  finalDistance = "";

  findDistance(lat1: any, lat2: any, lon1: any, lon2: any) {
    this.finalDistance = "";

    var R = 6371; // km (change this constant to get miles)
    var dLat = ((lat2 - lat1) * Math.PI) / 180;
    var dLon = ((lon2 - lon1) * Math.PI) / 180;
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;


    if (d > 1)
      this.finalDistance = (Math.round(d) + ' KM');

    else if (d <= 1)
      this.finalDistance = (Math.round(d * 1000) + ' M');

    console.log(this.finalDistance);
  }

  myOfficeLatitude: any;
  myOfficeLongitude: any;

  getMyOfficeLatitudeLongitude() {
    if (this.cookie.get('branchId') != "0") {
      this.api.getAllBranch(0, 0, 'ID', 'desc', ' AND ORG_ID =' + this.cookie.get('orgId') + " AND ID=" + this.cookie.get('branchId')).subscribe(data => {
        if (data['code'] == 200) {
          this.myOfficeLatitude = data['data'][0]["LATITUTE"];
          this.myOfficeLongitude = data['data'][0]["LONGITUTE"];
        }

      }, err => {
        console.log(err);
      });
    }
  }

  handleDayInDayOutModalOk() { }
}

import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { ShiftMaster } from 'src/app/Models/shiftmaster';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-shiftmasters',
  templateUrl: './shiftmasters.component.html',
  styleUrls: ['./shiftmasters.component.css']
})
export class ShiftmastersComponent implements OnInit {

  formTitle ='Manage Shifts';
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] =[["NAME", 'Shift Name'], 
                        ["CODE", ' Short Code'], 
                        ["BEGIN_TIME" ,'Day Start Time' ],
                        ["END_TIME" ,'Day End Time' ],
                        ["BREAK_START_TIME" ,'Break Start Time' ],
                        ["BREAK_END_TIME" ,'Break End Time' ],
                        ["MARK_HALFDAY_AFTER" ,'Mark Halfday After' ],
                        ["MARK_HALFDAY_BEFORE" ,'Mark Halfday Before' ],
                        ["MARK_FULLDAY_AFTRE" ,'Mark Fullday After' ],
                        ["GRACE_MINUTES" ,'Grace Minuties' ],
                        ["TOTAL_WORKING_TIME" ,'Total Working Time' ],
                        ["BREAKE_TIME" ,'Break Time' ],
                        ["NO_OF_BREAKS" ,'Number Of Breaks' ]]

                        

  drawerData2: string[];
  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: ShiftMaster = new ShiftMaster();
  userId = sessionStorage.getItem('userId');
  userName = sessionStorage.getItem("userName")
  roleId = sessionStorage.getItem("roleId")
 key="7061737323313233"

 constructor(private api: ApiService, private message: NzNotificationService) { }
 shifts
  ngOnInit() {

    this.search()
  }


  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }
  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      var likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      console.log("likeQuery" + likeQuery);
    }

    this.api.getAllShifts(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];

    }, err => {
      console.log(err);
    });
  }
  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  add(): void {
    this.drawerTitle =  "Create Shifts"
    this.drawerData = new ShiftMaster();
    this.drawerVisible = true;
  }

  edit(data: ShiftMaster): void {
      this.drawerTitle ="Update Shifts ";
      this.drawerData = Object.assign({}, data);

      this.drawerData.BEGIN_TIME= new Date('01-01-1970 '+this.drawerData.BEGIN_TIME)
      this.drawerData.BEGIN_TIME= new Date(this.drawerData.BEGIN_TIME)

      this.drawerData.END_TIME= new Date('01-01-1970 '+this.drawerData.END_TIME)
      this.drawerData.END_TIME= new Date(this.drawerData.END_TIME)

      this.drawerData.MARK_HALFDAY_AFTER= new Date('01-01-1970 '+this.drawerData.MARK_HALFDAY_AFTER)
      this.drawerData.MARK_HALFDAY_AFTER= new Date(this.drawerData.MARK_HALFDAY_AFTER)

      this.drawerData.MARK_HALFDAY_BEFORE= new Date('01-01-1970 '+this.drawerData.MARK_HALFDAY_BEFORE)
      this.drawerData.MARK_HALFDAY_BEFORE= new Date(this.drawerData.MARK_HALFDAY_BEFORE)

      this.drawerData.MARK_FULLDAY_AFTRE= new Date('01-01-1970 '+this.drawerData.MARK_FULLDAY_AFTRE)
      this.drawerData.MARK_FULLDAY_AFTRE= new Date(this.drawerData.MARK_FULLDAY_AFTRE)

      this.drawerData.MARK_FULLDAY_BEFORE= new Date('01-01-1970 '+this.drawerData.MARK_FULLDAY_BEFORE)
      this.drawerData.MARK_FULLDAY_BEFORE= new Date(this.drawerData.MARK_FULLDAY_BEFORE)
      console.log(this.drawerData.MARK_FULLDAY_BEFORE,"this.drawerData.MARK_FULLDAY_BEFORE");

      this.drawerData.BREAK_START_TIME= new Date('01-01-1970 '+this.drawerData.BREAK_START_TIME)
      this.drawerData.BREAK_START_TIME= new Date(this.drawerData.BREAK_START_TIME)

      console.log(this.drawerData.BREAK_START_TIME,"this.drawerData.BREAK_START_TIME");

      this.drawerData.BREAK_END_TIME= new Date('01-01-1970 '+this.drawerData.BREAK_END_TIME)
      this.drawerData.BREAK_END_TIME= new Date(this.drawerData.BREAK_END_TIME)
      console.log(this.drawerData.BREAK_END_TIME,"this.drawerData.BREAK_END_TIME");
      
      
  
      this.drawerVisible = true;

      this.api.getAllShifts(0, 0, '', '', '').subscribe(data => {
        this.shifts = data['data'];
  
      }, err => {
        console.log(err);
      });
  
    
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

 

}

import { Component, OnInit, ViewChild } from '@angular/core';
// import { leavePolicy } from 'src/app/Models/leavePolicy';
import { ApiService } from 'src/app/Service/api.service';
import { leavePolicy } from '../../leavePolicy';
import { LeavePolicyMasterComponent } from '../leave-policy-master/leave-policy-master.component';

@Component({
  selector: 'app-leave-policy-table',
  templateUrl: './leave-policy-table.component.html',
  styleUrls: ['./leave-policy-table.component.css']
})
export class LeavePolicyTableComponent implements OnInit {
  masterVisible = false
  formTitle = "Policy Leave"
  mapdrawerTitle = "Map Policy"
  drawerTitle: any
  pageIndex = 1;
  pageSize = 10;
  sortKey: string = "id";
  sortValue: string = "desc";
  filter = ''
  leavePolicy = []
  totalRecords = []
  loadingRecords = false;
  searchText: string = "";
  filterQuery: string = '';
  isSpinning = false
  columns: string[][] = []
  data: leavePolicy = new leavePolicy()
  mapVisible = false
  NAMES: any
  leaves = []

  @ViewChild(LeavePolicyMasterComponent, { static: false }) LeavePolicy: LeavePolicyMasterComponent;
  
  constructor(private api: ApiService) { }

  ngOnInit() {
    this.search()
  }
  search(reset: boolean = false) {
    var filter = ""
    if (reset) {
      this.pageIndex = 1;
    }
    this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }
    console.log("search text : " + this.searchText);
    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND ";

      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }
    this.api.getleavePolicy(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery).subscribe(data => {
      console.log(data['data']);
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.leavePolicy = data['data'];
    }, err => {
      console.log(err);
    });
  }
  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }

  getLeave() {
    this.api.getEmployeeLeave(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, this.filter, this.LEAVE_POLICY_ID).subscribe(data => {
      this.leaves = data['data']
    })
  }

  openMasterForm() {
    this.data = new leavePolicy()
    this.api.getleavePolicy(1, 1, 'SEQUENCE_NO', 'desc', '').subscribe(data => {
      if (data['code'] == 200 && data['count'] > 0) {
        var seqno = data['data'][0]['SEQUENCE_NO'];
        this.data.SEQUENCE_NO = Number(seqno) + 1;

      } else {
        this.data.SEQUENCE_NO = 1;
      }

    }, err => {
      console.log(err);
    });
    this.drawerTitle = "Add Policy Leave"
    this.masterVisible = true
  }
  edit(data: any) {
    console.log(data);
    this.data = Object.assign({}, data)
    this.LeavePolicy.getallorg2(data.ID);
    this.drawerTitle = "Update Policy Leave"
    this.masterVisible = true
  }
  masterClose() {
    this.masterVisible = false
    this.search()
  }
  get closeCallback() {
    return this.masterClose.bind(this)
  }
  mapClose() {
    this.mapVisible = false
  }
  get closeCallBack1() {
    return this.mapClose.bind(this)

  }
  assign: any = []
  LEAVE_POLICY_ID: any
  map(data: leavePolicy, i: any) {
    this.LEAVE_POLICY_ID = this.leavePolicy[i]['ID']
    this.data = data
    this.data.PAYROLL_LEAVE_ID = []
    this.mapVisible = true
    this.api.getEmployeeLeave(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, this.filter, this.LEAVE_POLICY_ID).subscribe(datas => {
      this.leaves = datas['data']
      for (let i = 0; i < this.leaves.length; i++) {
        if (this.leaves[i]['IS_SELECTED'] == 1) {
          this.data.PAYROLL_LEAVE_ID.push(this.leaves[i]['ID'])
        }
      }
    })
  }

}

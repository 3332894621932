export class leaveMaster {
    ID:any;
    NAME:string='';
    CODE:string;
    GENDER :string;
    ALLOTMENT_FROM :any;
    DESCRIPTION :string='';
    STATUS :boolean;
    APPLICABLE_TYPES :any;
    LOOSE_OFF_PAY :boolean;
    APPLICABLE_TYPES_CAPTION:any
    ALLOTMENT_TYPE_ID :any;
    EFFECTIVE_FROM :any;
    ALLOTMENT_BALANCE :number;
    CARRY_FORWARD_LIMIT:number;
    PRO_RATED :boolean;
    PRORATED_MAX_JOINING_DATE :any;
    LEAVE_ENCASHMENT :boolean;
    AUTO_CREDIT:boolean;
    MIN_ENCASH:number;
    MAX_ENCASH:number;
    MAX_AVAIL_PERMONTH :any;
    MAX_AVAIL_PER_INSTANCE :any;
    MIN_AVAIL_PER_INSTANCE :any;
    MIN_GAP_BETWEEN_TWO_INSTANCE :any;
    CLUBBED_WITH_NOT_POSSIBLE :any;
    LEAVE_AVAIL_FROM :any;
    IS_EARNED :boolean;
    CLUBBED_SWITCH:boolean;
    APPLICABLE_SWITCH:boolean;
    CLIENT_ID:any;
    LEAVE_APPROVAL_DAYS:number=0

}
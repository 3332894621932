import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-employee-of-the-month',
  templateUrl: './employee-of-the-month.component.html',
  styleUrls: ['./employee-of-the-month.component.css']
})

export class EmployeeOfTheMonthComponent implements OnInit {
  formTitle = "Employee of The Month";
  totalRecords = 1;
  dataList = [];
  loadingRecords = true;
  filterQuery: string = "";
  columns: string[][] = [["EMPLOYEE_CODE", "Code"], ["NAME", "Employee Name"], ["BRANCH_NAME", "Branch Name"], ["DEPARTMENT_NAME", "Department Name"], ["DESIGNATION_NAME", "Designation Name"],];
  pageIndex = 1;
  pageSize = 10;

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private cookie: CookieService) { }

  ngOnInit() {
    this.search();
    this.getAllEmployee();
  }

  search() {
    this.loadingRecords = true;

    this.api.getEmployeeOfTheMonth(this.pageIndex, this.pageSize, "", "asc", "").subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['EMPLOYEE_OF_THE_MONTH'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  isEOMModalVisible = false;
  isEOMModalConfirmLoading = false;
  EOMModalTitle: string = "";

  showEOMModal(): void {
    this.isEOMModalVisible = true;
    this.EOMModalTitle = "Employee of the Month";
  }

  handleEOMModalCancel(): void {
    this.isEOMModalVisible = false;
    this.isEOMModalConfirmLoading = false;
    this.USER_ID = undefined;
  }

  handleEOMModalOk(): void {
    var isOk = true;

    if ((this.USER_ID == undefined) || (this.USER_ID == null)) {
      isOk = false;
      this.message.error("Please Select Employee", "");
    }

    if (isOk) {
      var obj1 = new Object();
      obj1["EMPLOYEE_ID"] = this.USER_ID;

      this.isEOMModalConfirmLoading = true;

      this.api.setEmployeeOfTheMonth(obj1).subscribe(successCode => {
        if (successCode['code'] == 200) {
          this.message.success("Employee of The Month Set Successfully", "");
          this.handleEOMModalCancel();
          this.search();

        } else {
          this.message.error("Failed To Set Employee of The Month", "");
          this.isEOMModalConfirmLoading = false;
        }

      }, err => {
        this.isEOMModalConfirmLoading = false;

        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });
    }
  }

  employeeList = [];
  orgId = this.cookie.get('orgId');
  USER_ID: number;

  getAllEmployee() {
    this.employeeList = [];

    this.api.getAllemployeeMaster(0, 0, 'NAME', 'asc', ' AND ORG_ID=' + this.orgId).subscribe(data => {
      if (data['code'] == 200) {
        this.employeeList = data['data'];
      }

    }, err => {
      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    })
  }

  getInitial(empName) {
    let initial: string = empName.charAt(0);
    return initial.trim();
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { EmployeeMaster } from 'src/app/Models/employeemaster';
import { EmployeeRoleMap } from 'src/app/Models/employeerolemap';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-employeerolemaplist',
  templateUrl: './employeerolemaplist.component.html',
  styleUrls: ['./employeerolemaplist.component.css']
})

export class EmployeerolemaplistComponent implements OnInit {
  formTitle = "Employee Role Map";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  @Input() dataList = [];
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "ID";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["ROLE_NAME", "Role Name"]]

  // @ViewChild(DepartmentcalenderComponent, { static: false }) departmentCalender: DepartmentcalenderComponent;

  //drawer Variables
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: EmployeeRoleMap = new EmployeeRoleMap();
  applicationId = Number(this.cookie.get('applicationId'))
  drawerVisible1: boolean;
  drawerTitle1: string;
  drawerData1: EmployeeRoleMap = new EmployeeRoleMap();
  listOfData: EmployeeRoleMap[] = [];
  OPEN_TIME2 = null
  CLOSE_TIME2 = null
  DAYS = false;
  @Input() data: EmployeeMaster
  @Input() drawerClose2: Function;
  role = []

  constructor(private api: ApiService, private cookie: CookieService, private message: NzNotificationService) { }

  ngOnInit() {
    this.search();
    this.loadingRecords = false;
    // this.api.getAllRoles
  }

  // Basic Methods
  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
      this.sortKey = "id";
      this.sortValue = "desc"
    }

    // this.loadingRecords = true;
    var sort: string;
    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";
    } catch (error) {
      sort = "";
    }

    var likeQuery = "";
    console.log("search text:" + this.searchText);
    if (this.searchText != "") {
      likeQuery = " AND";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });
      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
      // console.log("likeQuery" + likeQuery);
    }

    this.api.getAllEmpRoleMap(this.pageIndex, this.pageSize, this.sortKey, sort, likeQuery + ' AND EMPLOYEE_ID=' + this.data.ID).subscribe(data => {
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
      // console.log(this.dataList)
      //   if(this.totalRecords==0){
      //     data.SEQUENCE_NO=1;
      //   }else{
      //     data.SEQUENCE_NO= this.dataList[this.dataList.length-1]['SEQUENCE_NO']+1
      //   }
      // }, err => {
      //   console.log(err);
    });
  }

  //Drawer Methods
  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  get closeCallback1() {
    return this.drawerClose1.bind(this);
  }

  add(): void {
    this.drawerTitle = "Map New Role";
    this.drawerData = new EmployeeRoleMap();

    this.api.getAllRoles2(this.cookie.get('roleId'), this.data.ID,"AND STATUS=1").subscribe(data => {
      this.role = data['data'];
    }, err => {
    });
    this.drawerVisible = true;
  }

  // add(): void {
  //   this.drawerTitle = "Create New Cluster";
  //   this.drawerData = new ClusterMaster();
  //   this.drawerVisible = true;
  // }

  edit(data: EmployeeRoleMap): void {
    this.drawerTitle = "Update Role";
    this.drawerData = Object.assign({}, data);
    // this.OPEN_TIME2 = null
    // this.CLOSE_TIME2 = null
    this.DAYS = false;
    this.drawerVisible = true;
  }

  // viewCalender(data: Department): void {
  //   console.log(data)
  //   this.drawerTitle1 = "Calender For " + data.NAME + "";
  //   this.drawerData1 = Object.assign({}, data);
  //   this.drawerVisible1 = true;
  //   console.log(data.ID)
  //   this.departmentCalender.applyFilter(data.ID)
  // }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  drawerClose1(): void {
    this.drawerVisible1 = false;
  }

  cancel(): void {
  }

  confirm(data): void {
    this.api.deleteEmpRoleMap({ ROLE_ID: data.ROLE_ID, EMPLOYEE_ID: data.EMPLOYEE_ID }).subscribe((successCode) => {
      // this.api.deleteEmpRoleMap1({ ROLE_ID: data.ROLE_ID, EMPLOYEE_ID: data.EMPLOYEE_ID }).subscribe((successCode) => {
      if (successCode.code == '200') {
        this.message.success('Role Deleted Successfully', '');
        this.search();

      } else {
        this.message.error('Failed To Delete', '');
      }
    });
  }

  setStatus(event, i) {
    this.dataList[i]['STATUS'] = event;
    this.api.updateEmpRoleMap(this.dataList[i]).subscribe((successCode) => {
      if (successCode.code == '200') {
        this.message.success('Updated Successfully', '');
      } else {
        this.message.error('Failed To Update', '');
      }
      this.search();
    });
  }
}

function input() {
  throw new Error('Function not implemented.');
}
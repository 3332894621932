import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/Service/api.service';
import { DatePipe } from '@angular/common'; 

@Component({
  selector: 'app-job-application-report',
  templateUrl: './job-application-report.component.html',
  styleUrls: ['./job-application-report.component.css']
})
export class JobApplicationReportComponent implements OnInit {
    formTitle = "Job Application Report";
    pageIndex = 1;
    pageSize = 10;
    totalRecords = 1;
    dataList = [];
    // userId = this.cookie.get('userId')
    // roleId = this.cookie.get('roleId')
    loadingRecords = true;
    sortValue: string = "desc";
    sortKey: string = "id";
    searchText: string = "";
    filterQuery: string = "";
    // fileName= 'UserWise.xlsx';
    isFilterApplied: string = "default";
    columns: string[][] = [["EMAIL_ID", "E-mail ID"],["MOBILE_NO", "Mobile No"],
    ["STATE_NAME", "State"], ["COLLEGE_NAME", "College"], ["BRANCH_NAME", "Branch"],["YEAR_NAME", "Year"],["NAME", "Name"]]
    isSpinning = false
    filterClass: string = "filter-invisible";
    selectedDate: Date[] = []
    dateFormat = 'dd/MM/yyyy';
    ticketQuestion = {};
    
    startValue: any;
    endValue: any;
    constructor(private api: ApiService,private datePipe: DatePipe) { }
  
    ngOnInit() {
      this.search();

    }
    search(reset: boolean = false) {
      if (reset) {
        this.pageIndex = 1;
      }
      this.loadingRecords = true;
      var sort: string;
      try {
        sort = this.sortValue.startsWith("a") ? "asc" : "desc";
        
      } catch (error) {
        sort = "";
      }
  
      var likeQuery = "";
      console.log("search text : " + this.searchText);
  
      if (this.searchText != "") {
        likeQuery = " AND ";
  
        this.columns.forEach(column => {
          likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
        });
  
        likeQuery = likeQuery.substring(0, likeQuery.length - 2) ;
      }
  
    this.loadingRecords=true
      this.api.getApplicationReport(this.pageIndex, this.pageSize, this.sortKey, sort, this.filterQuery + likeQuery).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
        }
  
      }, err => {
        if (err['ok'] == false)
        console.log("Server Not Found", "");
      });
    }

    getAttachment(url: string)  {
      window.open(this.api.retriveimgUrl + "Resumes/" + url); }
  
    
    
  
    sort(sort: any): void {
      this.sortKey = sort.key;
      this.sortValue = sort.value;
      if (this.sortValue == "descend") {
        this.sortValue = 'desc';
      } else {
        this.sortValue = 'asc'
      }
      console.log(this.sortValue + "kk");
      this.search(true);
    }
    
    onKeypressEvent(reset: any) {
      const element = window.document.getElementById('button');
      if (element != null) element.focus();
      this.search();
    }
    keyup(event: any) {
      this.search();
    }
  
    showFilter() {
      if (this.filterClass === 'filter-visible')
      this.filterClass = 'filter-invisible';
    else this.filterClass = 'filter-visible';

    }
  
    applyFilter() {
      // this.isSpinning = true
      this.loadingRecords = true;
     
      this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd');
      this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd');
  
  
      var sort: string;
      try {
        sort = this.sortValue.startsWith('a') ? 'asc' : 'desc';
      } catch (error) {
        sort = '';
      }
  
      this.filterQuery = '';
      this.startValue = this.datePipe.transform(this.startValue, 'yyyy-MM-dd');
      this.endValue = this.datePipe.transform(this.endValue, 'yyyy-MM-dd');
  
      if (this.startValue != null && this.endValue != null) {
        this.filterQuery = " AND CREATED_MODIFIED_DATE BETWEEN '" + this.startValue + "' AND '" + this.endValue + "' "
  
        var filter = '';
        filter = this.filterQuery;
        var likeQuery = "";
      }
  
  
      
      
      this.api.getApplicationReport(0, 0, this.sortKey, sort, this.filterQuery).subscribe(data => {
        console.log(data)
        this.loadingRecords = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
        this.isSpinning = false
        this.isFilterApplied = "primary";
        this.filterClass = "filter-invisible";
      }, err => {
        console.log(err);
      });
    }
    clearFilter() {
     
      this.filterQuery = ""
      this.selectedDate = null;
      this.startValue = ''
      this.endValue = ''
      this.filterClass = "filter-invisible";
      this.applyFilter()
    }
  
  
  }
  
  


// exportexcel(): void
    // {
    //   // pass here the table id /
    //   let element = document.getElementById('summer');
    //   const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
   
    //  // generate workbook and add the worksheet /
    //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
    //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
   
    //   // save to file /  
    //   XLSX.writeFile(wb, this.fileName);
   
    // }  



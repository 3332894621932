import { Component, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { leaveadjustment } from 'src/app/Models/leaveadjustment';
// import { leaveadjustment } from 'src/app/Models/leaveadjustment';
import { ApiService } from 'src/app/Service/api.service';
// import { ApiService } from '../Service/api.service';

@Component({
  selector: 'app-leave-adjustment-table',
  templateUrl: './leave-adjustment-table.component.html',
  styleUrls: ['./leave-adjustment-table.component.css']
})
export class LeaveAdjustmentTableComponent implements OnInit {
  formTitle = "Leave Adjustment"
  isVisible = false
  drawerTitle: any
  pageIndex = 1;
  pageSize = 10;
  sortKey: string = "id";
  sortValue: string = "desc";
  drawerdata : leaveadjustment = new leaveadjustment()
  tablehide: boolean
  startbutton: boolean
  filterbutton: boolean
  columns4 = []

  columns2: string[][] = [["EMPLOYEE_NAME", "Employees"]]
  columns: string[][] = [['DATE', 'Date'], ['REMARK', 'Adjustment Remark'], ['USER_NAME', 'User Name'], ['CREATED_MODIFIED_DATE', 'Date1'],]

  searchText: string = "";
  loadingRecords = false
  totalRecords: any
  dataList1 = []
  dataList = []
  filterQuery: string = '';
  userName = this.cookie.get('userName')
  REMARK = ""
  constructor(private api: ApiService, public cookie: CookieService, private message: NzNotificationService) { }

  ngOnInit() {
    this.search(true)
  }
  search(reset: boolean = false) {
    var filter = ""
    this.filterQuery = ''
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    console.log("search text : " + this.searchText);
    var likeQuery = "";
    if (this.searchText != "") {
      likeQuery = " AND ";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2)
    }

    this.api.getAdjustmentData(this.pageIndex, this.pageSize, this.sortKey, this.sortValue, this.filterQuery + likeQuery).subscribe(data => {
      console.log(data['data']);
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList1 = data['data'];

    }, err => {
      console.log(err);
    });


  }
  add() {
    this.drawerTitle = "Add Leave Adjustment"
    this.isVisible = true
    this.tablehide = true
    this.startbutton = false
    this.filterbutton = true

  }



  editAdjustment(data) {
    this.drawerTitle = "Edit Leave Adjustment"
    this.isVisible = true
    this.tablehide = false
    this.startbutton = true
    this.filterQuery = ''
    this.filterbutton = false
    this.drawerdata = Object.assign({}, data);

    var adjustFilter: any = "";

    adjustFilter = data.ID;
    this.filterQuery += " AND LEAVE_BALANCE_ADJUSTMENT_MASTER_ID = " + adjustFilter;

    this.api.getLeaveadjustmentDetails(0, 0, this.sortKey, this.sortValue, this.filterQuery).subscribe(data => {
      console.log(data['data']);
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
      console.log(this.dataList);


      this.api.getLeaveData(0, 0, '', '', 'AND STATUS=1').subscribe(data2 => {
        if (data2['code'] == 200) {
          this.allemps = data2['data']
          this.columns4 = data2['data']


          // for (let h = 0; h < this.dataList.length; h++) {
          // this.dataList[0].split(",")
          var result: any = Object.keys(this.dataList[0]).map((key) => [(key), this.dataList[0][key]]);

          for (let j = 0; j < result.length; j++) {
            //  if( this.dataList[i].EMPLOYEE_ID == this.allemps[j]['ID'])
            //  this.dataList['NAME'] =(this.allemps[j]['NAME'])
            var s = result[j][0].split('_')

            for (let i = 0; i < this.allemps.length; i++) {
              console.log(s[0]);
              if (this.allemps[i]['ID'] == s[0].replace('LV', '')) {
                var a = result[j][0].split('_')
                if ('C' == a[1])
                  this.columns4[i]['KEY1'] = result[j][0];
                if ('S' == a[1])
                  this.columns4[i]['KEY3'] = result[j][0];
                if ('N' == a[1])
                  this.columns4[i]['KEY2'] = result[j][0];
                console.log(this.columns4[i])
              }

            }
            if (j + 1 == result.length) {

            }
          }
        }

      }, err => {
        if (err['ok'] == false)
          this.message.error("Server Not Found", "");
      });

    }, err => {
      console.log(err);
    });
  }

  drawerClose() {
    this.isVisible = false
    this.search(true)
    this.tablehide = undefined
    this.startbutton = undefined
    this.filterbutton = undefined
    
  }

  get closeCallBack() {
    return this.drawerClose.bind(this)
  }

  edit(data) {
    console.log(data);

    this.drawerTitle = "Update Leave Adjustment"
    this.isVisible = true

    // this.api.getLeaveAdjustmentMappingData
  }

  sort(sort: any): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    if (this.sortValue == "descend") {
      this.sortValue = 'desc';
    } else {
      this.sortValue = 'asc'
    }
    console.log(this.sortValue + "kk");
    this.search(true);
  }
  isApkVersionModalVisible = false;
  isApkVersionModalConfirmLoading = false;
  apkVersionModalTitle: string = "";

  handleApkVersionModalCancel(): void {
    this.isApkVersionModalVisible = false;
    this.isApkVersionModalConfirmLoading = false;


  }
  allemps = []
  
  showApkVersionModal(data: any): void {
    this.filterQuery = ''
    this.isApkVersionModalVisible = true;
    this.apkVersionModalTitle = "Leave Adjustment Details";
    
    this.filterQuery += " AND LEAVE_BALANCE_ADJUSTMENT_MASTER_ID = " + data.ID;
    this.api.getLeaveadjustmentDetails(0, 0, this.sortKey, this.sortValue, this.filterQuery).subscribe(data => {
      console.log(data['data']);
      this.loadingRecords = false;
      this.totalRecords = data['count'];
      this.dataList = data['data'];
      console.log(this.dataList);
      this.api.getLeaveData(0, 0, '', '', 'AND STATUS=1').subscribe(data2 => {
        if (data2['code'] == 200) {
          this.allemps = data2['data']
          this.columns = data2['data']
          // for (let h = 0; h < this.dataList.length; h++) {
          // this.dataList[0].split(",")
          var result: any = Object.keys(this.dataList[0]).map((key) => [(key), this.dataList[0][key]]);
          for (let j = 0; j < result.length; j++) {
            //  if( this.dataList[i].EMPLOYEE_ID == this.allemps[j]['ID'])
            //  this.dataList['NAME'] =(this.allemps[j]['NAME'])
            var s = result[j][0].split('_')

            for (let i = 0; i < this.allemps.length; i++) {
              console.log(s[0]);
              if (this.allemps[i]['ID'] == s[0].replace('LV', '')) {
                var a = result[j][0].split('_')
                if ('C' == a[1])
                  this.columns[i]['KEY1'] = result[j][0];
                if ('S' == a[1])
                  this.columns[i]['KEY3'] = result[j][0];
                if ('N' == a[1])
                  this.columns[i]['KEY2'] = result[j][0];
                console.log(this.columns[i])
              }

            }
            if (j + 1 == result.length) {

            }
          }
        }
      }, err => {

      });
    }, err => {
      console.log(err);
    });
  }


}

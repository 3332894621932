import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
// import { leavePolicy } from 'src/app/Models/leavePolicy';
import { ApiService } from 'src/app/Service/api.service';
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { leavePolicy } from '../../leavePolicy';

@Component({
  selector: 'app-leave-policy-master',
  templateUrl: './leave-policy-master.component.html',
  styleUrls: ['./leave-policy-master.component.css']
})
export class LeavePolicyMasterComponent implements OnInit {
  @Input() masterClose: Function
  @Input() data: leavePolicy = new leavePolicy()
  constructor(private api: ApiService, public message: NzNotificationService) { }
  listdata2 = [];
  isSpinning=false
  listdata1=[]
  ngOnInit() {

    this.getallorg1()
  }

  
  getallorg1() {
    this.listdata1 = [];

    this.api.getleavePolicy(0, 0, 'ID', 'desc', '').subscribe(data => {
      if (data['code'] == 200) {
        this.listdata1 = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

    getallorg2(userID) {
    this.listdata2 = [];

    var tempEmpList = this.listdata1.filter(obj1 => {
      return obj1["ID"] != userID;
    });

    this.listdata2 = tempEmpList;
  }
  submitPolicyLeave(addNew: boolean, httpForm: NgForm) {
   
    if (this.data.NAME == null || this.data.NAME == undefined) {
      this.message.warning('Please enter name','')
    }
    else if (this.data.SEQUENCE_NO == null || this.data.SEQUENCE_NO == undefined) {
      this.message.warning('Please enter sequence number','')
    }
  
    else {
      if (this.data.ID) {
      var name = this.listdata2.filter(obj => {
        return (obj.NAME == this.data.NAME);
      });
      if (name.length == 0) {
        this.isSpinning=true
        this.api.updateleavePolicy(this.data).subscribe(data => {
          if (data['code'] == 200) {
            this.message.success("Information Updated Successfully", "");
              this.isSpinning=false
            if (!addNew)
              this.masterClose();

          

          } else {
            this.message.error("Information Updation Failed", "");
            this.isSpinning=false
          }
        });

      } else {
        this.message.error("Employee Code Already Exist. Please Enter Other Employee Code", "");
        this.isSpinning=false
      }
    }

      else {
        var name1 = this.listdata1.filter(obj => {
          return ((obj.NAME == this.data.NAME) );
          
        });
      ;

        if (name1.length == 0) {
          this.isSpinning=true
          this.api.createleavePolicy(this.data).subscribe(successCode => {
            if (successCode['code'] == 200) {
              this.isSpinning = false;
              this.message.success("Information Saved Successfully", "");
              this.masterClose()
            this.getSequenceNo()

              if (!addNew) {
                this.masterClose();
            

              } else {
                this.data = new leavePolicy();
                
                this.getallorg1();
              }

            } else {
              this.message.error("Information Saved Failed", "");
              this.isSpinning = false;
            }
          });

        } else {
          this.message.error("This Policy  Already Exist. Please Enter Other Policy", "");
          this.isSpinning = false;
        }
      }
      }
    

  }
  cancel(httpForm: NgForm) {
    this.reset(httpForm)
    this.masterClose()
  }
  reset(httpForm: NgForm) {
    httpForm.reset()
  }


    
      // else {
      //   this.api.createleavePolicy(this.data).subscribe(data => {
      //     if (data['code'] == '200') {
      //       this.message.success('Leave policy submit successfull','')
      //       this.masterClose()
      //       this.getSequenceNo()
      //     }
      //   })
      // }

  
  getSequenceNo() {
    this.api.getleavePolicy(1, 1, 'SEQUENCE_NO', 'desc', '').subscribe(data => {
      if (data['code'] == 200 && data['count'] > 0) {
        var seqno = data['data'][0]['SEQUENCE_NO'];
        this.data.SEQUENCE_NO = Number(seqno) + 1;

      } else {
        this.data.SEQUENCE_NO = 1;
      }

    }, err => {
      console.log(err);
    });
  }

  omit(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService, NzSelectComponent } from 'ng-zorro-antd';
import { Expenses } from 'src/app/Models/Expenses';
import { ApiService } from 'src/app/Service/api.service';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { DatePipe } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { CustomerMaster } from 'src/app/Models/customer-master';

@Component({
  selector: 'app-add-employee-expenses',
  templateUrl: './add-employee-expenses.component.html',
  styleUrls: ['./add-employee-expenses.component.css']
})

export class AddEmployeeExpensesComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: Expenses;
  @Input() drawerVisible: boolean;
  isSpinning = false;

  constructor(private api: ApiService, private message: NzNotificationService, private datePipe: DatePipe, private cookie: CookieService) { }

  expenseTypes = [];
  customers = [];
  employees = [];

  userId = Number(this.cookie.get('userId'));
  userName = String(this.cookie.get('userName'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  designationId = Number(this.cookie.get('designationId'));

  ngOnInit() {
    this.getExpenseHead();
    this.getCustomers();
    this.getEmployees();
    this.PEOPLES_ID = [];
    this.createTable(null);
  }

  getExpenseHead() {
    this.expenseTypes = [];

    this.api.getAllExpenseHead(0, 0, 'NAME', 'asc', ' and IS_ACTIVE=1').subscribe(data => {
      if (data['code'] == 200) {
        this.expenseTypes = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  getCustomers() {
    this.customers = [];
    var filter = ' and ID in (select COLLEGE_ID from employee_college_mapping where employee_id=' + this.userId + ' and IS_ALLOWED=1)';

    this.api.getAllCustomers(0, 0, 'NAME', 'asc', filter).subscribe(data => {
      if (data['code'] == 200) {
        this.customers = data['data'];
        console.log(this.customers);
      }

    }, err => {
      console.log(err);
    });
  }

  getEmployees() {
    this.employees = [];

    this.api.getAllEmployeesSplitBill(this.orgId, this.userId, this.designationId).subscribe(data => {
      if (data['code'] == 200) {
        this.employees = data['data'];
        console.log(this.employees);
      }

    }, err => {
      console.log(err);
    });
  }

  empList: any = [];

  onEmployeeSelected(empID: any) {
    this.createTable(empID);
  }

  createTable(empID) {
    this.empList = [];
    var arry = [];
    var currentEmp = new Object();

    currentEmp['ID'] = this.userId;
    currentEmp['NAME'] = this.userName;
    currentEmp['DEPARTMENT_ID'] = this.deptId;
    currentEmp["AMT"] = 0;

    if (empID != null) {
      for (var i = 0; i < empID.length; i++) {
        var empData = this.employees.filter(data => {
          if (data.ID == empID[i]) {
            data["AMT"] = 0;
            arry.push(data);
          }
        });
      }

      this.empList = arry;
      console.log(this.empList);
    }

    this.empList.push(Object.assign({}, currentEmp));

    for (var j = 0; j < this.empList.length; j++) {
      if (this.data.AMOUNT != undefined && this.data.AMOUNT != null) {
        this.empList[j]["AMT"] = parseFloat(String(Number(this.data.AMOUNT) / (this.empList.length))).toFixed(2);
      }
    }
  }

  onAmtChange(amt) {
    this.data.AMOUNT = amt;
    this.PEOPLES_ID = [];
    this.createTable(null);
  }

  close(myForm: NgForm): void {
    this.drawerClose();
    this.reset(myForm);
  }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  PEOPLES_ID = [];

  save(addNew: boolean, myForm: NgForm): void {
    var isOk = true;

    if (this.data.DATE == undefined) {
      isOk = false;
      this.message.error("Please Select Valid Date", "");
    }

    if (this.data.HEAD_ID == undefined || this.data.HEAD_ID == 0) {
      isOk = false;
      this.message.error("Please Select Valid Expense Type", "");
    }

    if (this.data.CUST_ID == undefined) {
      isOk = false;
      this.message.error("Please Select Valid Customer", "");
    }

    if (this.data.CUST_ID == 0) {
      if (this.data.CUST_NAME != undefined) {
        if (this.data.CUST_NAME.trim() != '') {
          if (!this.api.checkTextBoxIsValid(this.data.CUST_NAME)) {
            isOk = false;
            this.message.error("Please Enter Valid Location", "");
          }
        } else {
          isOk = false;
          this.message.error("Please Enter Valid Location", "");
        }
      } else {
        isOk = false;
        this.message.error("Please Enter Valid Location", "");
      }
    } else {
      this.data.CUST_NAME = " ";
    }

    if (this.data.PARTY_NAME != undefined) {
      if (this.data.PARTY_NAME.trim() != '') {
        if (!this.api.checkTextBoxIsValid(this.data.PARTY_NAME)) {
          isOk = false;
          this.message.error("Please Enter Valid Party Name", "");
        }
      } else {
        isOk = false;
        this.message.error("Please Enter Valid Party Name", "");
      }
    } else {
      isOk = false;
      this.message.error("Please Enter Valid Party Name", "");
    }

    if (this.data.DESCRIPTION == undefined || this.data.DESCRIPTION.trim() == '') {
      isOk = false;
      this.message.error("Please Enter Valid Description", "");
    }

    // if (this.fileURL != undefined) {
    //   if (this.fileURL == null) {
    //     isOk = false;
    //     this.message.error("Please Select Valid Attachment", "");
    //   }
    // } else {
    //   isOk = false;
    //   this.message.error("Please Select Valid Attachment", "");
    // }

    if (this.data.AMOUNT == undefined || this.data.AMOUNT == 0) {
      isOk = false;
      this.message.error("Please Enter Valid Bill Amount", "");
    }

    if (this.data.AMOUNT != undefined || this.data.AMOUNT != 0) {
      let total = 0;
      this.empList.map(item => {
        total = total + parseFloat(item.AMT);
      });

      total = Math.round(total);
      this.data.AMOUNT = Math.round(this.data.AMOUNT);

      if (this.data.AMOUNT != total) {
        isOk = false;
        this.message.error("Split Bill Total is Not as much as Your Claimed Amount", "");
      }
    } else {
      isOk = false;
      this.message.error("Please Enter Valid Bill Amount", "");
    }

    if (isOk) {
      if (this.data.CUST_ID == 0)
        this.addNewLocation(this.data.CUST_NAME);
    }

    if (isOk) {
      this.isSpinning = true;

      setTimeout(() => {
        console.log(this.data.CUST_ID);

        if (this.data.CUST_ID != 0) {
          var arry = [];
          var peopleIDStr: string = "";
          var peopleNameStr: string = "";

          for (var i = 0; i < this.empList.length; i++) {
            var detailsObj = new Object();

            detailsObj['DATE'] = this.datePipe.transform(this.data.DATE, "yyyy-MM-dd");
            detailsObj['TYPE'] = this.data.HEAD_ID;
            detailsObj['AMOUNT'] = this.empList[i]["AMT"];
            detailsObj['APPROVED_AMOUNT'] = 0;
            detailsObj['CLIENT_ID'] = this.api.clientId;
            detailsObj['EMPLOYEE_ID'] = this.empList[i]["ID"];
            detailsObj['DEPARTMENT_ID'] = this.empList[i]["DEPARTMENT_ID"];

            arry.push(Object.assign({}, detailsObj));

            if (peopleIDStr == "")
              peopleIDStr = this.empList[i]["ID"];
            else
              peopleIDStr = peopleIDStr + "," + this.empList[i]["ID"];

            if (peopleNameStr == "")
              peopleNameStr = this.empList[i]["NAME"];
            else
              peopleNameStr = peopleNameStr + "," + this.empList[i]["NAME"];
          }

          this.data.DATE = this.datePipe.transform(this.data.DATE, "yyyy-MM-dd");
          this.data.EMPLOYEE_ID = this.api.userId;
          this.data.PEOPLES_ID = peopleIDStr;
          this.data.PEOPLES_NAMES = peopleNameStr;
          this.data.DETAILS = arry;

          this.imageUpload();
          this.data.ATTACHMENT = this.uploadedAttachmentStr;
          console.log(this.data);

          if (this.data.ID) {
            this.api.updateEmployeeExpenses(this.data).subscribe(successCode => {
              if (successCode['code'] == "200") {
                this.message.success("Expenses Updated Successfully", "");
                this.isSpinning = false;

                if (!addNew)
                  this.close(myForm);

              } else {
                this.message.error("Expenses Updation Failed", "");
                this.isSpinning = false;
              }
            });

          } else {
            this.api.createEmployeeExpenses(this.data).subscribe(successCode => {
              if (successCode['code'] == "200") {
                this.message.success("Expenses Created Successfully", "");
                this.isSpinning = false;

                if (!addNew)
                  this.close(myForm);

                else {
                  this.data = new Expenses();
                }

              } else {
                this.message.error("Expenses Creation Failed", "");
                this.isSpinning = false;
              }
            });
          }

        } else {
          this.isSpinning = false;
        }

      }, 2000);
    }
  }

  fileURL: File = null;

  onFileSelected(event) {
    console.log(Number(parseFloat(String((event.target.files[0].size / 1024) / 1024)).toFixed(0)));

    if (event.target.files[0].type == "image/jpeg" || event.target.files[0].type == "image/jpg" || event.target.files[0].type == "image/png") {
      if (Number(parseFloat(String((event.target.files[0].size / 1024) / 1024)).toFixed(0)) <= 2) {
        this.fileURL = <File>event.target.files[0];

      } else {
        this.fileURL = null;
        this.message.error("File size Must be Less than or Equal to '2 MB'", "");
      }

    } else {
      this.fileURL = null;
      this.message.error("File Type Must be JPEG/ JPG/ PNG", "");
    }
  }

  clear() {
    this.fileURL = null;
  }

  today = new Date().setDate(new Date().getDate());

  disabledStartDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.today) > 0;

  folderName = "expenses"
  uploadedAttachmentStr: string;

  imageUpload() {
    this.uploadedAttachmentStr = "";

    if (this.fileURL) {
      var number = Math.floor(100000 + Math.random() * 900000);
      var fileExt = this.fileURL.name.split('.').pop();
      var url = "E" + number + "." + fileExt;

      this.api.onUpload2(this.folderName, this.fileURL, url).subscribe(res => {
        if (res["code"] == 200) {
          console.log("Uploaded");

        } else {
          console.log("Not Uploaded");
        }
      });

      this.uploadedAttachmentStr = url;

    } else {
      this.uploadedAttachmentStr = "";
    }
  }

  omit(event: any) {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }

  getInitial(empName) {
    let initial: string = empName.charAt(0);
    return initial.trim();
  }

  locationData = new CustomerMaster();

  addNewLocation(locationName): void {
    this.locationData.NAME = locationName;
    this.locationData.IS_ACTIVE = true;

    this.api.createCustomer(this.locationData).subscribe(successCode => {
      if (successCode['code'] == 200) {
        this.message.success("New Location Created Successfully", "");

        this.api.getAllCustomers(1, 1, "ID", "desc", "").subscribe(data => {
          if (successCode['code'] == 200) {
            this.data.CUST_ID = data["data"][0]["ID"];
          }

        }, err => {
          console.log(err);
        });

      } else {
        this.message.error("Location Creation Failed", "");
      }

    }, err => {
      console.log(err);
    });
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringpipe'
})
export class StringpipePipe implements PipeTransform {

  transform(value:string): string {
    var slicestring = value.slice(0, 5)
    return slicestring;
  }

}

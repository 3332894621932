import { Component, OnInit, Input } from '@angular/core';
// import { Branchmaster } from '../../../Models/Branchmaster'
import { EmmService } from '../../../Service/emm.service'
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { DatePipe } from '@angular/common';
import { ApiService } from 'src/app/Service/api.service';
import { NgForm } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { Branchmaster } from 'src/app/Models/branchmaster';

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.css'],
  providers: [DatePipe]
})

export class BranchComponent implements OnInit {
  @Input() drawerClose: Function;
  @Input() data: Branchmaster;
  @Input() drawerVisible: boolean;

  isSpinning = false
  fileData: File = null
  isOk = true;
  emailpattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  // namepatt = /^[a-zA-Z \-\']+/
  mobpattern = /^[6-9]\d{9}$/
  onlynum = /^[0-9]*$/
  onlychar = /^[a-zA-Z ]*$/
  // pincode = /^[1-9][0-9]{5}$/
  namepatt = /[a-zA-Z][a-zA-Z ]+/;
  addpat = /[ .a-zA-Z0-9 ]+/
  pincode = /([1-9]{1}[0-9]{5}|[1-9]{1}[0-9]{3}\\s[0-9]{3})/;
  imgUrl
  time
  time1
  time2
  department: Branchmaster[] = [];
  org = []
  orgId = this.cookie.get('orgId')
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "id";
  date
  //dateFormat="dd/MMM/yyyy"

  constructor(private api: ApiService, private cookie: CookieService, private datePipe: DatePipe, private message: NzNotificationService) {
  }

  //imgUrl="http://192.168.0.10:8000/static/organization/";

  ngOnInit() {
    // this.dept();
    this.api.getAllclusterMaster(0, 0,'', '', '').subscribe(data => { 
    // this.api.getAllclusterMaster("", '','', '', ' AND  ORG_ID =' + this.orgId).subscribe(data => {
      this.loadingRecords = false;

      this.org = data['data'];
    }, err => {
      console.log(err);
    });
  }

  close(accountMasterPage: NgForm) {
    this.drawerClose();
    this.resetDrawer(accountMasterPage);
  }

  resetDrawer(accountMasterPage: NgForm) {
    accountMasterPage.form.reset();
    this.add();
  }

  add(): void {
    this.api.getAllBranch(1, 1, 'SEQUENCE_NO', 'desc', ' AND  ORG_ID =' + this.orgId).subscribe(data => {
      if (data['count'] == 0) {
        this.data.SEQUENCE_NO = 1;
      } else {
        this.data.SEQUENCE_NO = Number(data['data'][0]['SEQUENCE_NO']) + 1;
        this.data.STATUS = true;
      }
    }, err => {
      console.log(err);
    })
  }

  dept() {
    this.api.getAllBranch(0, 0, '', '', ' AND  ORG_ID =' + this.cookie.get('orgId')).subscribe(data => {
      this.department = data['data'];
    }, err => {
      console.log(err);
      this.isSpinning = false;
    });
  }

  alphaOnly(event) {
    event = (event) ? event : window.event;
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 32 && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
      return false;
    }
    return true;
  }

  omit(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  save(addNew: boolean, accountMasterPage: NgForm): void {
    this.isSpinning = false;
    this.isOk = true;
    this.data.ORG_ID = Number(this.orgId);

    if (this.data.CLUSTER_ID == undefined || this.data.CLUSTER_ID == '0') {
      this.isOk = false;
      this.message.error("Please Select Cluster Name", '');
    }

    if ((this.data.NAME != undefined && this.data.NAME.trim() != '')) {
      if (!this.namepatt.test(this.data.NAME.trim())) {
        this.isOk = false
        this.message.error('Please Enter Valid Branch Name', '');
      }

    } else {
      this.isOk = false;
      this.message.error("Please Enter Branch Name", '');
    }

    if (this.data.SEQUENCE_NO == undefined || this.data.SEQUENCE_NO <= 0) {
      this.isOk = false;
      this.message.error('Please Enter Sequence Number', '');
    }

    if (this.data.LOCATION_RESTRICATION == undefined || this.data.LOCATION_RESTRICATION.trim() == "") {
      this.isOk = false;
      this.message.error("Please Enter Location Restriction In Meter", '');
    }

    if (this.data.LONGITUTE == undefined || this.data.LONGITUTE == '') {
      this.data.LONGITUTE = ' ';
    }

    if (this.data.LATITUTE == undefined || this.data.LATITUTE == '') {
      this.data.LATITUTE = ' ';
    }

    if (this.data.ADDRESS_LINE_1 == undefined || this.data.ADDRESS_LINE_1 == '') {
      this.data.ADDRESS_LINE_1 = ' ';
    }

    if (this.data.ADDRESS_LINE_2 == undefined || this.data.ADDRESS_LINE_2 == '') {
      this.data.ADDRESS_LINE_2 = ' ';
    }

    if (this.data.CITY == undefined || this.data.CITY == '') {
      this.data.CITY = ' ';
    }

    if (this.data.TEHSIL == undefined || this.data.TEHSIL == '') {
      this.data.TEHSIL = ' ';
    }

    if (this.data.DISTRICT == undefined || this.data.DISTRICT == '') {
      this.data.DISTRICT = ' ';
    }

    if (this.data.STATE == undefined || this.data.STATE == '') {
      this.data.STATE = ' ';
    }

    if (this.data.COUNTRY == undefined || this.data.COUNTRY == '') {
      this.data.COUNTRY = ' ';
    }

    if (this.data.PINCODE == undefined) {
      this.data.PINCODE = 0;
    }

    if (this.isOk) {
      this.isSpinning = true;

      if (this.data.ID) {
        this.api.updateBranch(this.data).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.message.success("Branch Updated Successfully", "");
            this.isSpinning = false;

            if (!addNew)
              this.close(accountMasterPage);

          } else {
            this.message.error("Branch Updation Failed", "");
            this.isSpinning = false;
          }
        });

      } else {
        this.api.createBranch(this.data).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.message.success("Branch Created Successfully", "");
            this.isSpinning = false;

            if (!addNew) {
              this.close(accountMasterPage);

            } else {
              this.data = new Branchmaster();
              this.resetDrawer(accountMasterPage);
            }

          } else {
            this.message.error("Branch Creation Failed", "");
            this.isSpinning = false;
          }
        });
      }
    }
  }

  onFileSelected(event) {
    this.imgUrl = event.target.value;
    this.fileData = <File>event.target.files[0];

    console.log(this.fileData);
    var fileExt = this.fileData.name.split('.').pop();
    console.log("extesion: " + fileExt);
  }
}

import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ShiftMaster } from 'src/app/Models/shiftmaster';
import { ApiService } from 'src/app/Service/api.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-shiftmaster',
  templateUrl: './shiftmaster.component.html',
  styleUrls: ['./shiftmaster.component.css'],
  providers:[DatePipe]
})
export class ShiftmasterComponent implements OnInit {

  @Input() drawerClose: Function;
  @Input() data: ShiftMaster;
  @Input() drawerVisible: boolean;

  listdata1 = [];
  listdata2 = [];
  orgId = this.cookie.get('orgId');
  loadingRecords = true;
  isSpinning = false;
  fileData: File = null;
  isOk = true;

  emailpattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  namepatt = /^[a-zA-Z \-\']+/
  mobpattern = /^[6-9]\d{9}$/
  onlynum = /^[0-9]*$/
  onlychar = /^[a-zA-Z ]*$/
  longitudepattern = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/
  latitudepattern = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/
  pat1 = /^\d{6}$/;
  namepattern = /[a-zA-Z][a-zA-Z ]+/
  cityRegex = /^[a-zA-z] ?([a-zA-z]|[a-zA-z] )*[a-zA-z]$/;
  aaddpattern = /^[a-zA-Z0-9\s,'-]*$/
  namepatte = /[ .a-zA-Z]+/
  passpattern = /^\S*$/
  imgUrl
  time
  time1
  time2
  date

  constructor(private api: ApiService, private cookie: CookieService, private datePipe: DatePipe, private message: NzNotificationService) {
  }

  ngOnInit() {
    this.getallorg1();
   
  }

  omit(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  close(shiftsMasterPage: NgForm) {
    this.drawerClose();
    this.resetDrawer(shiftsMasterPage);
  }

  resetDrawer(shiftsMasterPage: NgForm) {
    shiftsMasterPage.form.reset();
    this.add();
  }

  add(): void {
  
  } 

  emailExist = false;

  getallorg1() {
    this.api.getAllShifts(0, 0, 'ID', 'desc', "").subscribe(data => {
      if (data['code'] == 200) {
        this.listdata1 = data['data'];
      }

    }, err => {
      console.log(err);
    });
  }

  // getallorg2(id) {
  //   this.api.getAllShifts(0, 0, 'ID', 'desc', " and ID !=" + id).subscribe(data => {
  //     if (data['code'] == 200) {
  //       this.listdata2 = data['data'];
  //     }

  //   }, err => {
  //     console.log(err);
  //   });
  // }

  // NAME:string
  // CODE:string
  // TYPE:string="R"
  // BEGIN_TIME:any
  // END_TIME:any
  // END_NEXT_DAY:boolean
  // IS_BREAK:boolean
  // BREAK_START_TIME:any
  // BREAK_END_TIME:any
  // MARK_HALFDAY_AFTER:any
  // MARK_HALFDAY_BEFORE:any
  // MARK_FULLDAY_AFTRE:any
  // MARK_FULLDAY_BEFORE:any
  // GRACE_TIME:boolean
  // GRACE_MINUTES:string
  // TOTAL_WORKING_TIME:string
  // BREAKE_TIME:string
  // NO_OF_BREAKS:number

  save(addNew: boolean, shiftsMasterPage: NgForm): void {
    this.isOk = true;

    if ( this.data.NAME == undefined && this.data.CODE == undefined  ) {
       this.isOk = false;
       this.message.error('Please Fill All Required Information', '');

   } else if (this.data.NAME == undefined || this.data.NAME == '') {

      this.isOk = false

      this.message.error('Please Enter Name', '')
    } else if (this.data.CODE == '' || this.data.CODE == undefined) {

      this.isOk = false;

      this.message.error('Please Enter Code', '');  

    } else if (this.data.TYPE == 'R') {

      if (this.data.BEGIN_TIME == null || this.data.BEGIN_TIME == undefined) {
        this.isOk = false;
        this.message.error("Please Select Begin Time", '');
      }
  
      if (this.data.END_TIME == null || this.data.END_TIME == undefined) {
        this.isOk = false;
        this.message.error("Please Select End Time", '');
      }
  
       if (this.data.MARK_HALFDAY_AFTER == null || this.data.MARK_HALFDAY_AFTER == undefined) {
        this.isOk = false;
        this.message.error("Please Select Mark Halfday After Time", '');
      }
  
  
      if (this.data.MARK_HALFDAY_BEFORE == null || this.data.MARK_HALFDAY_BEFORE == undefined) {
        this.isOk = false;
        this.message.error("Please Mark Halfday Before Time ", '');
      }
  
      if (this.data.MARK_FULLDAY_AFTRE == null || this.data.MARK_FULLDAY_AFTRE == undefined) {
        this.isOk = false;
        this.message.error("Please Select Mark Fullday After Time ", '');
      }
  
      if (this.data.MARK_FULLDAY_BEFORE == null || this.data.MARK_FULLDAY_BEFORE == undefined) {
        this.isOk = false;
        this.message.error("Please Select Mark Full Before Time ", '');
       
     } 
     
     if(this.data.IS_BREAK==true){
      if (this.data.BREAK_START_TIME == null || this.data.BREAK_START_TIME == undefined) {
        this.isOk = false;
        this.message.error("Please Select Break Start Time ", '');
      }
  
      if (this.data.BREAK_END_TIME == null || this.data.BREAK_END_TIME == undefined) {
        this.isOk = false;
        this.message.error("Please Select Break End Time ", '');
       
     } 
     }

     if(this.data.GRACE_TIME==true){
      if ( this.data.GRACE_MINUTES == undefined && this.data.GRACE_MINUTES == ''  ) {
        this.isOk = false;
        this.message.error('Please Enter Grace Minute Information', '');
 
    }
     }
    }
    else if (this.data.TYPE == 'F') {

      if (this.data.TOTAL_WORKING_TIME == undefined || this.data.TOTAL_WORKING_TIME == '') {

        this.isOk = false
  
        this.message.error('Please Enter Total Working Time', '')
      } if (this.data.BREAKE_TIME == '' || this.data.BREAKE_TIME == undefined) {
  
        this.isOk = false;
  
        this.message.error('Please Enter Break Time', '');  

    }
  }

 if (this.isOk) {
      this.isSpinning = true;
     
      this.orgId = this.cookie.get('orgId');
      this.data.BEGIN_TIME = this.datePipe.transform(this.data.BEGIN_TIME, "HH:mm:ss");
      this.data.END_TIME = this.datePipe.transform(this.data.END_TIME, "HH:mm:ss");
      this.data.MARK_HALFDAY_AFTER = this.datePipe.transform(this.data.MARK_HALFDAY_AFTER, "HH:mm:ss");
      this.data.MARK_HALFDAY_BEFORE = this.datePipe.transform(this.data.MARK_HALFDAY_BEFORE, "HH:mm:ss");
      this.data.BREAK_START_TIME = this.datePipe.transform(this.data.BREAK_START_TIME, "HH:mm:ss");
      this.data.BREAK_END_TIME = this.datePipe.transform(this.data.BREAK_END_TIME, "HH:mm:ss");
      this.data.MARK_FULLDAY_AFTRE = this.datePipe.transform(this.data.MARK_FULLDAY_AFTRE, "HH:mm:ss");
      this.data.MARK_FULLDAY_BEFORE = this.datePipe.transform(this.data.MARK_FULLDAY_BEFORE, "HH:mm:ss");

      if (this.data.ID) {
           this.api.updateShifts(this.data).subscribe(successCode => {
            if (successCode['code'] == 200) {
              this.message.success("Oraganization Updated Successfully", "");

              if (!addNew)
                this.drawerClose();

              this.isSpinning = false;
              this.resetDrawer(shiftsMasterPage);

            } else {
              this.message.error("Oraganization Updation Failed", "");
              this.isSpinning = false;
            }
          });

       

      } else {
      

       
          this.api.createShift(this.data).subscribe(successCode => {
            if (successCode['code'] == 200) {
              this.isSpinning = false;
              this.message.success("Oraganization Created Successfully", "");

              if (!addNew) {
                this.drawerClose();
                this.resetDrawer(shiftsMasterPage);

              } else {
                this.data = new ShiftMaster();
                this.resetDrawer(shiftsMasterPage);
              }

            } else {
              this.message.error("Oraganization Creation Failed", "");
              this.isSpinning = false;
            }
          });

        
      }
    }
  

  // onFileSelected(event) {
  //   this.imgUrl = event.target.value;
  //   this.fileData = <File>event.target.files[0];
  //   console.log(this.fileData);
  //   var fileExt = this.fileData.name.split('.').pop();
  //   console.log("extesion: " + fileExt);
  // }

}
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, concat, forkJoin, Observable, of } from 'rxjs';
import { HttpHeaders, HttpClient, HttpEvent, HttpParams, HttpRequest } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { NzNotificationService } from 'ng-zorro-antd';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Application } from '../Models/application';
import { Module } from '../Models/module';
import { User } from '../Models/user';
import { ApplicationModuleMapping } from '../Models/application-module-mapping';
import { UserModuleMapping } from '../Models/user-module-mapping';
import { Role } from '../Models/role';
import { RoleModuleMapping } from '../Models/roleModulemapping';
import { Form } from '../Models/form';
import { Faqhead } from '../Models/faqhead';
import { Faq } from '../Models/faq';
import { Roledetails } from '../Models/roledetails';
import { Faqresponse } from '../Models/faqresponse';
import { Department } from '../Models/department';
import { DepartmentworkingDetails } from '../Models/departmentworking-details';
import { Emailserver } from '../Models/emailserver';
import { Smsserver } from '../Models/smsserver';
import { Emailhistory } from '../Models/emailhistory';
import { Smshistory } from '../Models/smshistory';
import { Folder } from '../Models/folder';
import { Ticket } from '../Models/ticket';
import { Ticketdetails } from '../Models/ticketdetails';
import { Useraccessmapping } from '../Models/useraccessmapping';
import { Task } from '../Models/task';
import { Viewhistory } from '../Models/viewhistory';
import { Ticketgroup } from '../Models/ticketgroup';
import { Ticketfaqmapping } from '../Models/ticketfaqmapping';
import { AWSTemplets } from '../Models/AWSTemplets';
import { AWSSendMail } from '../Models/AWSSendMail';
import { OrganizationMaster } from '../Models/organization-master';
import { fileMaster } from '../Models/fileMaster';
import { folderMaster } from '../Models/folderMaster';
import { Branchmaster } from '../Models/Branchmaster';
import { catchError } from 'rxjs/operators';
import { FolderSharing } from '../Models/folderSharing';
import { LeaveType } from '../Models/leave-type';
import { Leave } from '../Models/Leave';
import { ExpensesHeadMaster } from '../Models/expenses-head-master';
import { EvaluationCriateria } from '../Models/evaluation-criateria';
import { CustomerMaster } from '../Models/customer-master';
import { HolidayMaster } from '../Models/holiday-master';
import { ThoughMaster } from '../Models/though-master';
import { Latemark } from '../Models/Latemark';
import { BirthdayMaster } from '../Models/birthday-master';
import { Earlymark } from '../Models/Earlymark';
import { Expensedetails } from '../Models/expensedetails';
import { Expenses } from '../Models/Expenses';
import { Employeecollegedetails } from '../Models/Employeecollegedetails';
import { Attendance } from '../Models/Attedance';
import { AttendanceReport } from '../Models/AttendanceReport';
import { EmployeeMaster } from '../Models/employeemaster';
import { LeaveReport } from '../Models/LeaveReport';
import { Earlymarkreport } from '../Models/Earlymarkreport';
import { ExpenseReport } from '../Models/ExpenseReport';
import { EvaluationReport } from '../Models/EvaluationReport';
import { AssetCategory } from '../Models/AssetCategory';
import { Asset } from '../Models/Asset';
import { AssetMapping } from '../Models/AssetMapping';
import { AssetRequest } from '../Models/AssetRequest';
import { ExpenseSummeryReport } from '../Models/ExpenseSummaryReport';
import { Attendanceregister } from '../Models/AttendanceRegister';
import { UpdateAttendanceRegister } from '../Models/UpdateAttendanceRegister';
import { ShiftMaster } from '../Models/shiftmaster';
// import { leaveMaster } from '../LeaveMaster';
import { leaveMaster } from '../pages/Leave Master/LeaveMaster';
import { leavePolicy } from '../pages/Leave Calculation Module/leavePolicy';
import { leaveYearConfiguration } from '../pages/Leave Calculation Module/leaveyearconfiguration';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  currentMessage = new BehaviorSubject(null);
  cloudID
  clientId = 1

  httpHeaders = new HttpHeaders();
  options = {
    headers: this.httpHeaders
  };

  httpHeaders1 = new HttpHeaders();
  options1 = {
    headers: this.httpHeaders1
  };

  gmUrl = "https://gm.tecpool.in:8078/";
  // baseUrl = "http://192.168.29.131:4460/";

  // Live URL
  // baseUrl = "https://89f0-171-79-45-173.in.ngrok.io/";
  // https://762https://7628-182-68-20-5.in.ngrok.io8-182-68-20-5.in.ngrok.io/
  // baseUrl = "https://b4c5-106-213-85-253.in.ngrok.io/";

  // Development URL
  baseUrl = "https://koynabank.uvtechsoft.com:9448/"

  url = this.baseUrl + 'api/';
  //loggerUrl =  this.baseUrl + "logger/";
  imgUrl = this.baseUrl + "upload/";
  apkUrl = this.baseUrl + "upload/App/";
  retriveimgUrl = this.baseUrl + "static/";
  applicationId = 1;

  moduleId = Number(this.cookie.get('moduleId'));
  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deviceId = Number(this.cookie.get('deviceId'));

  constructor(private cookie: CookieService, private message: NzNotificationService, private httpClient: HttpClient, private angularFireMessaging: AngularFireMessaging) {
    if (this.cookie.get('deviceId') === '' || this.cookie.get('deviceId') === null) {
      var deviceId = this.randomstring(16);
      this.cookie.set('deviceId', deviceId.toString(), 365, "", "", false, "Strict");
    }

    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'apikey': ' gT4oQrxHPMccXtZzBt5gm6Fn2H2AHnsN',
      'applicationkey': 'ywz04IlfiTEGd8se',
      'deviceid': this.cookie.get('deviceId'),
      'supportkey': this.cookie.get('supportKey'),
      'Token': this.cookie.get('token'),
    });

    this.options = {
      headers: this.httpHeaders
    };

    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
  }

  login(email: string, password: string): Observable<Useraccessmapping[]> {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'apikey': ' gT4oQrxHPMccXtZzBt5gm6Fn2H2AHnsN',
      'applicationkey': 'ywz04IlfiTEGd8se',
      'deviceid': this.cookie.get('deviceId'),
      'supportkey': this.cookie.get('supportKey'),
      'Token': this.cookie.get('token'),
    });

    this.options = {
      headers: this.httpHeaders
    };

    var data = {
      username: email,
      password: password,
      TYPE: "W",
      DEVICE_ID: "A"
    };

    return this.httpClient.post<Useraccessmapping[]>(this.baseUrl + "user/login", JSON.stringify(data), this.options);
  }

  getAllEmployeeLeave1(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, EMPLOYEE_ID: any): Observable<Leave[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      EMPLOYEE_ID: EMPLOYEE_ID
    };

    return this.httpClient.post<Leave[]>(this.url + "employeeLeaveMapping/get", JSON.stringify(data), this.options);
  }

  createLeave1(leave: Leave): Observable<number> {
    leave.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "employeeLeaveMapping/create", JSON.stringify(leave), this.options);
  }

  updateLeave1(leave: Leave): Observable<number> {
    leave.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "employeeLeaveMapping/update", JSON.stringify(leave), this.options);
  }


  employeelogin(email: string, password: string): Observable<Useraccessmapping[]> {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'apikey': ' gT4oQrxHPMccXtZzBt5gm6Fn2H2AHnsN',
      'applicationkey': 'ywz04IlfiTEGd8se',
      'deviceid': this.cookie.get('deviceId'),
      'supportkey': this.cookie.get('supportKey'),
      'Token': this.cookie.get('token'),
    });

    this.options = {
      headers: this.httpHeaders
    };

    var data = {
      username: email,
      password: password,
      TYPE: "W",
      DEVICE_ID: "A"
    };

    return this.httpClient.post<Useraccessmapping[]>(this.baseUrl + "employee/login", JSON.stringify(data), this.options);
  }

  logoutForSessionValues() {
    this.cookie.delete("supportKey")
    this.cookie.delete("token")
    sessionStorage.clear();
    window.location.reload()
  }

  randomstring(L) {
    var s = '';

    var randomchar = function () {
      var n = Math.floor(Math.random() * 62);
      if (n < 10) return n; //1-10
      if (n < 36) return String.fromCharCode(n + 55); //A-Z
      return String.fromCharCode(n + 61); //a-z
    }

    while (s.length < L) s += randomchar();
    return s;
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log("new message received. ", payload);

        this.currentMessage.next(payload);
        this.message.info(payload['notification']['title'], payload['notification']['body'], { nzDuration: 0 });
      })
  }

  subscribeTokenToTopic(token, topic) {
    // var bodyArray = []
    // for (var i = 0; i < channels.length; i++) {
    //   if (channels[i] != null && channels[i].trim() != '') {
    //     var b = {
    //       to: '/topics/' + channels[i], 'registration_tokens': [token]
    //     }
    //     bodyArray.push(b);
    //   }
    //   if (i == channels.length - 1) {

    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `key= AAAAVM2R_rI:APA91bGOluvSPCVNouGePNI0KxG1XhF09u69xB5s9tnqhFddvCLGZcMqoEnQrmSMM-CXUfLh2uZZPB0JGeDiavayd4oSl3ADw_Ft6iS0jGqBkysT3_upWREyEGphtaTEhyqtL3Obubfh`
    });

    var options22 = {
      headers: this.httpHeaders
    };

    let httpReqs = topic
      .map(i =>
        this.httpClient.post(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${i}`, {}, options22)
          .pipe(catchError(err => of({ err })))
      );

    concat(...httpReqs).subscribe(data => {
      window.location.reload();

      // setTimeout(() => {
      //   window.location.reload();
      // }, 5000);
    });

    // }
    // }
    return true;

    // fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, {
    //   method: 'POST',
    //   headers: new Headers({
    //     Authorization: `key= AAAAVM2R_rI:APA91bGOluvSPCVNouGePNI0KxG1XhF09u69xB5s9tnqhFddvCLGZcMqoEnQrmSMM-CXUfLh2uZZPB0JGeDiavayd4oSl3ADw_Ft6iS0jGqBkysT3_upWREyEGphtaTEhyqtL3Obubfh`
    //   })
    // })
    //   .then((response) => {
    //     if (response.status < 200 || response.status >= 400) {
    //       console.log(response.status, response);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(error.result);
    //   });
    // return true;
  }

  unsubscribeTokenToTopic(token) {
    var d = this.cookie.get('channels');
    var channels = d.split(',');
    var bodyArray = [];

    for (var i = 0; i < channels.length; i++) {
      if (channels[i] != null && channels[i].trim() != '') {
        var b = {
          to: '/topics/' + channels[i], 'registration_tokens': [token]
        }
        bodyArray.push(b);
      }

      if (i == channels.length - 1) {
        this.httpHeaders = new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `key= AAAAVM2R_rI:APA91bGOluvSPCVNouGePNI0KxG1XhF09u69xB5s9tnqhFddvCLGZcMqoEnQrmSMM-CXUfLh2uZZPB0JGeDiavayd4oSl3ADw_Ft6iS0jGqBkysT3_upWREyEGphtaTEhyqtL3Obubfh`
        });

        var options22 = {
          headers: this.httpHeaders
        };

        let httpReqs = bodyArray
          .map(i =>
            this.httpClient.post(`https://iid.googleapis.com/iid/v1:batchRemove`, i, options22)
              .pipe(catchError(err => of({ err })))
          );

        concat(...httpReqs).subscribe(data => {
          this.cookie.deleteAll();
          sessionStorage.clear()
          window.location.reload();

          // setTimeout(() => {
          //   window.location.reload();
          // }, 5000);
        });
      }
    }
    return true;

    // fetch(`https://iid.googleapis.com/iid/v1:batchRemove`, {
    //   method: 'POST',
    //   body: JSON.stringify(b),
    //   headers: new Headers({
    //     'Content-Type': 'application/json',
    //     'Authorization': `key= AAAAVM2R_rI:APA91bGOluvSPCVNouGePNI0KxG1XhF09u69xB5s9tnqhFddvCLGZcMqoEnQrmSMM-CXUfLh2uZZPB0JGeDiavayd4oSl3ADw_Ft6iS0jGqBkysT3_upWREyEGphtaTEhyqtL3Obubfh`
    //   })
    // })
    //   .then((response) => {
    //     if (response.status < 200 || response.status >= 400) {
    //       console.log(response.status, response);
    //     }  
    //   })
    //   .catch((error) => {
    //     console.error(error.result);
    //   });
  }

  // public async updatePushToken(token: string,userId) {
  //   try {
  //     const devices = await this.angularFireMessaging.firestore.collection('Devices').where('token', '==', token).get();

  //     if (devices.empty) {
  //       const deviceInfo = this.deviceService.getDeviceInfo();
  //       const data = {
  //         token: token,
  //         userId: userId,
  //         deviceType: 'web',
  //         deviceInfo: {
  //           browser: deviceInfo.browser,
  //           userAgent: deviceInfo.userAgent
  //         },
  //         createdAt: firestore.FieldValue.serverTimestamp()
  //       };

  //       await this.angularFireMessaging.firestore.collection('Devices').add(data);
  //       console.log('New Device Added');
  //     } else {
  //       console.log('Already existing Device');
  //     }
  //   } catch (error) {
  //     console.log("Error Message", error);
  //   }
  // }

  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
        this.cloudID = token;
        // this.subscribeTokenToTopic(this.cloudID, 'new');
        // this.updatePushToken(token,userId);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  onUploadNewMethod(selectedFile, ext, fKey) {
    this.httpHeaders1 = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      'Accept': 'application/json',
      'f_key': fKey,
      'f_ext': ext,
      'supportkey': this.cookie.get('supportKey'),
      'apikey': ' gT4oQrxHPMccXtZzBt5gm6Fn2H2AHnsN',
      'applicationkey': 'ywz04IlfiTEGd8se',
      'Token': this.cookie.get('token'),
    });

    this.options1 = {
      headers: this.httpHeaders1
    };

    const fd = new FormData()
    fd.append("F_DATA", selectedFile)
    fd.append("F_EXT", ext)
    fd.append("F_KEY", fKey)
    return this.httpClient.post(this.baseUrl + 'file/upload', fd, this.options1)
  }

  getFile(lkey) {
    var data = {
      L_KEY: lkey
    };
    return this.httpClient.post(this.baseUrl + 'file/getFile', JSON.stringify(data), this.options)
  }

  onUpload(folderName, selectedFile, filename) {
    //console.log(this.httpHeaders1)
    const fd = new FormData()
    fd.append("Image", selectedFile, filename)
    //console.log("selected file" + JSON.stringify(selectedFile))
    //console.log("form data: " + fd)
    this.httpClient.post(this.imgUrl + folderName, fd, this.options1)
      .subscribe(res => {
        //console.log(res);
      });
  }

  onUpload2(folderName, selectedFile, filename) {
    this.httpHeaders1 = new HttpHeaders({
      'Accept': 'application/json',
      'apikey': ' gT4oQrxHPMccXtZzBt5gm6Fn2H2AHnsN',
      'applicationkey': 'ywz04IlfiTEGd8se',
      'deviceid': this.cookie.get('deviceId'),
      'supportkey': this.cookie.get('supportKey'),
      'Token': this.cookie.get('token'),
    });

    this.options1 = {
      headers: this.httpHeaders1
    };

    const fd = new FormData();
    fd.append("Image", selectedFile, filename);

    return this.httpClient.post(this.imgUrl + folderName, fd, this.options1);
  }

  onFileUploadWithProgress(folderName, selectedFile, filename): Observable<HttpEvent<any>> {
    this.httpHeaders1 = new HttpHeaders({
      'supportkey': this.cookie.get('supportKey'),
      'apikey': ' gT4oQrxHPMccXtZzBt5gm6Fn2H2AHnsN',
      'applicationkey': 'ywz04IlfiTEGd8se',
      'Token': this.cookie.get('token'),
    });

    const fd = new FormData();
    fd.append("Apk", selectedFile, filename);
    let params = new HttpParams();

    const options = {
      headers: this.httpHeaders1,
      params: params,
      reportProgress: true,
    };

    const req = new HttpRequest('POST', this.apkUrl, fd, options);
    return this.httpClient.request(req);
  }

  onFileUpload2(file): Observable<HttpEvent<any>> {
    this.httpHeaders1 = new HttpHeaders({
      'supportkey': this.cookie.get('supportKey'),
      'apikey': ' gT4oQrxHPMccXtZzBt5gm6Fn2H2AHnsN',
      'applicationkey': 'ywz04IlfiTEGd8se',
      'Token': this.cookie.get('token'),
    });

    const fd = new FormData();
    fd.append("FILE", file);
    let params = new HttpParams();

    const options = {
      headers: this.httpHeaders1,
      params: params,
      reportProgress: true,
    };

    const req = new HttpRequest('POST', this.url + 'folder/upload/', fd, options);
    return this.httpClient.request(req);
  }

  loggerInit() {
    var data = {
      CLIENT_ID: this.clientId
    };

    this.httpHeaders1 = new HttpHeaders({
      'Accept': 'application/json',
      'supportkey': this.cookie.get('supportKey'),
      'apikey': 'SLQphsR7FlH8K3jRFnv23Mayp8jlnp9R',
      'applicationkey': 'ywz04IlfiTEGd8se',
      'Token': this.cookie.get('token'),
      'deviceid': this.cookie.get('deviceId'),
      'Content-Type': 'application/json',
    });

    this.options1 = {
      headers: this.httpHeaders1
    };

    return this.httpClient.post(this.gmUrl + "device/init", JSON.stringify(data), this.options1);
  }

  addLog(type, text, userId): Observable<number> {
    var data = {
      LOG_TYPE: type,
      LOG_TEXT: text,
      USER_ID: userId,
      CLIENT_ID: this.clientId
    };

    this.httpHeaders1 = new HttpHeaders({
      'Accept': 'application/json',
      'supportkey': this.cookie.get('supportKey'),
      'apikey': 'SLQphsR7FlH8K3jRFnv23Mayp8jlnp9R',
      'applicationkey': 'ywz04IlfiTEGd8se',
      'Token': this.cookie.get('token'),
      'deviceid': this.cookie.get('deviceId'),
      'Content-Type': 'application/json',
    });

    console.log(this.cookie.get('deviceId'), "this.cookie.get('deviceId')")
    this.options1 = {
      headers: this.httpHeaders1
    };

    return this.httpClient.post<number>(this.gmUrl + "device/addLog", JSON.stringify(data), this.options1);
  }

  getAllclusterMaster(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<any>(this.url + "cluster/get", JSON.stringify(data), this.options);
  }

  createclusterMaster(ticketGroup: any): Observable<any> {
    ticketGroup.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(this.url + "cluster/create/", JSON.stringify(ticketGroup), this.options);
  }

  updateclusterMaster(ticketGroup: any): Observable<any> {
    ticketGroup.CLIENT_ID = this.clientId;
    return this.httpClient.put<any>(this.url + "cluster/update/", JSON.stringify(ticketGroup), this.options);
  }

  getAllclusterbranchMaster(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<any>(this.url + "clusterBranch/get", JSON.stringify(data), this.options);
  }

  createclusterbranchMaster(ticketGroup: any): Observable<any> {
    ticketGroup.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(this.url + "clusterBranch/create/", JSON.stringify(ticketGroup), this.options);
  }

  updateclusterbranchMaster(ticketGroup: any): Observable<any> {
    ticketGroup.CLIENT_ID = this.clientId;
    return this.httpClient.put<any>(this.url + "clusterBranch/update/", JSON.stringify(ticketGroup), this.options);
  }

  getAllemployeeMaster(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<any>(this.url + "employee/get", JSON.stringify(data), this.options);
  }

  createemployeeMaster(ticketGroup: any): Observable<any> {
    ticketGroup.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(this.url + "employee/create/", JSON.stringify(ticketGroup), this.options);
  }

  updateemployeeMaster(ticketGroup: any): Observable<any> {
    ticketGroup.CLIENT_ID = this.clientId;
    return this.httpClient.put<any>(this.url + "employee/update/", JSON.stringify(ticketGroup), this.options);
  }

  getAllOrganizations(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<OrganizationMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<OrganizationMaster[]>(this.url + "organisation/get", JSON.stringify(data), this.options);
  }

  createOrganization(organization: OrganizationMaster): Observable<number> {
    organization.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "organisation/addOrganisation/", JSON.stringify(organization), this.options);
  }

  updateOrganization(organization: OrganizationMaster): Observable<number> {
    organization.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "organisation/updateOrganisation/", JSON.stringify(organization), this.options);
  }


  //shiftmaster

  getAllShifts(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<ShiftMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<ShiftMaster[]>(this.url + "shift/get/", JSON.stringify(data), this.options);
  }

  createShift(shift: ShiftMaster): Observable<number> {
    shift.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "shift/create/", JSON.stringify(shift), this.options);
  }

  updateShifts(shift: ShiftMaster): Observable<number> {
    shift.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "shift/update/", JSON.stringify(shift), this.options);
  }

  getAllFeature(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<OrganizationMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<any>(this.url + "features/get", JSON.stringify(data), this.options);
  }

  createFeature(organization: any): Observable<number> {
    organization.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "features/create/", JSON.stringify(organization), this.options);
  }

  updateFeature(organization: any): Observable<number> {
    organization.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "features/update/", JSON.stringify(organization), this.options);
  }

  getAllBranch(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Branchmaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<any>(this.url + "branch/get", JSON.stringify(data), this.options);
  }

  createBranch(department: any): Observable<number> {
    department.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "branch/create/", JSON.stringify(department), this.options);
  }

  updateBranch(department: any): Observable<number> {
    department.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "branch/update/", JSON.stringify(department), this.options);
  }

  getAllDesignation(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Department[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<any[]>(this.url + "designation/get", JSON.stringify(data), this.options);
  }

  createDesignation(department: any): Observable<number> {
    department.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "designation/create/", JSON.stringify(department), this.options);
  }

  updateDesignation(department: any): Observable<number> {
    department.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "designation/update/", JSON.stringify(department), this.options);
  }

  getAllApplications(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Application[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<Application[]>(this.url + "application/get", JSON.stringify(data), this.options);
  }

  createApplication(application: Application): Observable<number> {
    application.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "application/create/", JSON.stringify(application), this.options);
  }

  updateApplication(application: Application): Observable<number> {
    application.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "application/update/", JSON.stringify(application), this.options);
  }

  getAllForms(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Form[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<Form[]>(this.url + "form/get", JSON.stringify(data), this.options);
  }

  createForm(form: Form): Observable<number> {
    form.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "form/create/", JSON.stringify(form), this.options);
  }

  updateForm(form: Form): Observable<number> {
    form.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "form/update/", JSON.stringify(form), this.options);
  }

  getAllRoles2(parentId: string, employeeId: number, filter: string): Observable<Role[]> {
    var data = {
      parentId: parentId,
      employeeId: employeeId,
      filter: filter
    };

    return this.httpClient.post<any[]>(this.url + "role/getChildRoles", JSON.stringify(data), this.options);
  }

  getAllRoles(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Role[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<any[]>(this.url + "role/get", JSON.stringify(data), this.options);
  }

  createRole(application: any): Observable<number> {
    application.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "role/create/", JSON.stringify(application), this.options);
  }

  updateRole(application: any): Observable<number> {
    application.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "role/update/", JSON.stringify(application), this.options);
  }

  getRoleModules(roleId: number) {
    var data = {
      ROLE_ID: roleId
    };

    return this.httpClient.post<RoleModuleMapping[]>(this.url + "roleModuleMapping/get", JSON.stringify(data), this.options);
  }

  addRoleModuleDetails(roleId1: number, data1: string[]): Observable<number> {
    var data = {
      ROLE_ID: roleId1,
      data: data1,
    };

    return this.httpClient.post<number>(this.url + "roleModuleMapping/addBulk", data, this.options);
  }

  getAllModules(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Module[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<Module[]>(this.url + "module/get", JSON.stringify(data), this.options);
  }

  createModule(module: Module): Observable<number> {
    module.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "module/create/", JSON.stringify(module), this.options);
  }

  updateModule(module: Module): Observable<number> {
    module.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "module/update/", JSON.stringify(module), this.options);
  }

  getAllUsers(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<User[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<User[]>(this.url + "user/get", JSON.stringify(data), this.options);
  }

  createUser(user: User): Observable<number> {
    user.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "user/create/", JSON.stringify(user), this.options);
  }

  updateUser(user: User): Observable<number> {
    user.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "user/update/", JSON.stringify(user), this.options);
  }

  getMappingModules(applicationId: number) {
    var data = {
      APPLICATION_ID: applicationId
    };

    return this.httpClient.post<ApplicationModuleMapping[]>(this.url + "applicationModulemapping/get", JSON.stringify(data), this.options);
  }

  addMappingModules(applicationId1: number, data1: string[]): Observable<number> {
    var data = {
      APPLICATION_ID: applicationId1,
      data: data1,
    };

    return this.httpClient.post<number>(this.url + "applicationModulemapping/addBulk", data, this.options);
  }

  getApplicationModuleMapping(userId: number) {
    var data = {
      USER_ID: userId
    };

    return this.httpClient.post<UserModuleMapping[]>(this.url + "userModuleMapping/get", JSON.stringify(data), this.options);
  }

  addApplicationModuleMapping(userId: number, data1: string[]): Observable<number> {
    var data = {
      USER_ID: userId,
      data: data1,
    };

    return this.httpClient.post<number>(this.url + "userModuleMapping/addBulk", data, this.options);
  }

  getAllFaqHeads(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<any[]>(this.url + "faqHead/get", JSON.stringify(data), this.options);
  }

  createFaqHead(faqHead: Faqhead): Observable<number> {
    faqHead.APPLICATION_ID = this.applicationId;
    faqHead.CLIENT_ID = this.clientId;
    faqHead.STATUS = faqHead.STATUS ? 1 : 0
    faqHead.IS_PARENT = faqHead.IS_PARENT ? 1 : 0

    return this.httpClient.post<number>(this.url + "faqHead/create/", JSON.stringify(faqHead), this.options);
  }

  updateFaqHead(faqHead: Faqhead): Observable<number> {
    faqHead.APPLICATION_ID = this.applicationId;
    faqHead.CLIENT_ID = this.clientId;
    faqHead.STATUS = faqHead.STATUS ? 1 : 0;
    faqHead.IS_PARENT = faqHead.IS_PARENT ? 1 : 0;

    return this.httpClient.put<number>(this.url + "faqHead/update/", JSON.stringify(faqHead), this.options);
  }

  getAllFaqs(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<any[]>(this.url + "faq/get", JSON.stringify(data), this.options);
  }

  createFaq(faq: Faq): Observable<number> {
    faq['USER_ID'] = this.cookie.get('userId');
    faq.CLIENT_ID = this.clientId;

    return this.httpClient.post<number>(this.url + "faq/create/", JSON.stringify(faq), this.options);
  }

  updateFaq(faq: Faq): Observable<number> {
    faq['USER_ID'] = this.cookie.get('userId');
    faq.CLIENT_ID = this.clientId;

    return this.httpClient.put<number>(this.url + "faq/update/", JSON.stringify(faq), this.options);
  }

  getRoleDetails(roleId: number) {
    var data = {
      ROLE_ID: roleId
    };

    return this.httpClient.post<Roledetails[]>(this.url + "roleDetails/getData", JSON.stringify(data), this.options);
  }

  createRoleDetails(roleId: any) {
    roleId.CLIENT_ID = this.clientId;
    return this.httpClient.post<Roledetails[]>(this.url + "roleDetails/create", JSON.stringify(roleId), this.options);
  }

  getForms(userId: string, roleId: number) {
    var data = {
      USER_ID: userId,
      ROLE_ID: roleId,
    };

    this.httpHeaders1 = new HttpHeaders({
      'Accept': 'application/json',
      'supportkey': this.cookie.get('supportKey'),
      'apikey': ' gT4oQrxHPMccXtZzBt5gm6Fn2H2AHnsN',
      'applicationkey': 'ywz04IlfiTEGd8se',
      'Token': this.cookie.get('token'),
      'deviceid': this.cookie.get('deviceId'),
      'Content-Type': 'application/json',
    });

    this.options = {
      headers: this.httpHeaders1
    };

    return this.httpClient.post<Roledetails>(this.url + "user/getForms", JSON.stringify(data), this.options);
  }

  // getForms(roleId: number) {
  //   var data = {
  //     ROLE_ID: roleId,
  //   };
  //   // return this.httpClient.post<Roledetails>(this.url + "user/getForms", JSON.stringify(data), this.options);
  // return this.httpClient.post<Roledetails>(this.url + "user/getMenu", JSON.stringify(data), this.options);
  // }

  addRoleDetails(roleId: number, data1: string[]): Observable<number> {
    var data = {
      ROLE_ID: roleId,
      data: data1,
    };

    return this.httpClient.post<number>(this.url + "roleDetails/addBulk", data, this.options);
  }

  getAllFaqResponses(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Faqresponse[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<Faqresponse[]>(this.url + "faqResponses/get", JSON.stringify(data), this.options);
  }

  createFaqResponse(data: any): Observable<number> {
    return this.httpClient.post<number>(this.url + "faqResponse/create/", data, this.options);
  }

  updateFaqResponse(data: Faqresponse): Observable<number> {
    return this.httpClient.put<number>(this.url + "faqResponse/update/", data, this.options);
  }

  getAllDepartments(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Department[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<any>(this.url + "department/get", JSON.stringify(data), this.options);
  }

  createDepartment(department: any): Observable<number> {
    // department.CALENDER_DATA = JSON.stringify(calenderData)
    // department.APPLICATION_ID = this.applicationId;
    // department.MODULE_ID = this.moduleId
    department.CLIENT_ID = this.clientId;

    return this.httpClient.post<number>(this.url + "department/create/", JSON.stringify(department), this.options);
  }

  updateDepartment(department: any): Observable<number> {
    department.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "department/update/", JSON.stringify(department), this.options);
  }

  getAllEmailServers(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Emailserver[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<Emailserver[]>(this.url + "emailServer/get", JSON.stringify(data), this.options);
  }

  createEmailServer(emailServer: Emailserver): Observable<number> {
    emailServer.APPLICATION_ID = this.applicationId;
    emailServer.CLIENT_ID = this.clientId;

    return this.httpClient.post<number>(this.url + "emailServer/create/", JSON.stringify(emailServer), this.options);
  }

  updateEmailServer(emailServer: Emailserver): Observable<number> {
    emailServer.CLIENT_ID = this.clientId;

    return this.httpClient.put<number>(this.url + "emailServer/update/", JSON.stringify(emailServer), this.options);
  }

  getAllSmsServers(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Smsserver[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<Smsserver[]>(this.url + "smsServer/get", JSON.stringify(data), this.options);
  }

  createSmsServer(smsServer: Smsserver): Observable<number> {
    smsServer.SENDER_ID = 0
    smsServer.APPLICATION_ID = this.applicationId;
    smsServer.CLIENT_ID = this.clientId;

    return this.httpClient.post<number>(this.url + "smsServer/create/", JSON.stringify(smsServer), this.options);
  }

  updateSmsServer(smsServer: Smsserver): Observable<number> {
    smsServer.CLIENT_ID = this.clientId;

    return this.httpClient.put<number>(this.url + "smsServer/update/", JSON.stringify(smsServer), this.options);
  }

  getAllEmailHistory(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Emailhistory[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<Emailhistory[]>(this.url + "emailHistory/get", JSON.stringify(data), this.options);
  }

  updateEmailHistory(emailHistory: Emailhistory): Observable<number> {
    emailHistory.CLIENT_ID = this.clientId;

    return this.httpClient.put<number>(this.url + "emailHistory/update/", JSON.stringify(emailHistory), this.options);
  }

  getAllSmsHistory(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Smshistory[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<Smshistory[]>(this.url + "smsHistory/get", JSON.stringify(data), this.options);
  }

  updateSmsHistory(smsHistory: Smshistory): Observable<number> {
    smsHistory.CLIENT_ID = this.clientId;

    return this.httpClient.put<number>(this.url + "smsHistory/update/", JSON.stringify(smsHistory), this.options);
  }

  getAllFolders(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Folder[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<Folder[]>(this.url + "folder/get", JSON.stringify(data), this.options);
  }

  createFolder(folder: Folder): Observable<number> {
    folder.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "folder/create/", JSON.stringify(folder), this.options);
  }

  updateFolder(folder: Folder): Observable<number> {
    folder.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "folder/update/", JSON.stringify(folder), this.options);
  }

  getAllDepartmentWorkingDetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<DepartmentworkingDetails[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<DepartmentworkingDetails[]>(this.url + "departmentWorkingDetails/get", JSON.stringify(data), this.options);
  }

  updateDepartmentWorkingDetails(departmentDetails: DepartmentworkingDetails): Observable<number> {
    departmentDetails.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "departmentWorkingDetails/update/", JSON.stringify(departmentDetails), this.options);
  }

  getAllTickets(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Ticket[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<Ticket[]>(this.url + "ticket/get", JSON.stringify(data), this.options);
  }

  updateTicket(ticket: Ticket): Observable<number> {
    ticket.CLIENT_ID = this.clientId;
    ticket['KEY'] = ticket['KEY'] != undefined && ticket['KEY'] == 'USER' ? 'USER' : 'SUPPORT_USER';
    return this.httpClient.put<number>(this.url + "ticket/update/", JSON.stringify(ticket), this.options);
  }

  getAllTicketDetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Ticketdetails[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<Ticketdetails[]>(this.url + "ticketDetails/get", JSON.stringify(data), this.options);
  }

  createTicketDetail(ticketDetail: Ticketdetails): Observable<number> {
    return this.httpClient.post<number>(this.url + "ticketDetails/create/", JSON.stringify(ticketDetail), this.options);
  }

  createTicket(ticket: any): Observable<number> {
    ticket['ORG_ID'] = Number(this.cookie.get('orgId'))
    return this.httpClient.post<number>(this.url + "ticket/create/", JSON.stringify(ticket), this.options);
  }

  AssignApplicationsData(data1: string[]): Observable<number> {
    var data = {
      data: data1,
    };

    return this.httpClient.post<number>(this.url + "userAccessMapping/addBulk", data, this.options);
  }

  getAllUserAccessMapping(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Useraccessmapping[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<Useraccessmapping[]>(this.url + "userAccessMapping/getData", JSON.stringify(data), this.options);
  }

  getApplicationsAssigned(roleId: number, userId: number) {
    var data = {
      ROLE_ID: roleId,
      USER_ID: userId
    };

    return this.httpClient.post<Useraccessmapping[]>(this.url + "userAccessMapping/get", JSON.stringify(data), this.options);
  }

  getAllTasks(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Task[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<Task[]>(this.url + "task/get", JSON.stringify(data), this.options);
  }

  createTask(task: Task): Observable<number> {
    task.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "task/create/", JSON.stringify(task), this.options);
  }

  updateTask(task: Task): Observable<number> {
    task.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "task/update/", JSON.stringify(task), this.options);
  }

  getAllScheduleHistory(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Viewhistory[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<Viewhistory[]>(this.url + "task/get", JSON.stringify(data), this.options);
  }

  getAllLogHistory(applicationId, supportKey) {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/string',
      'apiKey': ' gT4oQrxHPMccXtZzBt5gm6Fn2H2AHnsN',
      'applicationkey': 'ywz04IlfiTEGd8se',
      'supportKey': this.cookie.get('supportKey'),
      'TOKEN': this.cookie.get('token'),
    });

    this.options = {
      headers: this.httpHeaders
    };

    var data = {
      APPLICATION_ID: applicationId,
      SUPPORT_KEY: supportKey
    };

    return this.httpClient.post(this.url + "device/getDeviceLogs", data, this.options);
  }

  getAllLogUserHistory(type, email, applicationId) {
    var data = {
      TYPE: type,
      TEXT: email,
      APPLICATION_ID: applicationId
    };

    return this.httpClient.post(this.url + "device/getLogs", JSON.stringify(data), this.options);
  }

  getAllTicketGroups(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Ticketgroup[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<Ticketgroup[]>(this.url + "ticketGroup/get", JSON.stringify(data), this.options);
  }

  createTicketGroup(ticketGroup: Ticketgroup): Observable<number> {
    ticketGroup.IS_LAST = ticketGroup.IS_LAST ? 1 : 0;
    ticketGroup.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "ticketGroup/create/", JSON.stringify(ticketGroup), this.options);
  }

  updateTicketGroup(ticketGroup: Ticketgroup): Observable<number> {
    ticketGroup.IS_LAST = ticketGroup.IS_LAST ? 1 : 0;
    ticketGroup.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "ticketGroup/update/", JSON.stringify(ticketGroup), this.options);
  }

  getMappingFaqs2(ticketId: number) {
    var data = {
      TICKET_GROUP_ID: ticketId,
      ORG_ID: Number(this.cookie.get('orgId'))
    };

    return this.httpClient.post<Ticketfaqmapping[]>(this.url + "ticketFaqMapping/getTicketFaqMapping", JSON.stringify(data), this.options);
  }

  getMappingFaqs(ticketId: number, headID: number) {
    var data = {
      TICKET_GROUP_ID: ticketId,
      FAQ_HEAD_ID: headID,
      ORG_ID: Number(this.cookie.get('orgId'))
    };

    return this.httpClient.post<Ticketfaqmapping[]>(this.url + "ticketFaqMapping/get", JSON.stringify(data), this.options);
  }

  addMappingFaqs(ticketId: number, data1: string[]): Observable<number> {
    var data = {
      TICKET_GROUP_ID: ticketId,
      data: data1,
    };

    return this.httpClient.post<number>(this.url + "ticketFaqMapping/addBulk", data, this.options);
  }

  getAllModuleRoles(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Role[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<Role[]>(this.url + "userAccessMapping/getData", JSON.stringify(data), this.options);
  }

  getScheduledEmails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<AWSTemplets[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<AWSTemplets[]>(this.url + "emailSchedular/get", JSON.stringify(data), this.options);
  }

  getAllAwsTemplates(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<AWSTemplets[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<AWSTemplets[]>(this.url + "awsEmailTemplate/get", JSON.stringify(data), this.options);
  }

  createAwsTemplates(awsTemplates: AWSTemplets): Observable<number> {
    awsTemplates.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "awsEmailTemplate/create/", JSON.stringify(awsTemplates), this.options);
  }

  sendAWSMails(awsMailData: AWSSendMail): Observable<number> {
    return this.httpClient.post<number>(this.url + "awsEmailSender/send/", JSON.stringify(awsMailData), this.options);
  }

  scheduleMails(awsMailData: AWSSendMail): Observable<number> {
    var data = {
      TEMPLATE_ID: awsMailData.TEMPLATE_ID,
      SHEET_KEY: awsMailData.SHEET_ID,
      TOTAL_RECORDS: awsMailData.ROWS_COUNT,
      START_TIME: awsMailData.START_TIME,
      IS_SCHEDULED: awsMailData.IS_SCHEDULED,
      CLIENT_ID: 1
    };

    return this.httpClient.post<number>(this.url + "emailSchedular/create/", JSON.stringify(data), this.options);
  }

  updateAwsTemplates(awsTemplates: AWSTemplets): Observable<number> {
    awsTemplates.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "awsEmailTemplate/update/", JSON.stringify(awsTemplates), this.options);
  }

  getDashboardReport(APPLICATION_KEY: string, DEPARTMENT, USER_ID, FROM_DATE, TO_DATE): Observable<Ticket[]> {
    var data = {
      APPLICATION_KEY: APPLICATION_KEY,
      DEPARTMENT_ID: DEPARTMENT,
      USER_ID: USER_ID,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE
    };

    return this.httpClient.post<Ticket[]>(this.url + "ticket/getDashboardReport", JSON.stringify(data), this.options);
  }

  getUserwiseReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, APPLICATION_KEY, DEPARTMENT): Observable<Ticket[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: this.cookie.get('orgId')
    };

    return this.httpClient.post<Ticket[]>(this.url + "ticket/getUserwiseReport", JSON.stringify(data), this.options);
  }

  getDepartmentwiseReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, APPLICATION_KEY, DEPARTMENT): Observable<Ticket[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: this.cookie.get('orgId'),
      DEPARTMENT_ID: DEPARTMENT
    };

    return this.httpClient.post<Ticket[]>(this.url + "ticket/getDepartmentwiseReport", JSON.stringify(data), this.options);
  }

  getAllEmpRoleMap(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<any>(this.url + "employeeRoleMapping/get", JSON.stringify(data), this.options);
  }

  createEmpRoleMap(ticketGroup: any): Observable<any> {
    ticketGroup.CLIENT_ID = this.clientId;
    ticketGroup.PARENT_ID = ticketGroup.PARENT_ROLE_ID;
    ticketGroup.STATUS = ticketGroup.STATUS ? 1 : 0;

    return this.httpClient.post<any>(this.url + "employeeRoleMapping/create/", JSON.stringify(ticketGroup), this.options);
  }

  createEmpRoleMap1(ticketGroup: any): Observable<any> {
    ticketGroup.CLIENT_ID = this.clientId;
    ticketGroup.PARENT_ID = ticketGroup.PARENT_ROLE_ID;
    ticketGroup.STATUS = ticketGroup.STATUS ? 1 : 0;

    return this.httpClient.post<any>(this.url + "employeeRoleMapping/addRole/", JSON.stringify(ticketGroup), this.options);
  }

  updateEmpRoleMap(ticketGroup: any): Observable<any> {
    ticketGroup.PARENT_ID = ticketGroup.PARENT_ROLE_ID
    ticketGroup.CLIENT_ID = this.clientId;
    ticketGroup.STATUS = ticketGroup.STATUS ? 1 : 0;

    return this.httpClient.put<any>(this.url + "employeeRoleMapping/update/", JSON.stringify(ticketGroup), this.options);
  }

  deleteEmpRoleMap(data: any): Observable<any> {
    return this.httpClient.post<any>(this.url + "employeeRoleMapping/delete/", JSON.stringify(data), this.options);
  }

  deleteEmpRoleMap1(data: any): Observable<any> {
    return this.httpClient.post<any>(this.url + "employeeRoleMapping/updateRole/", JSON.stringify(data), this.options);
  }

  gettickdeskDepartmentAdminMapping(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<any>(this.url + "tickdeskDepartmentAdminMapping/get", JSON.stringify(data), this.options);
  }

  createEmpRoleMapBulk(ticketGroup: any, EMPLOYEE_ID: any): Observable<any> {
    var data = {
      EMPLOYEE_ID: EMPLOYEE_ID,
      data: ticketGroup
    }

    return this.httpClient.post<any>(this.url + "tickdeskDepartmentAdminMapping/addBulk", JSON.stringify(data), this.options);
  }

  tickdeskSupportUserMapping(ticketGroup: any, EMPLOYEE_ID: any): Observable<any> {
    var data = {
      EMPLOYEE_ID: EMPLOYEE_ID,
      data: ticketGroup
    }

    return this.httpClient.post<any>(this.url + "tickdeskSupportUserMapping/addBulk", JSON.stringify(data), this.options);
  }

  featuresRoleMapping(ticketGroup: any, FEATURE_ID: number): Observable<any> {
    var data = {
      FEATURE_ID: FEATURE_ID,
      data: ticketGroup
    }

    return this.httpClient.post<any>(this.url + "featuresRoleMapping/addBulk", JSON.stringify(data), this.options);
  }

  organisationFeatureMapping(ticketGroup: any, ORG_ID: number): Observable<any> {
    var data = {
      ORG_ID: ORG_ID,
      data: ticketGroup
    }

    return this.httpClient.post<any>(this.url + "organisationFeatureMapping/addBulk", JSON.stringify(data), this.options);
  }

  gettickdeskSupportUserMapping(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<any>(this.url + "tickdeskSupportUserMapping/get", JSON.stringify(data), this.options);
  }

  createFolderSharigDetailsBulk(folderID: any, folderData: any): Observable<any> {
    var data = {
      FOLDER_ID: folderID,
      data: folderData
    }

    return this.httpClient.post<any>(this.url + "folderSharingDetails/addBulk", JSON.stringify(data), this.options);
  }

  createFileSharigDetailsBulk(fileID: any, fileData: any): Observable<any> {
    var data = {
      FILE_ID: fileID,
      data: fileData
    }

    return this.httpClient.post<any>(this.url + "fileSharingDetails/addBulk", JSON.stringify(data), this.options);
  }

  checkTextBoxIsValid1(value: any) {
    const expression = /^[A-Za-z1-9 ]*$/;
    return expression.test(String("" + value).toLowerCase());
  }

  getChannels(): Observable<number> {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'apiKey': ' gT4oQrxHPMccXtZzBt5gm6Fn2H2AHnsN',
      'applicationkey': 'ywz04IlfiTEGd8se',
      'supportKey': this.cookie.get('supportKey'),
      'TOKEN': this.cookie.get('token'),
    });

    this.options = {
      headers: this.httpHeaders
    };

    var data = {
      CLOUD_ID: this.cloudID,
      EMPLOYEE_ID: Number(this.cookie.get('userId')),
    }

    return this.httpClient.post<any>(this.url + "employee/submitToken/", JSON.stringify(data), this.options);
  }

  logout(): Observable<number> {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'apiKey': ' gT4oQrxHPMccXtZzBt5gm6Fn2H2AHnsN',
      'applicationkey': 'ywz04IlfiTEGd8se',
      'supportKey': this.cookie.get('supportKey'),
      'TOKEN': this.cookie.get('token'),
    });

    this.options = {
      headers: this.httpHeaders
    };

    var data = {
      EMPLOYEE_ID: Number(this.cookie.get('userId'))
    }

    return this.httpClient.post<any>(this.url + "employee/clearToken/", JSON.stringify(data), this.options);
  }

  getAllnotification(): Observable<number> {
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'apiKey': ' gT4oQrxHPMccXtZzBt5gm6Fn2H2AHnsN',
      'applicationkey': 'ywz04IlfiTEGd8se',
      'supportKey': this.cookie.get('supportKey'),
      'TOKEN': this.cookie.get('token'),
    });

    this.options = {
      headers: this.httpHeaders
    };

    var data = {
      EMPLOYEE_ID: Number(this.cookie.get('userId'))
    }

    return this.httpClient.post<any>(this.url + "employee/getNotifications/", JSON.stringify(data), this.options);
  }

  // Folder (22-06-2022, by: Ashish)
  getAllMyNotications(pageIndex: number, pageSize: number, empId: number, deptId: number, branchId: number, designationId: number, orgId: number): Observable<folderMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      EMPLOYEE_ID: empId,
      DEPT_ID: deptId,
      BRANCH_ID: branchId,
      DESIGNATION_ID: designationId,
      ORG_ID: orgId
    };

    return this.httpClient.post<folderMaster[]>(this.url + "notification/getNotifications", JSON.stringify(data), this.options);
  }

  // Folder (26-05-2022, by: Ashish)
  getAllMyFolders(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<folderMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<folderMaster[]>(this.url + "folder/get", JSON.stringify(data), this.options);
  }

  // Searching Folders and Files (20-06-2022, by: Ashish)
  getAllMyFoldersFilesOnSearchig(empID: number, deptID: number, branchID: number, designationID: number, searchText: string): Observable<folderMaster[]> {
    var data = {
      EMPLOYEE_ID: empID,
      DEPARTMENT_ID: deptID,
      BRANCH_ID: branchID,
      DESIGNATION_ID: designationID,
      SEARCH_TEXT: searchText
    };

    return this.httpClient.post<folderMaster[]>(this.url + "folder/searchFile", JSON.stringify(data), this.options);
  }

  sendNoti(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<folderMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<folderMaster[]>(this.url + "folder/generate", JSON.stringify(data), this.options);
  }

  createNewMyFolder(folder: folderMaster): Observable<number> {
    folder.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "folder/create/", JSON.stringify(folder), this.options);
  }

  updateExistingMyFolder(folder: folderMaster): Observable<number> {
    folder.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "folder/update/", JSON.stringify(folder), this.options);
  }

  // File (26-05-2022, by: Ashish)
  getAllFiles(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<fileMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<fileMaster[]>(this.url + "file/get", JSON.stringify(data), this.options);
  }

  createNewFile(file: fileMaster): Observable<number> {
    file.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "file/create/", JSON.stringify(file), this.options1);
  }

  updateExistingFile(file: fileMaster): Observable<number> {
    file.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "file/update/", JSON.stringify(file), this.options);
  }

  onFileUpload(folderID, file, ownerID, tag, desc) {
    this.httpHeaders1 = new HttpHeaders({
      'supportkey': this.cookie.get('supportKey'),
      'apikey': ' gT4oQrxHPMccXtZzBt5gm6Fn2H2AHnsN',
      'applicationkey': 'ywz04IlfiTEGd8se',
      'Token': this.cookie.get('token'),
    });

    this.options1 = {
      headers: this.httpHeaders1
    };

    const fd = new FormData();
    fd.append("PARENT_ID", folderID);
    fd.append("FOLDER_FILE", 'L');
    fd.append("FILE", file);
    fd.append("OWNER_ID", ownerID);
    fd.append("TAGS", tag);
    fd.append("DESCRIPTION", desc);

    return this.httpClient.post(this.url + 'folder/upload/', fd, this.options1)
  }

  onFileUpload1(folderID, file, ownerID, tag, desc): Observable<HttpEvent<any>> {
    this.httpHeaders1 = new HttpHeaders({
      'supportkey': this.cookie.get('supportKey'),
      'apikey': ' gT4oQrxHPMccXtZzBt5gm6Fn2H2AHnsN',
      'applicationkey': 'ywz04IlfiTEGd8se',
      'Token': this.cookie.get('token'),
    });

    const fd = new FormData();
    fd.append("PARENT_ID", folderID);
    fd.append("FOLDER_FILE", 'L');
    fd.append("FILE", file);
    fd.append("OWNER_ID", ownerID);
    fd.append("TAGS", tag);
    fd.append("DESCRIPTION", desc);

    let params = new HttpParams();

    const options = {
      headers: this.httpHeaders1,
      params: params,
      reportProgress: true,
    };

    const req = new HttpRequest('POST', this.url + 'folder/upload/', fd, options);
    return this.httpClient.request(req);
  }

  // File Download (25-08-2022, by: Ashish)
  downloadFileWithProgress(empId: number, accessType: string, url: string, fileId: number): Observable<HttpEvent<Blob>> {
    var data = {
      EMPLOYEE_ID: empId,
      URL: url,
      ACCESS_TYPE: accessType,
      FOLDER_ID: fileId
    };

    let params = new HttpParams();
    const options = {
      headers: this.httpHeaders1,
      params: params,
      reportProgress: true,
    };

    const req = new HttpRequest('GET', this.url + "folder/downloadFile?EMPLOYEE_ID=" + empId + "&FOLDER_ID=" + fileId, options);
    return this.httpClient.request(req);
  }

  // Folder Sharing (30-05-2022, by: Ashish)
  getAllFolderSharigDetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<folderMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<folderMaster[]>(this.url + "folderSharingDetails/get", JSON.stringify(data), this.options);
  }

  // Folder Sharing (18-06-2022, by: Ashish)
  getSubordinateList(folderId, empid, roleId, orgId): Observable<[]> {
    var data = {
      FOLDER_ID: folderId,
      EMPLOYEE_ID: empid,
      ROLE_ID: roleId,
      ORG_ID: orgId
    };

    return this.httpClient.post<[]>(this.url + "folderSharingDetails/getSubOrdinateExceptShared", JSON.stringify(data), this.options);
  }

  // Folder Sharing (18-06-2022, by: Ashish)
  getSubordinateDepartmentList(folderId, empid, orgId): Observable<[]> {
    var data = {
      FOLDER_ID: folderId,
      EMPLOYEE_ID: empid,
      ORG_ID: orgId,
    };

    return this.httpClient.post<[]>(this.url + "folderSharingDetails/getDepartmentExceptShared", JSON.stringify(data), this.options);
  }

  // Folder Sharing (18-06-2022, by: Ashish)
  getSubordinateBranchList(folderId, empid, orgId): Observable<[]> {
    var data = {
      FOLDER_ID: folderId,
      EMPLOYEE_ID: empid,
      ORG_ID: orgId,
    };

    return this.httpClient.post<[]>(this.url + "folderSharingDetails/getBranchExceptShared", JSON.stringify(data), this.options);
  }

  // Folder Sharing (18-06-2022, by: Ashish)
  getSubordinateDesignationList(folderId, empid, orgId): Observable<[]> {
    var data = {
      FOLDER_ID: folderId,
      EMPLOYEE_ID: empid,
      ORG_ID: orgId,
    };

    return this.httpClient.post<[]>(this.url + "folderSharingDetails/getDesignationExceptShared", JSON.stringify(data), this.options);
  }

  // Send Noti (22-06-2022, by: Ashish)
  getNotiSubordinateList(empid, roleId, orgId): Observable<[]> {
    var data = {
      EMPLOYEE_ID: empid,
      ROLE_ID: roleId,
      ORG_ID: orgId
    };

    return this.httpClient.post<[]>(this.url + "notification/getSubOrdinateExceptNotified", JSON.stringify(data), this.options);
  }

  // Send Noti (22-06-2022, by: Ashish)
  getNotiSubordinateDepartmentList(empid, orgId): Observable<[]> {
    var data = {
      EMPLOYEE_ID: empid,
      ORG_ID: orgId,
    };

    return this.httpClient.post<[]>(this.url + "notification/getDepartmentNotified", JSON.stringify(data), this.options);
  }

  // Send Noti (22-06-2022, by: Ashish)
  getNotiSubordinateBranchList(empid, orgId): Observable<[]> {
    var data = {
      EMPLOYEE_ID: empid,
      ORG_ID: orgId,
    };

    return this.httpClient.post<[]>(this.url + "notification/getBranchNotified", JSON.stringify(data), this.options);
  }

  // Send Noti (22-06-2022, by: Ashish)
  getNotiSubordinateDesignationList(empid, orgId): Observable<[]> {
    var data = {
      EMPLOYEE_ID: empid,
      ORG_ID: orgId,
    };

    return this.httpClient.post<[]>(this.url + "notification/getDesignationNotified", JSON.stringify(data), this.options);
  }

  // Send Noti (22-06-2022, by: Ashish)
  getNotiSubordinateEntireList(orgId): Observable<[]> {
    var data = {
      ORG_ID: orgId
    };

    return this.httpClient.post<[]>(this.url + "notification/getEntireOrganisationNotified", JSON.stringify(data), this.options);
  }

  updateFolderSharingDetails(folder: FolderSharing): Observable<number> {
    folder.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "folderSharingDetails/update/", JSON.stringify(folder), this.options);
  }

  removeFolderSharingDetails(folder: FolderSharing): Observable<number> {
    var data = {
      ID: folder.ID,
    };
    return this.httpClient.post<number>(this.url + "folderSharingDetails/removeAccess/", JSON.stringify(data), this.options);
  }

  // Folder Sharing (10-06-2022, by: Ashish)
  folderSharigDetailsAddBulk(folderID: number, sharingType: any, empID: number, empData: any): Observable<[]> {
    for (var i = 0; i < empData.length; i++) {
      empData[i].CLIENT_ID = this.clientId;
    }

    var data = {
      FOLDER_ID: folderID,
      SHARING_TYPE: sharingType,
      EMPLOYEE_ID: empID,
      data: empData,
      IS_PANEL: 0
    };

    return this.httpClient.post<[]>(this.url + "folderSharingDetails/addBulk", JSON.stringify(data), this.options);
  }

  // Notification Sharing (22-06-2022, by: Ashish)
  notiDetailsAddBulk(empID: number, title: string, desc: string, sharingType: number, nData: any, orgId: number, imageUrl: string, notiType: string): Observable<[]> {
    for (var i = 0; i < nData.length; i++) {
      nData[i].CLIENT_ID = this.clientId;
    }

    var data = {
      TITLE: title,
      DESCRIPTION: desc,
      data: nData,
      SHARING_TYPE: sharingType,
      EMPLOYEE_ID: empID,
      ORG_ID: orgId,
      IS_PANEL: 1,
      ATTACHMENT: imageUrl,
      TYPE: notiType
    };

    return this.httpClient.post<[]>(this.url + "notification/sendNotification", JSON.stringify(data), this.options);
  }

  createFolderSharigDetails(folder: folderMaster): Observable<number> {
    folder.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "folderSharingDetails/create/", JSON.stringify(folder), this.options1);
  }

  updateExistingFolderSharigDetails(folder: folderMaster): Observable<number> {
    folder.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "folderSharingDetails/update/", JSON.stringify(folder), this.options);
  }

  // File Sharing (30-05-2022, by: Ashish)
  getAllFileSharigDetails(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<fileMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<fileMaster[]>(this.url + "fileSharingDetails/get", JSON.stringify(data), this.options);
  }

  // Get Shared Folder(s) Details (19-06-2022, by: Ashish)
  getAllSharedFolderDetails(pageIndex: number, pageSize: number, folderID: number, empID: number, orgID: number, branchID: number, deptID: number, designationID: number, accessType: string): Observable<fileMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      FOLDER_ID: folderID,
      EMPLOYEE_ID: empID,
      ORG_ID: orgID,
      BRANCH_ID: branchID,
      DEPT_ID: deptID,
      DESIGNATION_ID: designationID,
      ACCESS_TYPE: accessType
    };

    return this.httpClient.post<fileMaster[]>(this.url + "folderSharingDetails/getSharedFolders", JSON.stringify(data), this.options);
  }

  // File Sharing (10-06-2022, by: Ashish)
  fileSharigDetailsAddBulk(fileID: number, fileData: any): Observable<[]> {
    fileData.CLIENT_ID = this.clientId;

    var data = {
      FILE_ID: fileID,
      data: [fileData]
    };

    return this.httpClient.post<[]>(this.url + "fileSharingDetails/addBulk", JSON.stringify(data), this.options);
  }

  // File Download (23-06-2022, by: Ashish)
  downloadFile(empId: number, accessType: string, url: string, fileId: number): Observable<[]> {
    var data = {
      EMPLOYEE_ID: empId,
      URL: url,
      ACCESS_TYPE: accessType,
      ID: fileId
    };

    return this.httpClient.post<[]>(this.url + "folder/downloadFile", JSON.stringify(data), this.options);
  }

  // File Download (23-06-2022, by: Ashish)
  downloadFile1(empId: number, accessType: string, url: string, fileId: number): Observable<[]> {
    var data = {
      EMPLOYEE_ID: empId,
      URL: url,
      ACCESS_TYPE: accessType,
      FOLDER_ID: fileId
    };

    return this.httpClient.get<[]>(this.url + "folder/downloadFile?EMPLOYEE_ID=" + empId + "&FOLDER_ID=" + fileId, this.options);
  }

  createFileSharigDetails(file: fileMaster): Observable<number> {
    file.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "fileSharingDetails/create/", JSON.stringify(file), this.options1);
  }

  updateExistingFileSharigDetails(file: fileMaster): Observable<number> {
    file.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "fileSharingDetails/update/", JSON.stringify(file), this.options);
  }

  // Text box Validation
  checkTextBoxIsValid(value: any) {
    const expression = /^[A-Za-z0-9 ]*$/;
    return expression.test(String("" + value).toLowerCase());
  }

  transferTicket(ticket: Ticket): Observable<number> {
    ticket.CLIENT_ID = this.clientId;
    ticket['KEY'] = ticket['KEY'] != undefined && ticket['KEY'] == 'USER' ? 'USER' : 'SUPPORT_USER';
    return this.httpClient.put<number>(this.url + "ticket/update/", JSON.stringify(ticket), this.options);
  }

  // Text box Validation
  checkTextBoxIsValidWithComma(value: any) {
    const expression = /^[A-Za-z0-9, ]*$/;
    return expression.test(String("" + value).toLowerCase());
  }

  getTicketGroupParent(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<any[]>(this.url + "ticketGroup/getParent", JSON.stringify(data), this.options);
  }

  getOptionwiseReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, APPLICATION_KEY, DEPARTMENT): Observable<Ticket[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      ORG_ID: this.cookie.get('orgId'),
      DEPARTMENT_ID: DEPARTMENT
    };

    return this.httpClient.post<Ticket[]>(this.url + "ticket/getOptionWiseCount", JSON.stringify(data), this.options);
  }

  getBreadInChat(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, DEPARTMENT, filter: string, TICKET_GROUP_ID: any): Observable<Ticket[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      TICKET_GROUP_ID: TICKET_GROUP_ID,
      filter: filter,
      ORG_ID: sessionStorage.getItem('orgId'),
    };

    return this.httpClient.post<Ticket[]>(this.url + "ticketGroup/getParent", JSON.stringify(data), this.options);
  }

  getcountData(fromDate: any, toDate: any, orgId: number) {
    var data = {
      FROM_DATE: fromDate,
      TO_DATE: toDate,
      ORG_ID: orgId
    };

    return this.httpClient.post<[]>(this.url + "scheduler/sendEmployeeReport", JSON.stringify(data), this.options);
  }

  sendEmail(fromDate: any, toDate: any, orgId: number) {
    var data = {
      FROM_DATE: fromDate,
      TO_DATE: toDate,
      ORG_ID: orgId
    };

    return this.httpClient.post<[]>(this.url + "scheduler/sendEmail", JSON.stringify(data), this.options);
  }

  // EMM Component's Services
  getAllLeaveType(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<LeaveType[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<LeaveType[]>(this.url + "leaveType/get", JSON.stringify(data), this.options);
  }

  createLeaveType(leavetype: LeaveType): Observable<number> {
    leavetype.ORGANIZATION_ID = this.orgId;
    leavetype.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "leaveType/create", JSON.stringify(leavetype), this.options);
  }

  updateLeaveType(leavetype: LeaveType): Observable<number> {
    leavetype.ORGANIZATION_ID = this.orgId;
    leavetype.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "leaveType/update", JSON.stringify(leavetype), this.options);
  }

  getAllEmployeeLeave(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Leave[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<Leave[]>(this.url + "leave/get", JSON.stringify(data), this.options);
  }

  createLeave(leave: Leave): Observable<number> {
    leave.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "leave/add", JSON.stringify(leave), this.options);
  }

  updateLeave(leave: Leave): Observable<number> {
    leave.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "leave/update", JSON.stringify(leave), this.options);
  }

  getAllExpenseHead(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<ExpensesHeadMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<ExpensesHeadMaster[]>(this.url + "expensehead/get", JSON.stringify(data), this.options);
  }

  createExpenseHead(expenseHead: ExpensesHeadMaster): Observable<number> {
    expenseHead.ORGANIZATION_ID = +this.orgId;
    expenseHead.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "expensehead/create/", JSON.stringify(expenseHead), this.options);
  }

  updateExpenseHead(expenseHead: ExpensesHeadMaster): Observable<number> {
    expenseHead.ORGANIZATION_ID = +this.orgId;
    expenseHead.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "expensehead/update/", JSON.stringify(expenseHead), this.options);
  }

  getAllEmployeeExpenses(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Expenses[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<Expenses[]>(this.url + "expenseTransaction/get", JSON.stringify(data), this.options);
  }

  getAllEmployeeSplitDetails(transID: any): Observable<[]> {
    var data = {
      EXPENSE_TRANSACTION_ID: transID
    }

    return this.httpClient.post<[]>(this.url + "expenseSplitBillDetails/get", JSON.stringify(data), this.options);
  }

  createEmployeeExpenses(expenses: Expenses): Observable<number> {
    expenses.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "expenseTransaction/add", JSON.stringify(expenses), this.options);
  }

  updateEmployeeExpenses(expenses: Expenses): Observable<number> {
    expenses.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "expenseTransaction/update", JSON.stringify(expenses), this.options);
  }

  getAllEvaluationCriteria(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<EvaluationCriateria[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<EvaluationCriateria[]>(this.url + "evaluationCriteria/get", JSON.stringify(data), this.options);
  }

  createEvaluationCriteria(evaluationCriteria: EvaluationCriateria): Observable<number> {
    evaluationCriteria.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "evaluationCriteria/create", JSON.stringify(evaluationCriteria), this.options);
  }

  updateEvaluationCriteria(evaluationCriteria: EvaluationCriateria): Observable<number> {
    evaluationCriteria.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "evaluationCriteria/update", JSON.stringify(evaluationCriteria), this.options);
  }

  getAllEmployeesSplitBill(orgID: number, empID: number, designationID: number): Observable<[]> {
    var data = {
      ORG_ID: orgID,
      EMPLOYEE_ID: empID,
      DESIGNATION_ID: designationID
    };

    return this.httpClient.post<[]>(this.url + "employee/getEmployeeList", JSON.stringify(data), this.options);
  }

  getAllCustomers(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<CustomerMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<CustomerMaster[]>(this.url + "customer/get", JSON.stringify(data), this.options);
  }

  createCustomer(customer: CustomerMaster): Observable<number> {
    customer.CLIENT_ID = this.clientId;
    customer.ORGANIZATION_ID = this.orgId;

    return this.httpClient.post<number>(this.url + "customer/create", JSON.stringify(customer), this.options);
  }

  updateCustomer(customer: CustomerMaster): Observable<number> {
    customer.CLIENT_ID = this.clientId;
    customer.ORGANIZATION_ID = this.orgId;

    return this.httpClient.put<number>(this.url + "customer/update", JSON.stringify(customer), this.options);
  }

  getAllHolidays(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<HolidayMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<HolidayMaster[]>(this.url + "holiday/get", JSON.stringify(data), this.options);
  }

  createHoliday(holiday: HolidayMaster): Observable<number> {
    return this.httpClient.post<number>(this.url + "holiday/create/", JSON.stringify(holiday), this.options);
  }

  updateHoliday(holiday: HolidayMaster): Observable<number> {
    return this.httpClient.put<number>(this.url + "holiday/update/", JSON.stringify(holiday), this.options);
  }

  getAllThough(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<ThoughMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<ThoughMaster[]>(this.url + "thoughts/get", JSON.stringify(data), this.options);
  }

  createThough(though: ThoughMaster): Observable<number> {
    though.CLIENT_ID = this.clientId;
    though.TYPE = "";
    return this.httpClient.post<number>(this.url + "thoughts/create/", JSON.stringify(though), this.options);
  }

  updateThough(though: ThoughMaster): Observable<number> {
    though.TYPE = "";
    though.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "thoughts/update/", JSON.stringify(though), this.options);
  }

  getAllBirthday(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<BirthdayMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<BirthdayMaster[]>(this.url + "birthday/get", JSON.stringify(data), this.options);
  }

  createBirthday(birthday: BirthdayMaster): Observable<number> {
    birthday.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "birthday/create", JSON.stringify(birthday), this.options);
  }

  updateBirthday(birthday: BirthdayMaster): Observable<number> {
    birthday.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "birthday/update", JSON.stringify(birthday), this.options);
  }

  getAllLateMarks(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Latemark[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<Latemark[]>(this.url + "lateMark/get", JSON.stringify(data), this.options);
  }

  createlatemark(latemark: Latemark): Observable<number> {
    latemark.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "lateMark/add", JSON.stringify(latemark), this.options);
  }

  updatelatemark(latemark: Latemark): Observable<number> {
    latemark.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "lateMark/update", JSON.stringify(latemark), this.options);
  }

  getAllEarlyMarks(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Earlymark[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<Earlymark[]>(this.url + "earlyMark/get", JSON.stringify(data), this.options);
  }

  createEarlymark(earlymark: Earlymark): Observable<number> {
    earlymark.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "earlyMark/add", JSON.stringify(earlymark), this.options);
  }

  updateEarlymark(earlymark: Earlymark): Observable<number> {
    earlymark.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "earlyMark/update", JSON.stringify(earlymark), this.options);
  }

  getAllEmployeeExpenseHead(employeeId: number) {
    var data = {
      EMPLOYEE_ID: employeeId
    };

    return this.httpClient.post<Expensedetails[]>(this.url + "employeeExpenseHeadMapping/get", JSON.stringify(data), this.options);
  }

  addEmployeeExpenseHeadDetails(employeeId: number, data1: string[]): Observable<number> {
    console.log(employeeId)
    var data = {
      EMPLOYEE_ID: employeeId,
      data: data1,
    };

    return this.httpClient.post<number>(this.url + "employeeExpenseHeadMapping/addBulk", data, this.options);
  }

  getAllEmployeeColleges(employeeId: number) {
    var data = {
      EMPLOYEE_ID: employeeId
    };

    return this.httpClient.post<Employeecollegedetails[]>(this.url + "employeeCollegeMapping/get", JSON.stringify(data), this.options);
  }

  addEmployeeCollegeDetails(employeeId: number, data1: string[]): Observable<number> {
    var data = {
      EMPLOYEE_ID: employeeId,
      data: data1,
    };

    return this.httpClient.post<number>(this.url + "employeeCollegeMapping/addBulk", data, this.options);
  }

  getAttendanceRegister(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Attendance[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<Attendance[]>(this.url + "employeeAttendanceReport/get", JSON.stringify(data), this.options);
  }

  createAttendance(attendance: Attendance): Observable<number> {
    attendance.CLIENT_ID = this.clientId;
    console.log(attendance);
    return this.httpClient.post<number>(this.url + "attendance/create", JSON.stringify(attendance), this.options);
  }

  updateAttendance(attendance: Attendance): Observable<number> {
    attendance.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "attendance/update/", JSON.stringify(attendance), this.options);
  }

  getAttendanceReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, status: string, startDate: string,
    enddate: string): Observable<AttendanceReport[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      STATUS: status,
      START_DATE: startDate,
      END_DATE: enddate,
    };

    return this.httpClient.post<AttendanceReport[]>(this.url + "employeeAttendanceReport/get", JSON.stringify(data), this.options);
  }

  getAllEmployees(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<EmployeeMaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<EmployeeMaster[]>(this.url + "employee/get", JSON.stringify(data), this.options);
  }

  getLeaveReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<LeaveReport[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<LeaveReport[]>(this.url + "employeeLeaveReport/get", JSON.stringify(data), this.options);
  }

  getLatemarkReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<[]>(this.url + "employeeLatemarkReport/get", JSON.stringify(data), this.options);
  }

  getEarlymarkReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Earlymarkreport[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<Earlymarkreport[]>(this.url + "employeeEarlymarkReport/get", JSON.stringify(data), this.options);
  }

  getExpenseReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<ExpenseReport[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<ExpenseReport[]>(this.url + "employeeExpensesReport/get", JSON.stringify(data), this.options);
  }

  getEvaluationReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<EvaluationReport[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<EvaluationReport[]>(this.url + "employeeEvaluationReport/get", JSON.stringify(data), this.options);
  }

  getAllAssetCategory(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<AssetCategory[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<AssetCategory[]>(this.url + "assetCategory/get", JSON.stringify(data), this.options);
  }

  createAssetCategory(category: AssetCategory): Observable<number> {
    category.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "assetCategory/create", JSON.stringify(category), this.options);
  }

  updateAssetCategory(category: AssetCategory): Observable<number> {
    category.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "assetCategory/update", JSON.stringify(category), this.options);
  }

  getAllAssets(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Asset[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<Asset[]>(this.url + "asset/get", JSON.stringify(data), this.options);
  }

  createAssets(asset: Asset): Observable<number> {
    asset.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "asset/create", JSON.stringify(asset), this.options);
  }

  updateAssets(asset: Asset): Observable<number> {
    asset.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "asset/update", JSON.stringify(asset), this.options);
  }

  getAllAssetMapping(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<AssetMapping[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<AssetMapping[]>(this.url + "assetEmployeeMapping/get", JSON.stringify(data), this.options);
  }

  createAssetMapping(assetMapping: AssetMapping): Observable<number> {
    assetMapping.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "asset/create", JSON.stringify(assetMapping), this.options);
  }

  updateAssetMapping(assetMapping: AssetMapping): Observable<number> {
    assetMapping.CLIENT_ID = this.clientId;
    return this.httpClient.put<number>(this.url + "asset/update", JSON.stringify(assetMapping), this.options);
  }

  addAssetRequest(assetRequest: AssetRequest): Observable<number> {
    assetRequest.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "assetRequest/assetRequests", JSON.stringify(assetRequest), this.options);
  }

  getAllRequestedAsset(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, empID: number): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      EMPLOYEE_ID: empID,
    };

    return this.httpClient.post<[]>(this.url + "assetRequest/getRequestedAssets", JSON.stringify(data), this.options);
  }

  getAllAllocatedAsset(empID: number): Observable<[]> {
    var data = {
      EMPLOYEE_ID: empID,
    };

    return this.httpClient.post<[]>(this.url + "assetRequest/getAllocatedAssets", JSON.stringify(data), this.options);
  }

  getAllReAllocatedAsset(empID: number): Observable<[]> {
    var data = {
      NEW_EMPLOYEE_ID: empID,
    };

    return this.httpClient.post<[]>(this.url + "assetRequest/getReallocatedAssets", JSON.stringify(data), this.options);
  }

  allocatingAsset(assetMapping: AssetMapping): Observable<number> {
    assetMapping.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "assetRequest/updateAssetApproval", JSON.stringify(assetMapping), this.options);
  }

  allocatingAssetWithoutRequest(data: any): Observable<number> {
    data.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "asset/allocateAssetsWithoutRequest", JSON.stringify(data), this.options);
  }

  doAssetMaintenance(data: any): Observable<number> {
    data.CLIENT_ID = this.clientId;
    return this.httpClient.post<number>(this.url + "assetMaintenanceRegister/updateAssetRegister", JSON.stringify(data), this.options);
  }

  getEmployeeAttendanceRegister(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Attendanceregister[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<Attendanceregister[]>(this.url + "attendanceRegister/get", JSON.stringify(data), this.options);
  }

  getExpenseSummeryReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<ExpenseSummeryReport[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<ExpenseSummeryReport[]>(this.url + "expensesSummaryReport/get", JSON.stringify(data), this.options);
  }

  updateAttendanceRegister(attendanceRegister: UpdateAttendanceRegister): Observable<number> {
    return this.httpClient.put<number>(this.url + "attendance/updateStatus", JSON.stringify(attendanceRegister), this.options);
  }

  // Training Module
  getAlltrainingSubject(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<any>(this.url + "trainingSubject/get", JSON.stringify(data), this.options);
  }

  createtrainingSubject(role: any): Observable<any> {
    role.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(this.url + "trainingSubject/create/", JSON.stringify(role), this.options);
  }

  updatetrainingSubject(role: any): Observable<any> {
    role.CLIENT_ID = this.clientId;
    return this.httpClient.put<any>(this.url + "trainingSubject/update/", JSON.stringify(role), this.options);
  }

  addBulktrainingPointsMapping(SUBJECT_MASTER_ID: number, datas: any): Observable<any> {
    var data = {
      SUBJECT_MASTER_ID: SUBJECT_MASTER_ID,
      data: datas
    };

    return this.httpClient.post<any>(this.url + "trainingPointsMapping/addBulk", JSON.stringify(data), this.options);
  }

  trainingPointsMapping(SUBJECT_MASTER_ID: number): Observable<any> {
    var data = {
      SUBJECT_MASTER_ID: SUBJECT_MASTER_ID
    };

    return this.httpClient.post<any>(this.url + "trainingPointsMapping/getData", JSON.stringify(data), this.options);
  }

  getAlltrainer(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<any>(this.url + "trainer/get", JSON.stringify(data), this.options);
  }

  createtrainer(role: any): Observable<any> {
    role.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(this.url + "trainer/create/", JSON.stringify(role), this.options);
  }

  updatetrainer(role: any): Observable<any> {
    return this.httpClient.put<any>(this.url + "trainer/update/", JSON.stringify(role), this.options);
  }

  getAlltrainingType(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<any>(this.url + "trainingType/get", JSON.stringify(data), this.options);
  }

  createtrainingType(role: any): Observable<any> {
    role.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(this.url + "trainingType/create/", JSON.stringify(role), this.options);
  }

  updatetrainingType(role: any): Observable<any> {
    return this.httpClient.put<any>(this.url + "trainingType/update/", JSON.stringify(role), this.options);
  }

  getAllannualTraniningPoints(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<any>(this.url + "annualTraniningPoints/get", JSON.stringify(data), this.options);
  }

  createannualTraniningPoints(role: any): Observable<any> {
    role.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(this.url + "annualTraniningPoints/create/", JSON.stringify(role), this.options);
  }

  updateannualTraniningPoints(role: any): Observable<any> {
    return this.httpClient.put<any>(this.url + "annualTraniningPoints/update/", JSON.stringify(role), this.options);
  }

  addBulktrainingTrainerMapping(SCHEDULE_ID: number, datas: any): Observable<any> {
    var data = {
      SCHEDULE_ID: SCHEDULE_ID,
      data: datas
    };

    return this.httpClient.post<any>(this.url + "trainingTrainerMapping/addBulk", JSON.stringify(data), this.options);
  }

  getAlltrainingPoints(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<any>(this.url + "trainingPoints/get", JSON.stringify(data), this.options);
  }

  createtrainingPoints(role: any): Observable<any> {
    role.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(this.url + "trainingPoints/create/", JSON.stringify(role), this.options);
  }

  updatetrainingPoints(role: any): Observable<any> {
    return this.httpClient.put<any>(this.url + "trainingPoints/update/", JSON.stringify(role), this.options);
  }

  getAlltrainingSchedule(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<any>(this.url + "trainingSchedule/get", JSON.stringify(data), this.options);
  }

  trainingAttendiedMapping(SCHEDULE_ID: number, a: number, b: number): Observable<any> {
    var data = {
      SCHEDULE_ID: SCHEDULE_ID,
      DEPARTMENT_ID: a,
      DESIGNATION_ID: b
    };

    return this.httpClient.post<any>(this.url + "trainingAttendiedMapping/getData", JSON.stringify(data), this.options);
  }

  getAlltrainingAttendiedMapping(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<any>(this.url + "trainingAttendiedMapping/get", JSON.stringify(data), this.options);
  }

  createtrainingSchedule(role: any): Observable<any> {
    role.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(this.url + "trainingSchedule/create/", JSON.stringify(role), this.options);
  }

  updatetrainingSchedule(role: any): Observable<any> {
    return this.httpClient.put<any>(this.url + "trainingSchedule/update/", JSON.stringify(role), this.options);
  }

  addBulktrainingAttendiedMapping(SCHEDULE_ID: number, datas: any): Observable<any> {
    var data = {
      SCHEDULE_ID: SCHEDULE_ID,
      data: datas
    };

    return this.httpClient.post<any>(this.url + "trainingAttendiedMapping/addBulk", JSON.stringify(data), this.options);
  }

  getAllinductionArea(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<any>(this.url + "inductionArea/get", JSON.stringify(data), this.options);
  }

  createinductionArea(role: any): Observable<any> {
    role.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(this.url + "inductionArea/create/", JSON.stringify(role), this.options);
  }

  updateinductionArea(role: any): Observable<any> {
    return this.httpClient.put<any>(this.url + "inductionArea/update/", JSON.stringify(role), this.options);
  }

  getAlltrainingTrainerMapping(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<any>(this.url + "trainingTrainerMapping/get", JSON.stringify(data), this.options);
  }

  trainingTrainerMapping(SCHEDULE_ID: number): Observable<any> {
    var data = {
      SCHEDULE_ID: SCHEDULE_ID
    };

    return this.httpClient.post<any>(this.url + "trainingTrainerMapping/getData", JSON.stringify(data), this.options);
  }

  updatetrainingAttendiedMapping(data: any, reason: string, scheduleId: number): Observable<any> {
    var datas = {
      data: data,
      REASON: reason,
      SCHEDULE_ID: scheduleId
    }

    return this.httpClient.post<any>(this.url + "trainingAttendiedMapping/updateStatus/", JSON.stringify(datas), this.options);
  }

  clearDeviceID(empID: number): Observable<any> {
    var data = {
      ID: empID
    }

    return this.httpClient.post<any>(this.url + "employee/clearDeviceID/", JSON.stringify(data), this.options);
  }

  // Password Validation(Alphanumeric)
  passwordIsValid(value: any) {
    const expression = /^[A-Za-z0-9@#]*$/;
    return expression.test(String("" + value).toLowerCase());
  }

  updateGlobalSettingInfo(apkInfo: any): Observable<any> {
    apkInfo.CLIENT_ID = this.clientId;
    return this.httpClient.put<any>(this.baseUrl + "globalSettings/updatedVersion/", JSON.stringify(apkInfo), this.options);
  }

  getAssetMaintenance(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Asset[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<Asset[]>(this.url + "assetMaintenanceRegister/get", JSON.stringify(data), this.options);
  }

  getCheckAccessOfForm(roleId: number, link: string) {
    var data = {
      ROLE_ID: roleId,
      LINK: link
    };

    return this.httpClient.post<Roledetails>(this.url + "roleDetails/checkAccess", JSON.stringify(data), this.options);
  }

  getEmployeeOfTheMonth(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<[]>(this.baseUrl + "globalSettings/getEmployeeOfTheMonth/", JSON.stringify(data), this.options);
  }

  setEmployeeOfTheMonth(EOM_Info: any): Observable<any> {
    EOM_Info.CLIENT_ID = this.clientId;
    return this.httpClient.put<any>(this.baseUrl + "globalSettings/setEmployeeOfTheMonth/", JSON.stringify(EOM_Info), this.options);
  }

  getAllPayrollItem(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<any>(this.url + "inductionArea/get", JSON.stringify(data), this.options);
  }

  createPayrollItem(role: any): Observable<any> {
    role.CLIENT_ID = this.clientId;
    return this.httpClient.post<any>(this.url + "inductionArea/create/", JSON.stringify(role), this.options);
  }

  updatePayrollItem(role: any): Observable<any> {
    return this.httpClient.put<any>(this.url + "inductionArea/update/", JSON.stringify(role), this.options);
  }

  getAllParent(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<any>(this.url + "inductionArea/get", JSON.stringify(data), this.options);
  }

  getLeaveData(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter

    };

    return this.httpClient.post<any[]>(this.baseUrl + "api/payrollLeaveMaster/get", JSON.stringify(data), this.options);

  }

  createData(leaveMaster: leaveMaster) {
    leaveMaster.CLIENT_ID = this.clientId;

    if (leaveMaster.APPLICABLE_TYPES.toString() != undefined) {
      leaveMaster.APPLICABLE_TYPES = leaveMaster.APPLICABLE_TYPES.toString();

    }
    // console.log(leaveMaster.APPLICABLE_TYPES);
    if (leaveMaster.CLUBBED_WITH_NOT_POSSIBLE != undefined) {
      leaveMaster.CLUBBED_WITH_NOT_POSSIBLE = leaveMaster.CLUBBED_WITH_NOT_POSSIBLE.toString();

    }
    // console.log(leaveMaster.CLUBBED_WITH_NOT_POSSIBLE);
    return this.httpClient.post<any[]>(this.baseUrl + "api/payrollLeaveMaster/create", JSON.stringify(leaveMaster),
      this.options);
  }

  updateData(leaveMaster: leaveMaster) {

    leaveMaster.APPLICABLE_TYPES = leaveMaster.APPLICABLE_TYPES.toString();
    // console.log(leaveMaster.APPLICABLE_TYPES);

    leaveMaster.CLUBBED_WITH_NOT_POSSIBLE = (leaveMaster.CLUBBED_WITH_NOT_POSSIBLE).toString();
    // console.log(leaveMaster.CLUBBED_WITH_NOT_POSSIBLE);
    return this.httpClient.put<any[]>(this.baseUrl + "api/payrollLeaveMaster/update", JSON.stringify(leaveMaster),
      this.options);
  }

  getApplicationReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<Ticket[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };
    this.httpHeaders1 = new HttpHeaders({
      'Content-Type': 'application/json',
      'apikey': ' gT4oQrxHPMccXtZzBt5gm6Fn2H2AHnsN',
      'applicationkey': 'ywz04IlfiTEGd8se',
      'Token': this.cookie.get('token'),
    });
    this.options = { headers: this.httpHeaders1 }; return this.httpClient.post<any[]>(this.url + "careerRegistrationDetails/get", JSON.stringify(data), this.options);
  }

  // leave calculation module



  updateLeaveBalance(dataaaa): Observable<any> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "gT4oQrxHPMccXtZzBt5gm6Fn2H2AHnsN",
      applicationkey: "ywz04IlfiTEGd8se",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });

    this.options = {
      headers: this.httpHeaders,
    };

    return this.httpClient.post<any>(this.baseUrl + 'api/leaveBalanceAdjustmentDetails/updateLeaveBalance', JSON.stringify(dataaaa), this.options)
  }

  getLeaveMap(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, LIKE_STRING: string,
    BRANCH_ID: any, EMPLOYEE_ID: any, DEPARTMENT_ID: any, DESIGNATION_ID: any, FROM_DATE: any, TO_DATE: any): Observable<any[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      LIKE_STRING: LIKE_STRING,
      BRANCH_ID: BRANCH_ID.toString(),
      EMPLOYEE_ID: EMPLOYEE_ID.toString(),
      DEPARTMENT_ID: DEPARTMENT_ID,
      DESIGNATION_ID: DESIGNATION_ID,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE
    };
    return this.httpClient.post<any[]>(this.url + "employeeLeaveMapping/get", JSON.stringify(data), this.options);
  }

  getLeaveTransaction(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, LIKE_STRING: string,
    BRANCH_ID: any, EMPLOYEE_ID: any, DEPARTMENT_ID: any, DESIGNATION_ID: any, FROM_DATE: any, TO_DATE: any): Observable<any[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      LIKE_STRING: LIKE_STRING,
      BRANCH_ID: BRANCH_ID.toString(),
      EMPLOYEE_ID: EMPLOYEE_ID.toString(),
      DEPARTMENT_ID: DEPARTMENT_ID,
      DESIGNATION_ID: DESIGNATION_ID,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE

    };
    return this.httpClient.post<any[]>(this.url + "employeeLeaveTransactions/get", JSON.stringify(data), this.options);
  }

  getLeaveConfiguration(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<leaveYearConfiguration[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };
    return this.httpClient.post<leaveYearConfiguration[]>(this.url + "leaveYearConfiguration/get", JSON.stringify(data), this.options);
  }
  createLeaveConfiguration(leaveYearConfiguration: leaveYearConfiguration) {
    // areaMaster.CLIENT_ID = this.clientId;
    return this.httpClient.post<any[]>(this.baseUrl + "api/leaveYearConfiguration/create", JSON.stringify(leaveYearConfiguration), this.options);
  }

  updateLeaveConfiguration(leaveYearConfiguration: leaveYearConfiguration) {
    // areaMaster.CLIENT_ID = this.clientId;
    return this.httpClient.put<any[]>(this.baseUrl + "api/leaveYearConfiguration/update", JSON.stringify(leaveYearConfiguration), this.options);
  }

  addbulkpolicymap(data1): Observable<number> {
    var data = { ...data1, };
    return this.httpClient.post<number>(this.url + "employeeLeavePolicyMapping/addBulk", data, this.options);
  }
  getselectedemp(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<any>(this.url + "employeeLeavePolicyMapping/get", JSON.stringify(data), this.options);
  }

  getMappedPolicy(EMPLOYEE_ID: number): Observable<any> {
    var data = {
      EMPLOYEE_ID: EMPLOYEE_ID
    }
    return this.httpClient.post<any>(this.url + "employeeLeavePolicyMapping/getMappingData", JSON.stringify(data), this.options)
  }

  deletMappedPolicy(ID: any, USER_ID: any): Observable<any> {
    var data = {
      ID: ID,
      USER_ID: USER_ID
    }
    return this.httpClient.post(this.url + "employeeLeavePolicyMapping/deletePolicy", JSON.stringify(data), this.options)
  }
  getAllleavepolicy(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };

    return this.httpClient.post<any>(this.url + "leavePolicy/get", JSON.stringify(data), this.options);
  }


  getLeaveAdjustmentMappingData(BRANCH_IDS: number, DEPARTMENT_IDS: number, DESIGNATION_IDS: number): Observable<any> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "gT4oQrxHPMccXtZzBt5gm6Fn2H2AHnsN",
      applicationkey: "ywz04IlfiTEGd8se",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });

    this.options = {
      headers: this.httpHeaders,
    };
    var data = {
      BRANCH_IDS: BRANCH_IDS,
      DEPARTMENT_IDS: DEPARTMENT_IDS,
      DESIGNATION_IDS: DESIGNATION_IDS
    }
    return this.httpClient.post<any>(this.baseUrl + '/api/leaveBalanceAdjustmentDetails/getMappingData', JSON.stringify(data), this.options)
  }
  getAdjustmentData(pageIndex: number, pageSize: number, sortKey: any, sortValue: any, filter: any): Observable<any> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "gT4oQrxHPMccXtZzBt5gm6Fn2H2AHnsN",
      applicationkey: "ywz04IlfiTEGd8se",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });

    this.options = {
      headers: this.httpHeaders,
    };
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<any>(this.baseUrl + 'api/leaveBalanceAdjustment/get', JSON.stringify(data), this.options)
  }



  createadjustment(userid, date, remark): Observable<any> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "gT4oQrxHPMccXtZzBt5gm6Fn2H2AHnsN",
      applicationkey: "ywz04IlfiTEGd8se",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });

    this.options = {
      headers: this.httpHeaders,
    };
    var data = {
      USER_ID: userid,
      DATE: date,
      REMARK: remark,

    };
    return this.httpClient.post<leavePolicy>(this.baseUrl + 'api/leaveBalanceAdjustment/create', JSON.stringify(data), this.options)

  }

  getLeaveadjustmentDetails(pageIndex: number, pageSize: number, sortKey: any, sortValue: any, filter: any): Observable<any> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "gT4oQrxHPMccXtZzBt5gm6Fn2H2AHnsN",
      applicationkey: "ywz04IlfiTEGd8se",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });

    this.options = {
      headers: this.httpHeaders,
    };
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<any>(this.baseUrl + 'api/leaveBalanceAdjustmentDetails/get', JSON.stringify(data), this.options)
  }

  leaveAdjustmentaddbulk(data11): Observable<any> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "gT4oQrxHPMccXtZzBt5gm6Fn2H2AHnsN",
      applicationkey: "ywz04IlfiTEGd8se",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });

    this.options = {
      headers: this.httpHeaders,
    };

    var data = {
      data: data11
    };

    return this.httpClient.post<any>(this.baseUrl + 'api/leaveBalanceAdjustmentDetails/addBulk', JSON.stringify(data), this.options)
  }

  updateleaveAdjustment(userid, date, remark, ide, IS_COMPLETED): Observable<any> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "gT4oQrxHPMccXtZzBt5gm6Fn2H2AHnsN",
      applicationkey: "ywz04IlfiTEGd8se",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });

    this.options = {
      headers: this.httpHeaders,
    };
    var data = {
      USER_ID: ide,
      DATE: date,
      REMARK: remark,
      ID: userid,
      IS_COMPLETED: IS_COMPLETED

    };
    return this.httpClient.put<leavePolicy>(this.baseUrl + 'api/leaveBalanceAdjustment/update', JSON.stringify(data), this.options)
  }



  getleavePolicy(pageIndex: number, pageSize: number, sortKey: any, sortValue: any, filter: any): Observable<any> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "gT4oQrxHPMccXtZzBt5gm6Fn2H2AHnsN",
      applicationkey: "ywz04IlfiTEGd8se",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });

    this.options = {
      headers: this.httpHeaders,
    };
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter
    };
    return this.httpClient.post<any>(this.baseUrl + 'api/leavePolicy/get', JSON.stringify(data), this.options)
  }

  createleavePolicy(data: leavePolicy): Observable<any> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "gT4oQrxHPMccXtZzBt5gm6Fn2H2AHnsN",
      applicationkey: "ywz04IlfiTEGd8se",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });

    this.options = {
      headers: this.httpHeaders,
    };
    return this.httpClient.post<leavePolicy>(this.baseUrl + 'api/leavePolicy/create', JSON.stringify(data), this.options)

  }
  updateleavePolicy(data: leavePolicy): Observable<any> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "gT4oQrxHPMccXtZzBt5gm6Fn2H2AHnsN",
      applicationkey: "ywz04IlfiTEGd8se",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });

    this.options = {
      headers: this.httpHeaders,
    };
    return this.httpClient.put<leavePolicy>(this.baseUrl + 'api/leavePolicy/update', JSON.stringify(data), this.options)
  }
  getEmployeeLeave(pageIndex: number, pageSize: number, sortKey: any, sortValue: any, filter: any, LEAVE_POLICY_ID: any): Observable<any> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      LEAVE_POLICY_ID: LEAVE_POLICY_ID

    };
    return this.httpClient.post<any>(this.baseUrl + 'api/leavePolicyLeaveMapping/getMappingData', JSON.stringify(data), this.options)
  }
  NAMES: any = []
  policyMapAddBulk(LEAVE_POLICY_ID: any, PAYROLL_LEAVE_IDS: any): Observable<any> {
    this.httpHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      apikey: "gT4oQrxHPMccXtZzBt5gm6Fn2H2AHnsN",
      applicationkey: "ywz04IlfiTEGd8se",
      deviceid: this.cookie.get("deviceId"),
      supportkey: this.cookie.get("supportKey"),
      Token: this.cookie.get("token"),
    });

    this.options = {
      headers: this.httpHeaders,
    };
    var data = {
      LEAVE_POLICY_ID: LEAVE_POLICY_ID,
      PAYROLL_LEAVE_IDS: PAYROLL_LEAVE_IDS.toString()
    }
    return this.httpClient.post<any>(this.baseUrl + 'api/leavePolicyLeaveMapping/addBulk', JSON.stringify(data), this.options)
  }



  getLeaveData1(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string) {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter

    };

    return this.httpClient.post<any[]>(this.baseUrl + "api/payrollLeaveMaster/get", JSON.stringify(data), this.options);

  }

  createData1(leaveMaster: leaveMaster) {
    leaveMaster.CLIENT_ID = this.clientId;

    if (leaveMaster.APPLICABLE_TYPES.toString() != undefined) {
      leaveMaster.APPLICABLE_TYPES = leaveMaster.APPLICABLE_TYPES.toString();

    }
    // console.log(leaveMaster.APPLICABLE_TYPES);
    if (leaveMaster.CLUBBED_WITH_NOT_POSSIBLE != undefined) {
      leaveMaster.CLUBBED_WITH_NOT_POSSIBLE = leaveMaster.CLUBBED_WITH_NOT_POSSIBLE.toString();

    }
    // console.log(leaveMaster.CLUBBED_WITH_NOT_POSSIBLE);
    return this.httpClient.post<any[]>(this.baseUrl + "api/payrollLeaveMaster/create", JSON.stringify(leaveMaster),
      this.options);
  }

  updateData1(leaveMaster: leaveMaster) {

    leaveMaster.APPLICABLE_TYPES = leaveMaster.APPLICABLE_TYPES.toString();
    // console.log(leaveMaster.APPLICABLE_TYPES);

    leaveMaster.CLUBBED_WITH_NOT_POSSIBLE = (leaveMaster.CLUBBED_WITH_NOT_POSSIBLE).toString();
    // console.log(leaveMaster.CLUBBED_WITH_NOT_POSSIBLE);
    return this.httpClient.put<any[]>(this.baseUrl + "api/payrollLeaveMaster/update", JSON.stringify(leaveMaster),
      this.options);
  }

  getAllAttendanceBranch(
    pageIndex: number,
    pageSize: number,
    sortKey: string,
    sortValue: string,
    filter: string
  ): Observable<Branchmaster[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
    };

    return this.httpClient.post<any>(
      this.url + "branch/get",
      JSON.stringify(data),
      this.options
    );
  }

  getemployeeAttendanceReport(pageIndex: number, pageSize: number, sortKey: string, sortValue: string, filter: string, FROM_DATE: any, TO_DATE: any, MONTH: any, YEAR: any): Observable<[]> {
    var data = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortKey: sortKey,
      sortValue: sortValue,
      filter: filter,
      FROM_DATE: FROM_DATE,
      TO_DATE: TO_DATE,
      MONTH: MONTH,
      YEAR: YEAR
    };
    return this.httpClient.post<[]>(this.url + "reports/getAttendanceReport/getemployeeAttendanceReport", JSON.stringify(data), this.options);
  }

 




}
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './pages/CommonModule/Dashboard/dashboard/dashboard.component';
import { ApplicationsComponent } from './pages/CommonModule/application/applications/applications.component';
import { ModulesComponent } from './pages/CommonModule/module/modules/modules.component';
import { UsersComponent } from './pages/CommonModule/user/users/users.component';
import { RolesComponent } from './pages/CommonModule/role/roles/roles.component';
import { SelectpageComponent } from './pages/CommonModule/selectpage/selectpage/selectpage.component';
import { LoginComponent } from './login/login.component';
import { FaqHeadsComponent } from './pages/SupportModule/FaqHead/faq-heads/faq-heads.component';
import { FaqsComponent } from './pages/SupportModule/FAQ/faqs/faqs.component';
import { FormsComponent } from './pages/CommonModule/form/forms/forms.component';
import { DepartmentsComponent } from './pages/SupportModule/Department/departments/departments.component';
import { EmailsComponent } from './pages/CommunicationModule/EmailServer/emails/emails.component';
import { SmssComponent } from './pages/CommunicationModule/SMSServer/smss/smss.component';
import { FoldersComponent } from './pages/DocumentModule/folder/folders/folders.component';
import { TicketsComponent } from './pages/SupportModule/Tickets/tickets/tickets.component';
import { UserapplicationmappingComponent } from './pages/CommonModule/user/userapplicationmapping/userapplicationmapping.component';
import { UsermodulemappingComponent } from './pages/CommonModule/user/usermodulemapping/usermodulemapping.component';
import { UseraccessmappingComponent } from './pages/CommonModule/user/useraccessmapping/useraccessmapping.component';
import { UserrolemappingComponent } from './pages/CommonModule/user/userrolemapping/userrolemapping.component';
import { TasksComponent } from './pages/SchedularModule/Task/tasks/tasks.component';
import { ViewhistoryComponent } from './pages/SchedularModule/History/viewhistory/viewhistory.component';
import { LogmoduleComponent } from './pages/LogModule/logmodule/logmodule.component';
import { UserdepartmentmappingComponent } from './pages/CommonModule/user/userdepartmentmapping/userdepartmentmapping.component';
import { TicketgroupComponent } from './pages/SupportModule/Tickets/ticketgroup/ticketgroup.component';
import { TestComponent } from './pages/CommonModule/test/test.component';
import { MyticketComponent } from './pages/UserModule/myticket/myticket.component';
import { TicketreportComponent } from './pages/Reports/ticketreport/ticketreport.component';
import { SummaryreportComponent } from './pages/Reports/summaryreport/summaryreport.component';
import { UserwisesummaryComponent } from './pages/Reports/userwisesummary/userwisesummary.component';
import { DashboardreportComponent } from './pages/Report/dashboardreport/dashboardreport.component';
import { OrganizationsComponent } from './pages/Organizations/organizations/organizations.component';
// import { AuthGuard } from './Service/auth.guard';
// import { BranchComponent } from './pages/BranchMaster/branch/branch.component';
import { BranchesComponent } from './pages/BranchMaster/branches/branches.component';
import { DesignationsComponent } from './pages/DesignationMaster/designations/designations.component';
import { TickRolesComponent } from './pages/RoleMaster/tick-roles/tick-roles.component';
import { FeaturesComponent } from './pages/FeatureMaster/features/features.component';
import { EmployeemasterlistComponent } from './pages/employeemaster/employeemasterlist/employeemasterlist.component';
// import { Clusterbranchmasterlistcomponent } from './pages/clusterbranchmaster/clusterbranchmasterlist/clusterbranchmasterlist.component';
// import { ClusterMaster } from './Models/clustermaster';
import { ClustermasterlistComponent } from './pages/clustermaster/clustermasterlist/clustermasterlist.component';
import { EmployeerolemaplistComponent } from './pages/employeerolemap/employeerolemaplist/employeerolemaplist.component';
import { EmploginComponent } from './emplogin/emplogin.component';
import { SearchfaqComponent } from './pages/UserModule/searchfaq/searchfaq.component';
import { FolderMasterComponent } from './pages/FolderAndFileModule/Folder/folder-master/folder-master.component';
import { SharedFolderMasterComponent } from './pages/FolderAndFileModule/SharedFolder/shared-folder-master/shared-folder-master.component';
import { FaqresponsereportComponent } from './pages/Reports/faqresponsereport/faqresponsereport.component';
import { OptionreportComponent } from './pages/Reports/optionreport/optionreport.component';
import { LeavetypesComponent } from './pages/EMM_Module/LeaveType/leavetypes/leavetypes.component';
import { ExpensesheadComponent } from './pages/EMM_Module/ExpenseHead/expenseshead/expenseshead.component';
import { EvaluationcriteriasComponent } from './pages/EMM_Module/EvaluationCriteria/evaluationcriterias/evaluationcriterias.component';
import { CustomersComponent } from './pages/EMM_Module/Customer/customers/customers.component';
import { HolidaysComponent } from './pages/EMM_Module/Holidays/holidays/holidays.component';
import { ThoughsComponent } from './pages/EMM_Module/Though/thoughs/thoughs.component';
import { BirthdaysComponent } from './pages/EMM_Module/birthday/birthdays/birthdays.component';
import { AttendancereportComponent } from './pages/EMM_Module/Reports/attendancereport/attendancereport.component';
import { LeavereportComponent } from './pages/EMM_Module/Reports/leavereport/leavereport.component';
import { LatemarkreportComponent } from './pages/EMM_Module/Reports/latemarkreport/latemarkreport.component';
import { EarlymarkreportComponent } from './pages/EMM_Module/Reports/earlymarkreport/earlymarkreport.component';
import { ExpensereportComponent } from './pages/EMM_Module/Reports/expensereport/expensereport.component';
import { AssetCategoryListComponent } from './pages/materialIssueModule/assetCategory/asset-category-list/asset-category-list.component';
import { AssetListComponent } from './pages/materialIssueModule/asset/asset-list/asset-list.component';
import { EarlymarkTilesComponent } from './pages/EMM_Module/Earlymark/earlymark-tiles/earlymark-tiles.component';
import { LatemarkTilesComponent } from './pages/EMM_Module/LateMark/latemark-tiles/latemark-tiles.component';
import { LeaveTilesComponent } from './pages/EMM_Module/leave/leave-tiles/leave-tiles.component';
import { ExpensesTilesComponent } from './pages/EMM_Module/expenses/expenses-tiles/expenses-tiles.component';
import { HeadApproveEmployeeLeavesTilesComponent } from './pages/EMM_Module/leave/head-approve-employee-leaves-tiles/head-approve-employee-leaves-tiles.component';
import { ApproveEmployeeLeavesTilesComponent } from './pages/EMM_Module/leave/approve-employee-leaves-tiles/approve-employee-leaves-tiles.component';
import { HeadApproveEmployeeLatemarkTilesComponent } from './pages/EMM_Module/LateMark/head-approve-employee-latemark-tiles/head-approve-employee-latemark-tiles.component';
import { HeadApproveEmployeeEarlymarkTilesComponent } from './pages/EMM_Module/Earlymark/head-approve-employee-earlymark-tiles/head-approve-employee-earlymark-tiles.component';
import { ExpensesApproveFromHeadTilesComponent } from './pages/EMM_Module/expenses/expenses-approve-from-head-tiles/expenses-approve-from-head-tiles.component';
import { ExpensesApproveFromAccountantTilesComponent } from './pages/EMM_Module/expenses/expenses-approve-from-accountant-tiles/expenses-approve-from-accountant-tiles.component';
import { AssetMappingListComponent } from './pages/materialIssueModule/assetMapping/asset-mapping-list/asset-mapping-list.component';
import { AssetRequestListComponent } from './pages/materialIssueModule/assetRequest/asset-request-list/asset-request-list.component';
import { AttendanceregisterComponent } from './pages/EMM_Module/Reports/attendanceregister/attendanceregister.component';
import { TrainingsublistComponent } from './pages/TrainingModule/trainingsubmaster/trainingsublist/trainingsublist.component';
import { TrainermasterlistComponent } from './pages/TrainingModule/trainermaster/trainermasterlist/trainermasterlist.component';
import { TrainingtypelistComponent } from './pages/TrainingModule/trainingtypemaster/trainingtypelist/trainingtypelist.component';
import { AnnualtrainpointlistComponent } from './pages/TrainingModule/annualtrainpoint/annualtrainpointlist/annualtrainpointlist.component';
import { TrainingpointmasterlistComponent } from './pages/TrainingModule/trainingpointmaster/trainingpointmasterlist/trainingpointmasterlist.component';
import { TrainingschedulelistComponent } from './pages/TrainingModule/trainingschedule/trainingschedulelist/trainingschedulelist.component';
import { InductionarealistComponent } from './pages/TrainingModule/inductionareamaster/inductionarealist/inductionarealist.component';
import { TrainfeedformmasterlistComponent } from './pages/TrainingModule/trainingfeedbackformmaster/trainfeedformmasterlist/trainfeedformmasterlist.component';
import { TrainingevalutionformlistComponent } from './pages/TrainingModule/trainingevalutionform/trainingevalutionformlist/trainingevalutionformlist.component';
import { TrainfeedmasterlistComponent } from './pages/TrainingModule/trainingfeedbackmaster/trainfeedmasterlist/trainfeedmasterlist.component';
import { TrainattendancecardlistComponent } from './pages/TrainingModule/trainattendancecard/trainattendancecardlist/trainattendancecardlist.component';
import { TrainerloginviewComponent } from './pages/TrainingModule/trainerlogin/trainerloginview/trainerloginview.component';
import { AccessorviewComponent } from './pages/TrainingModule/accessor/accessorview/accessorview.component';
import { AssetRegisterComponent } from './pages/materialIssueModule/Reports/asset-register/asset-register.component';
import { AssetAllocationRegisterComponent } from './pages/materialIssueModule/Reports/asset-allocation-register/asset-allocation-register.component';
import { AssetCategoryRegisterComponent } from './pages/materialIssueModule/Reports/asset-category-register/asset-category-register.component';
import { EmployeeOfTheMonthComponent } from './pages/EMM_Module/employee-of-the-month/employee-of-the-month.component';
import { DaywiseattendanceComponent } from './pages/Reports/Payroll/daywiseattendance/daywiseattendance.component';
import { PayrollRegisterComponent } from './pages/Reports/Payroll/payroll-register/payroll-register.component';
import { LeaveRegisterComponent } from './pages/Reports/Payroll/leave-register/leave-register.component';
import { PayrollitemlistComponent } from './pages/EMM_Module/payrollitemmaster/payrollitemlist/payrollitemlist.component';
import { PayrollPolicyComponent } from './pages/EMM_Module/payrollPolicy/payroll-policy/payroll-policy.component';
import { LeavePolicyComponent } from './pages/EMM_Module/LeavePolicy/leave-policy/leave-policy.component';
import { ShiftmastersComponent } from './pages/ShiftMaster/shiftmasters/shiftmasters.component';
import { MasterTableComponent } from './pages/Leave Master/master-table/master-table.component';
import { JobApplicationReportComponent } from './pages/only reports/job-application-report/job-application-report.component';
import { EmpLeavePolicyMaptableComponent } from './pages/Leave Calculation Module/emp-leave-policy-maptable/emp-leave-policy-maptable.component';
import { LeavePolicyTableComponent } from './pages/Leave Calculation Module/Leave Policy/leave-policy-table/leave-policy-table.component';
import { LeaveAdjustmentTableComponent } from './pages/Leave Calculation Module/leave-adjustment-table/leave-adjustment-table.component';
import { LeaveYearconfigurationtableComponent } from './pages/Leave Calculation Module/leave-yearconfigurationtable/leave-yearconfigurationtable.component';
import { EmpLeaveMappingComponent } from './pages/Leave Calculation Module/emp-leave-mapping/emp-leave-mapping.component';
import { EmpTransactionMappingComponent } from './pages/Leave Calculation Module/emp-transaction-mapping/emp-transaction-mapping.component';
import { EmployeeAttendanceReportComponent } from './pages/Report/employee-attendance-report/employee-attendance-report.component';

export const routes: Routes = [
  { path: 'login', redirectTo: 'login' },
  { path: '', pathMatch: 'full', redirectTo: '/dashboard' },
  { path: 'login', component: LoginComponent },
  { path: 'adminlogin', component: EmploginComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'admindashboard', component: DashboardreportComponent },
  // { path: 'applications', component: ApplicationsComponent },
  // { path: 'modules', component: ModulesComponent },
  { path: 'users', component: UsersComponent },
  { path: 'roles', component: RolesComponent },
  { path: 'forms', component: FormsComponent },
  // { path: 'startpage', component: SelectpageComponent },
  { path: 'faqheads', component: FaqHeadsComponent },
  { path: 'faqs', component: FaqsComponent },
  // { path: 'supportmodule/departments', component: DepartmentsComponent },
  // { path: 'communicationmodule/emailserver', component: EmailsComponent },
  // { path: 'communicationmodule/smsserver', component: SmssComponent },
  { path: 'documentmodule/folders', component: FoldersComponent },
  { path: 'mytickets', component: MyticketComponent },
  { path: 'tickets', component: TicketsComponent },
  { path: 'reports/tickets', component: TicketreportComponent },
  { path: 'reports/departmentwisesummary', component: SummaryreportComponent },
  { path: 'reports/userwisesummary', component: UserwisesummaryComponent },
  // { path: 'userapplicationmapping', component: UserapplicationmappingComponent },
  // { path: 'usermodulemapping', component: UsermodulemappingComponent },
  // { path: 'useraccessmapping', component: UseraccessmappingComponent },
  // { path: 'userrolemapping', component: UserrolemappingComponent },
  // { path: 'userdepartmentmapping', component: UserdepartmentmappingComponent },
  // { path: 'Schedularmodule/tasks', component: TasksComponent },
  // { path: 'Schedularmodule/taskshistory', component: ViewhistoryComponent },
  // { path: 'loggermodule/loghistory', component: LogmoduleComponent },
  { path: 'ticketgroups', component: TicketgroupComponent },
  // { path: 'test', component: TestComponent },
  // {path :'organizations', component:OrganizationsComponent,canActivate:[AuthGuard],data:{roleId:['1']},  },
  { path: 'organizations', component: OrganizationsComponent },
  { path: 'shifts', component: ShiftmastersComponent },
  { path: 'departments', component: DepartmentsComponent },
  { path: 'branch', component: BranchesComponent },
  { path: 'designation', component: DesignationsComponent },
  { path: 'tickrole', component: TickRolesComponent },
  { path: 'features', component: FeaturesComponent },
  { path: 'employee', component: EmployeemasterlistComponent },
  { path: 'cluster', component: ClustermasterlistComponent },
  { path: 'employeeRoleMapping', component: EmployeerolemaplistComponent },
  { path: 'help', component: SearchfaqComponent },
  { path: 'folders', component: FolderMasterComponent },
  { path: 'sharedfolders', component: SharedFolderMasterComponent },
  { path: 'faqresponses', component: FaqresponsereportComponent },
  { path: 'reports/OptionWiseResport', component: OptionreportComponent },

  // EMM Module
  { path: 'leavetype', component: LeavetypesComponent },
  { path: 'evaluationcriteria', component: EvaluationcriteriasComponent },
  { path: 'location', component: CustomersComponent },
  { path: 'holiday', component: HolidaysComponent },
  { path: 'thought', component: ThoughsComponent },
  { path: 'birthday', component: BirthdaysComponent },
  { path: 'expensehead', component: ExpensesheadComponent },
  { path: 'leave', component: LeaveTilesComponent },
  { path: 'expenses', component: ExpensesTilesComponent },
  { path: 'earlymark', component: EarlymarkTilesComponent },
  { path: 'latemark', component: LatemarkTilesComponent },
  { path: 'approveleavesbyhr', component: ApproveEmployeeLeavesTilesComponent },
  { path: 'approveleavesbyhead', component: HeadApproveEmployeeLeavesTilesComponent },
  { path: 'approvelatemarkbyhead', component: HeadApproveEmployeeLatemarkTilesComponent },
  { path: 'approveearlymarkbyhead', component: HeadApproveEmployeeEarlymarkTilesComponent },
  { path: 'approveexpensesbyaccountant', component: ExpensesApproveFromAccountantTilesComponent },
  { path: 'approveexpensesbyhead', component: ExpensesApproveFromHeadTilesComponent },
  { path: 'attendancereport', component: AttendancereportComponent },
  { path: 'leavereport', component: LeavereportComponent },
  { path: 'latemarkreport', component: LatemarkreportComponent },
  { path: 'earlymarkreport', component: EarlymarkreportComponent },
  { path: 'expensereport', component: ExpensereportComponent },
  { path: 'attendanceregister', component: AttendanceregisterComponent },
  { path: 'employeeofthemonth', component: EmployeeOfTheMonthComponent },
  { path: 'salaryregister', component: DaywiseattendanceComponent },
  { path: 'payrollregister', component: PayrollRegisterComponent },
  { path: 'leaveregister', component: LeaveRegisterComponent },
  { path: 'payrollitems', component: PayrollitemlistComponent },
  { path: 'payrollpolicy', component: PayrollPolicyComponent },
  { path: 'leavepolicy', component: LeavePolicyComponent },
  // { path: 'evaluationreport', component: EvaluationreportComponent },

  // Material Issue Module
  { path: 'assetcategory', component: AssetCategoryListComponent },
  { path: 'assets', component: AssetListComponent },
  { path: 'assetrequest', component: AssetRequestListComponent },
  { path: 'assetallocation', component: AssetMappingListComponent },
  { path: 'assetregister', component: AssetRegisterComponent },
  { path: 'assetallocationregister', component: AssetAllocationRegisterComponent },
  { path: 'assetcategoryregister', component: AssetCategoryRegisterComponent },

  // Training Module
  { path: 'trainingsubjectgroup', component: TrainingsublistComponent },
  { path: 'trainingsubject', component: TrainingpointmasterlistComponent },
  { path: 'traineraccessor', component: TrainermasterlistComponent },
  { path: 'trainingtype', component: TrainingtypelistComponent },
  { path: 'annualtrainingpoint', component: AnnualtrainpointlistComponent },
  { path: 'trainingschedule', component: TrainingschedulelistComponent },
  { path: 'venue', component: InductionarealistComponent },
  { path: 'trainingfeedbackform', component: TrainfeedformmasterlistComponent },
  { path: 'trainingevaluationform', component: TrainingevalutionformlistComponent },
  { path: 'trainingfeedback', component: TrainfeedmasterlistComponent },
  { path: 'trainattendcard', component: TrainattendancecardlistComponent },
  { path: 'trainingattendance', component: TrainerloginviewComponent },
  { path: 'trainingevaluation', component: AccessorviewComponent },
  { path: 'leavemaster', component: MasterTableComponent },
  { path: 'ShiftmastersComponent', component: ShiftmastersComponent },
  { path: 'applicationreport', component: JobApplicationReportComponent },
  { path: "empleavepolicy", component: EmpLeavePolicyMaptableComponent },


  { path: "leavepolicy1", component: LeavePolicyTableComponent },
  // { path: "leaveTable", component: MasterTableComponent },
  { path: "leaveadjustment", component: LeaveAdjustmentTableComponent },

  { path: 'leaveYearConfiguration', component: LeaveYearconfigurationtableComponent },
  { path: 'leaveMapping', component: EmpLeaveMappingComponent },
  { path: 'leaveTransaction', component: EmpTransactionMappingComponent },
  { path: 'EmployeeAttendanceReport', component: EmployeeAttendanceReportComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }